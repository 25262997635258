import { CHANGE_VIEW, LOGIN, LOGOUT, RECONCILIATION_CONTROL } from "./actionTypes";

// Export "changeView" action
export const changeView = data => ({
    // Define the action type
    type: CHANGE_VIEW,
    // Define the data to pass with the action
    payload: data
});

// Export "login" action
export const login = data => ({
    // Define the action type
    type: LOGIN,
    // Define the data to pass with the action
    payload: data
});

// Export "logout" action
export const logout = data => ({
    // Define the action type
    type: LOGOUT,
    // Define the data to pass with the action
    payload: data
});

// Export "callsUpdate" action
export const callsUpdate = data => ({
    // Define the action type: manage REQUEST_START and REQUEST_END
    type: data.type,
    // Define the data to pass with the action
    payload: data
});

// Export "reconciliationControl" action
export const reconciliationControl = data => ({
    // Define the action type
    type: RECONCILIATION_CONTROL,
    // Define the data to pass with the action
    payload: data
});
