import React from 'react';
import moment from 'moment';
// Import FabricUI elements
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { List } from 'office-ui-fabric-react/lib/List';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
// Import classes
import Api from '../../../classes/Api.js';
import Logger from '../../../classes/Logger.js';
import Utility from '../../../classes/Utility.js';
// Import CSS
import '../../../styles/Modals.css';

class InformationClipModalView extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'InformationClipModalView'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Write in log
        Logger.write('InformationClipModalView@constructor -> START!', 0, props);
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            id: null,
            duration: 0,
            keywords: null,
            clip: undefined,
            profiles: [],
            serverProfiles: [],
            listElements: [],
            newProfileName: '',
            filterText: '',
            showServerList: false,
            isLoading: true,
        }

        // Compose clip information
        let info = this._composeClipInformation();
        // Store scope
        let me = this;

        // Load profiles from server for source id passed
        this._loadProfiles(this.props.task.sourceId()).then(response => {
            // If response is defined
            let profiles =  response
                // Iterate each element and return a new array
                ? response.map(profile => {
                    // Edit new element adding is_selected
                    profile['is_selected'] = info.profiles.some(
                        // If elment has same id of the profile enabled
                        el => el.id === profile.id
                    // Set true, otherwise false
                    ) ? true : false;
                    // Return that element with new prop "is selected"
                    return profile;
                // Otherwise return an empty array
                }) : [];
            // Update state with that profiles
            me.setState(
                {
                    id: info.id,
                    duration: info.duration,
                    keywords: info.keywords,
                    profiles: info.profiles,
                    serverProfiles: profiles,
                    isLoading: false
                },
                // HACK: As callback, filter profiles to show list eleemnt updated
                () => me._filterServerProfiles(profiles, this.state.filterText)
            );
        }).catch(e => {
            // Update state with loading status
            me.setState({ isLoading: false });
        });
    }

    /**
     * Compose clip information from data passed
     * @return object   The clip information with  profiles and other things
     */
    _composeClipInformation = () => {
        // If clip is null, exit
        if (!this.props.clip) return null;
        // Get start moment
        let start = this.props.clip.start('moment');
        // Get end moment
        let end = this.props.clip.end('moment');
        // Take the difference
        let diff = end.diff(start);
        // If clip has metdata array
        let keywords = this.props.clip.metadata()
            // Filter each metdata
            ? this.props.clip.metadata().filter(
                // And take only keyword or tag type
                k => k.type() === "keyword" || k.type() === "tag"
            // Iterate the filter result and return an new array instance
            ).map(
                // Compose the string "keyord (HH:mm:ss)"
                k => (k.value() + " (" + k.start('time') + ")")
            )
            // Otherwise return null
            : null;
        // Get all profiles from previous loading
        let profiles = this.props.previousProfiles
            // Or take the clip profiles
            || this.props.clip.profiles();
        // Save in state all informations
        return {
            id: this.props.clip.id(),
            duration: moment.utc(diff).format('HH:mm:ss'),
            keywords: keywords,
            profiles: profiles
        }
    }

    /**
     * Call API and load all profiles that can be enabled for current source
     * @param  integer source_id   The id of the source to be filtered
     * @return Promise      The request promise
     */
    _loadProfiles = (source_id) => {
        // Compose filter to retrieve only profiles of a source
        let filter = "?source__id=" + source_id;
        // Call get task from API and manage response
        return Api.getProfiles(filter, false).then((response) => {
            // Log in debug the call
            Logger.write(
                'InformationClipModalView@_loadProfiles -> success.',
                0, response
            );
            // If data is defined
            return response.data;
        })
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Task@_loadProfiles -> error.', 3, err);
                // Return an empty array
                return [];
            }
        );
    }

    /**
     * Filter the results returned from server with text written by user
     * @param  array    profiles    ALl profiles from server
     * @param  string   text        The input text of user
     * @return void
     */
    _filterServerProfiles = (profiles, text) => {
        // Update state
        this.setState({
            // Set filtered text
            filterText: text,
            // If text is defined
            listElements: text
                // Return all profiles filterd by
                ? profiles.filter(
                    // That has customer_name that match text of user
                    item => item.customer_name.toLowerCase().indexOf(
                        text.toLowerCase()
                    // OR that has id as written by user
                    ) >= 0 || (item.id+"").indexOf(text) >= 0
            // Otherwise return whole profiles
            ) : profiles
        });
    }

    /*
    |-------------------------------------------------------------------------|
    |                           HANDLE ACTION METHODS                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Handle action click on DELETE button
     * @param  Event ev     The button click event
     * @return void
     */
    _handleProfileCellClick = (ev) => {
        // Get the row
        let row = ev.currentTarget;
        // Get the profile ID to be deleted
        let profileId = row.dataset.id ? parseInt(row.dataset.id) : null;

        // Take all profiles selected and filter all exept passed one
        let newProfiles = this.state.serverProfiles.map((profile) => {
            // If current iterated element is the one clicked
            if (profile.id === profileId) {
                // Toggle is_selected state
                profile.is_selected = !profile.is_selected;
            }
            // Return profiles
            return profile;
        });

        // Update state
        this.setState({
            serverProfiles: [...newProfiles],
            profiles: this.state.profiles.filter(
                profile => profile.id !== profileId
            )
        });

        // HACK: view is not re-rendering: launch a filter profiles
        this._filterServerProfiles(newProfiles, this.state.filterText);
    }



    /**
     * Handle action click on DELETE button
     * @param  Event ev     The button click event
     * @return void
     */
    _handleClick = (ev) => {
        // Get the row
        let row = ev.currentTarget;
        // Get the profile ID to be deleted
        let profileId = row.dataset.id ? parseInt(row.dataset.id) : null;
        // Init enabled profiles
        let enabledProfiles = [];

        // Take all profiles selected and filter all exept passed one
        let newProfiles = this.state.serverProfiles.map((profile) => {
            // If current iterated element is the one clicked
            if (profile.id === profileId) {
                // Toggle is_selected state
                profile.is_selected = !profile.is_selected;
            }

            // If the profile is selected, push profile
            if (profile.is_selected) enabledProfiles.push(profile);

            // Return profiles
            return profile;
        });

        // Update state
        this.setState({
            serverProfiles: [...newProfiles],
            profiles: [...enabledProfiles]
        });

        // HACK: view is not re-rendering: launch a filter profiles
        this._filterServerProfiles(newProfiles, this.state.filterText);
    }

    /**
     * Handle action click on DELETE ALL button
     * @param  Event ev     The button click event
     * @return void
     */
    _handleDeleteAllProfilesClick = () => {
        // Take all profiles selected and filter all exept passed one
        let newProfiles = this.state.serverProfiles.map((profile) => {
            // Set is_selected to false
            profile.is_selected = false;
            // Return profiles
            return profile;
        });

        // Update state
        this.setState({
            serverProfiles: [...newProfiles],
            profiles: [...[]]
        });

        // HACK: view is not re-rendering: launch a filter profiles
        this._filterServerProfiles(newProfiles, this.state.filterText);
    }

    /**
     * Handle action click on ADD PROFILE button
     * @param  Event ev     The button click event
     * @return void
     */
    _handleAddProfileClick = () => {
        // Write a warning log
        Logger.write(
            'InformationClipModalView@_handleAddProfileClick -> start'
        );

        this.setState({
            showServerList: !this.state.showServerList
        })

        // TODO: Load profiles from server here
    }

    /**
     * Handle click on "Previous" button
     * @return void
     */
    _handlePreviousButtonClicked = () => {
        // Call the parent method to handle previous action
        this.props.handlePreviousViewChange();
    }

    /**
     * Handle click on "Next" button
     * @return void
     */
    _handleNextButtonClicked = () => {
        // Iterate each profiles
        let data = {};
        // Store profile
        data['profiles'] = [...this.state.profiles];
        // Store clip infos
        data['clip'] = {...this.props.clip};
        // TODO: COMBAK: check if this part is necessary
        // Call the parent method to handle newxt action
        this.props.handleNextViewChange(data);
    }

    /**
     * Change action of text field
     * @param  Event ev     The React event
     * @param  string text  The text of input field
     * @return void
     */
    _onFilterChanged = (ev, text) => {
        // Filter list
        this._filterServerProfiles(this.state.serverProfiles, text);
    }

    _onNewProfileInputChanged = (ev, text) => {
        // Set new profile name
        this.setState({newProfileName: text});
    }

    /**
     * Action render cell fired by Fabric UI list
     * @param  object   item            The project
     * @param  integer  index           The current item index
     * @param  boolean  isScrolling     True if is scrolling
     * @return JSX                      The row in JSX element
     */
    _onRenderServerCell = (item, index, isScrolling) => {
        // Check if current profile item has missing data
        let metadataMissing = Utility.isMetadataMissing(item);
        // Create a name to be displayed
        let name = item.customer_name
            ? item.customer_name : "Profilo #" + item.id;
        // Show correct icon for profile selected or not
        let icon = item.is_selected ? "CheckboxComposite" : "Checkbox";
        // Compose server icon info id
        let iconId = "server-icon-info-" + item.id;
        // If there is a note and is not empty
        let notes = item.notes && item.notes.length > 0
            // Use it, otherwise use default value
            ? item.notes : "Nessuna nota";
        // Return the JSX row element
        return (
            <div className="modal-profile list-element" data-is-focusable={true} data-id={item.id} onClick={this._handleClick}>
                <div className="list-element-part"><Icon iconName={icon} title="Checkbox" ariaLabel="Checkbox" className="list-element-part"/></div>
                <div className="list-element-part">({item.id})</div>
                <div className="list-element-part">
                    <TooltipHost
                        id={"tip-" + iconId}
                        className="tooltip-metadata"
                        content={notes}
                        calloutProps={{
                            gapSpace: 0,
                            target: `#${iconId}`
                        }}>
                    <Icon id={iconId} iconName="Info" title="info" ariaLabel="info" className="list-element-part color-info"/>
                    </TooltipHost>
                </div>
                <div className="list-element-part">{name}</div>
                <div className={"list-element-part " + (metadataMissing ? "" : "hidden")}>
                    <Icon iconName="Warning12" title="warning" ariaLabel="warning" className="list-element-part color-warning"/>
                    <div className="list-element-part color-warning2">&nbsp; Metadati mancanti</div>
                </div>
            </div>
        );
    }

    _onRenderProfileCell = (item, index, isScrolling) => {
        // Check if current profile item has missing data
        let metadataMissing = Utility.isMetadataMissing(item);
        // Create a name to be displayed
        let name = item.customer_name
            ? item.customer_name : "Profilo #" + item.id;
        // Compose client icon info id
        let iconId = "icon-info-" + item.id;
        // If there is a note and is not empty
        let notes = item.notes && item.notes.length > 0
            // Use it, otherwise use default value
            ? item.notes : "Nessuna nota";
        // Return the JSX row element
        return (
            <div className="modal-profile list-element" data-is-focusable={true} data-id={item.id} onClick={this._handleProfileCellClick}>
                <div className="list-element-part"><Icon iconName="Delete" title="Delete" ariaLabel="Delete" className="color-danger list-element-part"/></div>
                <div className="list-element-part">
                    <TooltipHost
                        id={"tip-" + iconId}
                        className="tooltip-metadata"
                        content={notes}
                        calloutProps={{
                            gapSpace: 0,
                            target: `#${iconId}`
                        }}>
                    <Icon id={iconId} iconName="Info" title="info" ariaLabel="info" className="list-element-part color-info"/>
                    </TooltipHost>
                </div>
                <div className="list-element-part">{name}</div>
                <div className={"list-element-part " + (metadataMissing ? "" : "hidden")}>
                    <Icon iconName="Warning12" title="warning" ariaLabel="warning" className="list-element-part color-warning"/>
                    <div className="list-element-part color-warning2">&nbsp; Metadati mancanti</div>
                </div>
            </div>
        );
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // Define the profiles enabled
        const profiles = this.state.profiles;
        // Assign the elements of the list filtrated
        const { listElements } = this.state;
        // Return the view
        return (
            <div hidden={this.props.hidden}>
                <div hidden={!this.state.isLoading}>
                    <Spinner label="Caricamento profili..." />
                </div>
                <div hidden={this.state.isLoading}>
                    <Label className="modal-label title">ID:&nbsp;</Label><Label className="modal-label">{this.state.id}</Label><br />
                    <Label className="modal-label title">Durata:&nbsp;</Label><Label className="modal-label">{this.state.duration}</Label><br />
                    <div><Label className="modal-label keyword-clip title">Keywords:&nbsp;</Label><Label className="modal-label keyword-clip-label">{this.state.keywords ? this.state.keywords.join(', ') : "-"}</Label></div><br />
                    <div className="profile-label-container">
                        <Label className="modal-label title">Profili Assegnati: {profiles ? profiles.length: 0}</Label>
                        <div className="pull-right">
                            <DefaultButton
                                className={"add-profile-button color-success border-success " + (this.state.showServerList ? "blink_me" : "")}
                                text={this.state.showServerList ? "Conferma" : "Aggiungi Profili"}
                                onClick={this._handleAddProfileClick}
                                />
                            <DefaultButton
                                className="color-danger border-danger"
                                onClick={this._handleDeleteAllProfilesClick}
                                text="Rimuovi tutti" />
                        </div>
                    </div>

                    <div className={"clip-profile-list-container " + (this.state.showServerList ? "hidden" : "")}>
                        <List ref="profile_list"
                                className="profile-list"
                                items={profiles}
                                onRenderCell={this._onRenderProfileCell}/>
                    </div>
                    <div  className={"server-profile-list-container " + (this.state.showServerList ? "" : "hidden")}>
                        <TextField placeholder="Filtra per nome profilo"
                            onChange={this._onFilterChanged} />
                        <List ref="server_list"
                                className="server-list"
                                items={listElements}
                                onRenderCell={this._onRenderServerCell}/>
                        <div className="hidden">
                            <TextField
                                className="create-profile-input"
                                placeholder="Inserisci il nome del profilo"
                                 onChange={this._onNewProfileInputChanged} />
                            <DefaultButton
                                className="create-profile-button color-success border-success"
                                text="Crea profilo"
                                onClick={this._handleCreateProfileClick}
                                disabled={this.state.newProfileName.length < 1}/>
                        </div>
                    </div>
                    <DialogFooter>
                        <DefaultButton text="Indietro" onClick={this._handlePreviousButtonClicked}/>
                        <PrimaryButton text="Avanti" onClick={this._handleNextButtonClicked} disabled={this.state.showServerList || this.state.profiles.length === 0}/>
                    </DialogFooter>
                </div>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default InformationClipModalView;

// const el = document.body
// ReactDOM.render(<InformationClipModalView name='John' />, el)
