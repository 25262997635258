import React from 'react'
import { IconButton } from 'office-ui-fabric-react';
import { getTheme } from 'office-ui-fabric-react';


import Main from './Main';
import ErrorBoundary from './ErrorBoundary';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';


import '../styles/Main.css';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
class Layout extends React.Component {

    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            fullscreen: false,
            // Load theme
            theme: getTheme()
        }
    }

    /**
     * Toggle full screen value
     * @return {[type]} [description]
     */
    toggleFullscreen = () => {
        // Set state
        this.setState({
            // Set opposite
            fullscreen: !this.state.fullscreen
        })
    }

    render() {
        // Load theme palette
        const t = this.state.theme.palette;

        return (
            <div className="container">
                <ErrorBoundary>
                    <div className={"fullscreen-toggler " + (this.state.fullscreen ? '' : 'hidden')} style={{backgroundColor: t.themePrimary}}>
                        <IconButton  iconProps={{ iconName: 'BackToWindow' }} title="BackToWindow" ariaLabel="BackToWindow" className="back-to-window" onClick={this.toggleFullscreen}></IconButton>
                    </div>
                    <div className={"header " + (this.state.fullscreen ? 'hidden' : '')} style={{backgroundColor: t.themePrimary}}><Header /></div>
                    <div className="body">
                        <div className="content"><Main /></div>
                        <div className={"sidebar " + (this.state.fullscreen ? 'hidden' : '')} style={{borderColor: t.themePrimary}}><Sidebar toggleFullscreen={this.toggleFullscreen}/></div>
                    </div>
                    <div className={"footer " + (this.state.fullscreen ? 'hidden' : '')} style={{backgroundColor: t.themePrimary}}><Footer /></div>
                </ErrorBoundary>
            </div>
        )
    }

}
export default Layout
