import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from '../pages/Home'
import TaskList from '../pages/TaskList'
import Task from '../pages/Task'
import Info from '../pages/Info'

import '../styles/Main.css';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = () => (
    <div className="container">
        <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/tasks' component={TaskList}/>
            <Route path='/tasks/:id' component={Task}/>
            <Route exact path='/info' component={Info}/>
        </Switch>
    </div>
)

export default Main
