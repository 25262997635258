import React from 'react';
// Import FabricUI components
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';

import Utility from '../../classes/Utility.js';
import Permission from '../../classes/Permission.js';

class TaskInProgressModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'ErrorModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            title: '',
            message: '',
            hideExitButton: false,
            hideCloseButton: true,
            hideAssignButton: true,
        }
    }

    /**
     * Open dialog
     * @param  Object word      The data object to be sent
     * @return void
     */
    open = (task) => {
        // If already is opened, return false
        if (!this.state.hideDialog) return false;

        // Compose dialog and show it
        this._composeDialog(task);
    }

    _composeDialog = (task) => {
        // Load user
        let user = Utility.loadPersistData('user');
        // Check if own task
        let ownTask = task.user() === user.url;
        // Set title as default
        let title = "";
        // Set title as default
        let message = "";
        // Init hide exit button
        let hideExitButton = false;
        // Init hide close button
        let hideCloseButton = true;
        // Init hide assign button
        let hideAssignButton = true;

        // If is not a moderator return false
        if (!Permission.isModerator()) return false;

        // If is the task's owner, don't display anything
        if (ownTask) return false;

       // If is not the owner, but it is already assigned to another user
       else if (!ownTask && task.user()) {
           // Edit title
           title = "Task già asseganta"
           // Edit message
           message = "Questa task è già stata assegnata ed è in corso di"
               + " lavorazione da parte dell'utente: "
               + Utility.getResourceIdFromUrl(task.user());
           // Hide exit
           hideExitButton = true;
           // Show close
           hideCloseButton = false;
       }

        // Otherwise the task is new and is not in progress
        else {
            // Edit title
            title = "Task non asseganta"
            // Edit message
            message = "Questa task non è stata assegnata ad un utente, "
                + "vuoi prendere in carico?";
            // Hide exit
            hideExitButton = true;
            // Show close
            hideCloseButton = false;
            // Show assign
            hideAssignButton = false;
        }

        // Update state
        this.setState({
            title: title,
            message: message,
            hideDialog: false,
            task: task,
            hideExitButton: hideExitButton,
            hideCloseButton: hideCloseButton,
            hideAssignButton: hideAssignButton
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Close dialog
        this.setState({ hideDialog: true });
    }

    /**
     * Close and go list
     * @return void
     */
    exit = () => {
        // Handle action dismiss
        this.props.handleExitAction();
    }

    /**
     * Close and call assign endpoint
     * @return void
     */
    assign = () => {
        // Close dialog
        this.setState({ hideDialog: !this.state.hideDialog });
        // Get the id
        let id = this.state.task ? this.state.task.id() : undefined;
        // Handle action dismiss
        this.props.handleAssignAction(id);
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.state.title || "Error",
                    subText: this.state.message || "An error occurred, please try again.",
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } }
                }}
            >
            <DialogFooter>
                <DefaultButton onClick={this.close} text="Chiudi" className={this.state.hideCloseButton ? "hidden" : ""} />
                <PrimaryButton onClick={this.exit} text="Torna alla lista" className={this.state.hideExitButton ? "hidden" : ""} />
                <PrimaryButton onClick={this.assign} text="Prendi in carico" className={this.state.hideAssignButton ? "hidden" : ""} />
            </DialogFooter>
        </Dialog>
        );
    }
}
// Export default component to be accessible in other components
export default TaskInProgressModal;
