import React from 'react';
import {CompoundButton} from 'office-ui-fabric-react/lib/Button';

import Logger from '../classes/Logger.js';
import Config from '../classes/Config.js';
import Utility from '../classes/Utility.js';

import '../styles/ActionButton.css';


class ActionButton extends React.Component {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'ActionButton',
        runningCalls: [],
        // Load all SHORTCUTS from config
        shortcuts: JSON.parse(
            Config.get('SHORTCUT_KEYS', '[]').replace(/\\/g, "")
        ),
        // Load special key from config
        special: Config.get('SHORTCUT_SPECIAL', 'alt'),
        // Check if user has the special key pressed...
        isSpecialPressed: false,
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
        }

        // Add event lister on keydown
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('keyup', this.handleKeyUp);
    }

    /**
     * Handle key up event
     * @param  object ev    React event
     * @return void
     */
    handleKeyUp = (ev) => {
        // Change state to special is pressed to false
        this.props.handleSpecialKeyPressed(false);
    }

    /**
     * Manage the keydown event action
     * @param  Event    ev   Keydown event
     * @return void
     */
    handleKeyDown = (ev) => {

        // If user press ESC, call parent method resetting all.
        if (ev.which === 27 && this.props.selected)
            this.props.handleActionChange(undefined);

        // If special is alt, return if is pressed
        let specialKeyPressed = (this.props.special === "alt") ? ev.altKey
            // Otherwise if special is crtl, return if is pressed
            : (this.props.special === "crtl") ? ev.ctrlKey
                // Otherwise if special is shift, return if is pressed
                : (this.props.special === "shift") ? ev.shiftKey
                    // Otherwise if special is crtl+alt, return if are pressed
                    : (this.props.special === "crtl+alt") ? (ev.ctrlKey && ev.altKey)
                        // Otherwise if special is meta, return if is pressed
                        : (this.props.special === "meta") ? ev.metaKey
                            // Otherwise return false
                            : false;

        // If special key is pressed
        if (specialKeyPressed) {
            // Change state to special is pressed
            this.props.handleSpecialKeyPressed(true);
        }

        // If no specialkey key and shortcut key was pressed return false
        if (!this._getShortcut(ev.which)
            || !(this._getShortcut(ev.which) && specialKeyPressed)) {
            // Return false
            return false;
        }

        // Prev defualt
        ev.preventDefault();
        // Set shortcut inside var
        let shortcut = this._getShortcut(ev.which)
        // Write in debug
        Logger.write('ActionButton@handleKeyDown -> ' + shortcut.name);
        // Get the dom target
        let target = document.querySelector(
            "[data-action='" + shortcut.action + "']"
        );

        // If targe is not defined
        if (!target) {
            // Log a warning
            Logger.write(
                'ActionButton@handleKeyDown -> no action button found.',
                2,
                shortcut.name
            );
            // Return false
            return false;
        }

        // Get dataset
        let data = target.dataset;

        // If button is disabled, don't go on!
        if (Utility.disableButtonForCurrentSelection(
            data.action, this.props.selected
        )) return false;

        // init action object
        let action = {
            context: 'actionbutton',
            mode: data.action,
            event: ev
        }

        // If has already the action in progress
        if (this.props.action && !this._isRapidAction(action)
            && (this.props.action.mode === "add_tag"
            || this.props.action.mode === "set_clip_start"
            || this.props.action.mode === "set_clip_end"
            || this.props.action.mode === "delete_clip"
            || this.props.action.mode === "create_clip"
            || this.props.action.mode === "merge_clips"
            || this.props.action.mode === "set_thumbnail")
        ) {
            // Dismiss the action
            this.dismissAction(action);
            // Exit
            return true;
        }

        // Make the action
        this.executeAction(action);
    }

    /**
     * Click Action on button
     * @param  {Event} ev   Event click
     * @return void
     */
    actionButtonClicked = (ev) => {
        // Write the debug log
        Logger.write('ActionButton@actionButtonClicked -> start.', 0);
        // Get the target
        let target = ev.currentTarget;
        // Get dataset
        let data = target.dataset;
        // init action object
        let action = {
            context: 'actionbutton',
            mode: data.action,
            event: ev
        }

        // If has already the action in progress
        if (this.props.action && !this._isRapidAction(action)
            && (this.props.action.mode === "add_tag"
            || this.props.action.mode === "set_clip_start"
            || this.props.action.mode === "set_clip_end"
            || this.props.action.mode === "delete_clip"
            || this.props.action.mode === "create_clip"
            || this.props.action.mode === "merge_clips"
            || this.props.action.mode === "set_thumbnail")
        ) {
            // Dismiss the action
            this.dismissAction(action);
            // Exit
            return true;
        }

        // Make the action
        this.executeAction(action);
    }

    /**
     * Execute an action of action button
     * @param  object action    The object with context, mode & event
     * @return void
     */
    executeAction = (action) => {
        // Write the debug log
        Logger.write('ActionButton@executeAction -> start.', 0, action);

        // Check if is some transcription box is selected
        if (Utility.isSelectionEnabled(["transcription-box", "timeline"])) {
            // If is a selection
            let selection = typeof window.getSelection != "undefined"
                // Get selection
                ? window.getSelection()
                // Otherwise, use a second way to check the selection
                : (typeof document.selection != "undefined"
                    // If found take the range, otherwise null
                    ? document.selection.createRange() : null);
            // Get start element
            let start = Utility.getSelectedElement(true, selection);
            // Get end element
            let end = Utility.getSelectedElement(false, selection);
            // Create range with HTML elements
            let range = [start, end];
            // Update action with a selection object
            action['selection'] = range;
        }

        // Call parent method to change the action
        this.props.handleActionChange(action);
    }

    /**
     * Dismiss an action started
     * @param  object action    The object with context, mode & event
     * @return void
     */
    dismissAction = (action) => {
        // Write the debug log
        Logger.write('ActionButton@dismissAction -> start.', 0, action);
        // Call parent method to change the mode
        this.props.handleActionDismiss(action);
    }



    /**
     * Return the shortcut object from key code passed
     * @param  integer  code    Key code id
     * @return object           Shortcut object
     */
    _getShortcut = (code) => {
        // Return the shortcut pressed
        return this.props.shortcuts.find(shortcut => shortcut.key === code);
    }

    /**
     * Return the shortcut object from action passed
     * @param  string action    Action name id (add_tag)
     * @return object           Shortcut object
     */
    _getShortcutFromAction = (action) => {
        // Return the shortcut object from action passed
        return this.props.shortcuts.find(
            shortcut => shortcut.action === action
        );
    }

    /**
     * Compose the string to be attachet to button label in render view
     * @param  string action    The string action
     * @return string           The string composed like (ALT+A)
     */
    _getShortcutRepresentation = (action) => {
        // If shortcut was not found return empty string
        if (!this._getShortcutFromAction(action)) return '';
        // If special key was not found return empty string
        if (!this.props.special) return '';
        // Return the composite string made like (ALT+A)
        return "(" + this.props.special.toUpperCase() + "+"
            +  this._getShortcutFromAction(action)['key_repr'] + ")";
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return Utility.shallowCompare(this, nextProps, nextState);
    }

    /**
     * Check if current action is an rapid action: an action executed while
     * player is playing.
     * NOTE: there is a copy of this method used in Task.js
     * @param  object  action  The action of action button/contextmenu/shortcut
     * @return boolean         True if is a rapid action mode
     */
    _isRapidAction = (action) => {
        // If action is null, return false
        if (!action) return false;
        // Return if is an rapid action (action while player is running)
        return this.props.isPlaying && (action.mode === 'add_tag'
            || action.mode === 'set_clip_start'
            || action.mode === 'set_clip_end'
            || action.mode === 'create_clip'
            || action.mode === 'set_thumbnail');
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // calculate if icon is visible or notπ
        let iconVisible = this.props.isPlaying ? 'visible blink_me' : 'hidden';

        return (
            <div className='task-action-button-container text-center border-secondary ms-Grid' dir='ltr'>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_add_tag"
                        data-action="add_tag"
                        text={"Aggiungi tag "}
                        secondaryText={(this._getShortcutRepresentation("add_tag") ? "Shortcut tastiera: " + this._getShortcutRepresentation("add_tag") : "Nessun shortcut asseganto")}
                        iconProps={{
                            iconName: 'LightningBolt',
                            className: 'action-button-rapid ' + iconVisible
                        }}
                        checked={this.props.action && this.props.action.mode === "add_tag"}
                        onClick={this.actionButtonClicked}
                        disabled={Utility.disableButtonForCurrentSelection('add_tag', this.props.selected)}
                        />
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_create_clip"
                        data-action="create_clip"
                        text={"Crea clip "}
                        secondaryText={(this._getShortcutRepresentation("create_clip") ? "Shortcut tastiera: " + this._getShortcutRepresentation("create_clip") : "Nessun shortcut asseganto")}
                        checked={this.props.action && this.props.action.mode === "create_clip"}
                        onClick={this.actionButtonClicked}
                        disabled={Utility.disableButtonForCurrentSelection('create_clip', this.props.selected)}
                        />
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_set_clip_start"
                        data-action="set_clip_start"
                        text={"Imposta inizio clip "}
                        secondaryText={(this._getShortcutRepresentation("set_clip_start") ? "Shortcut tastiera: " + this._getShortcutRepresentation("set_clip_start") : "Nessun shortcut asseganto")}
                        iconProps={{
                            iconName: 'LightningBolt',
                            className: 'action-button-rapid ' + iconVisible
                        }}
                        checked={this.props.action && this.props.action.mode === "set_clip_start"}
                        onClick={this.actionButtonClicked}
                        disabled={Utility.disableButtonForCurrentSelection('set_clip_start', this.props.selected)}
                        />
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_set_clip_end"
                        data-action="set_clip_end"
                        text={"Imposta fine clip "}
                        secondaryText={(this._getShortcutRepresentation("set_clip_end") ? "Shortcut tastiera: " + this._getShortcutRepresentation("set_clip_end") : "Nessun shortcut asseganto")}
                        iconProps={{
                            iconName: 'LightningBolt',
                            className: 'action-button-rapid ' + iconVisible
                        }}
                        checked={this.props.action && this.props.action.mode === "set_clip_end"}
                        onClick={this.actionButtonClicked}
                        disabled={Utility.disableButtonForCurrentSelection('set_clip_end', this.props.selected)}
                        />
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_merge_clips"
                        data-action="merge_clips"
                        text={"Unisci clips "}
                        secondaryText={(this._getShortcutRepresentation("merge_clips") ? "Shortcut tastiera: " + this._getShortcutRepresentation("merge_clips") : "Nessun shortcut asseganto")}
                        checked={this.props.action && this.props.action.mode === "merge_clips"}
                        onClick={this.actionButtonClicked}
                        disabled={Utility.disableButtonForCurrentSelection('merge_clips', this.props.selected)}
                        />
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_delete_clip"
                        data-action="delete_clip"
                        text={"Elimina clip "}
                        secondaryText={(this._getShortcutRepresentation("delete_clip") ? "Shortcut tastiera: " + this._getShortcutRepresentation("delete_clip") : "Nessun shortcut asseganto")}
                        checked={this.props.action && this.props.action.mode === "delete_clip"}
                        onClick={this.actionButtonClicked}
                        disabled={Utility.disableButtonForCurrentSelection('delete_clip', this.props.selected)}
                        />
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_set_thumbnail"
                        text={"Imposta thumbnail "}
                        secondaryText={(this._getShortcutRepresentation("set_thumbnail") ? "Shortcut tastiera: " + this._getShortcutRepresentation("set_thumbnail") : "Nessun shortcut asseganto")}
                        data-action="set_thumbnail"
                        iconProps={{
                            iconName: 'LightningBolt',
                            className: 'action-button-rapid ' + iconVisible
                        }}
                        checked={this.props.action && this.props.action.mode === "set_thumbnail"}
                        onClick={this.actionButtonClicked}
                        disabled={Utility.disableButtonForCurrentSelection('set_thumbnail', this.props.selected)}
                        />
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_end_production"
                        text={"Termina lavorazione "}
                        secondaryText={(this._getShortcutRepresentation("end_production") ? "Shortcut tastiera: " + this._getShortcutRepresentation("end_production") : "Nessun shortcut asseganto")}
                        data-action="end_production"
                        onClick={this.actionButtonClicked}
                        disabled={
                            this.props.runningCalls.length > 0
                            || Utility.disableButtonForCurrentSelection('end_production', this.props.selected)
                            || !this.props.taskCompleted
                        }/>
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_confirm_clips_and_end_production"
                        text={"Conferma clips e termina "}
                        secondaryText={(this._getShortcutRepresentation("confirm_clips_and_end_production") ? "Shortcut tastiera: " + this._getShortcutRepresentation("confirm_clips_and_end_production") : "Nessun shortcut asseganto")}
                        data-action="confirm_clips_and_end_production"
                        onClick={this.actionButtonClicked}
                        disabled={
                            this.props.runningCalls.length > 0
                            || Utility.disableButtonForCurrentSelection('confirm_clips_and_end_production', this.props.selected)
                            || !this.props.task.hasAlmostOneClipOK()
                        }/>
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_confirm_clips_and_create_task"
                        text={"Conferma clips e crea task "}
                        secondaryText={(this._getShortcutRepresentation("confirm_clips_and_create_task") ? "Shortcut tastiera: " + this._getShortcutRepresentation("confirm_clips_and_create_task") : "Nessun shortcut asseganto")}
                        data-action="confirm_clips_and_create_task"
                        onClick={this.actionButtonClicked}
                        disabled={
                            this.props.runningCalls.length > 0
                            || Utility.disableButtonForCurrentSelection('confirm_clips_and_create_task', this.props.selected)
                            || !this.props.task.hasAlmostOneClipOK()
                        }/>
                </div>

                <div className='ms-Grid-col ms-sm6 action-button-element-box'>
                    <CompoundButton primary
                        className="action-button button"
                        data-automation-id="button_reload_task"
                        text={"Reset Task "}
                        secondaryText={(this._getShortcutRepresentation("reload_task") ? "Shortcut tastiera: " + this._getShortcutRepresentation("reload_task") : "Nessun shortcut asseganto")}
                        data-action="reload_task"
                        onClick={this.actionButtonClicked}
                        disabled={Utility.disableButtonForCurrentSelection('reload_task', this.props.selected)}
                        />
                </div>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default ActionButton;
