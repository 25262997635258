import { RECONCILIATION_CONTROL } from "../actionTypes";

// Create the initial state of the
const initialState = {
    render: false,
    target: 'transcriptionBox'
}

// Export the reducer, taking the state and the action
export default function (state = initialState, action) {
    // Create a copy of current state
    let newState = {...state};

    // Switch on action type
    switch (action.type) {
        // If the action is REQUEST_START
        case RECONCILIATION_CONTROL: {
            // Take the new data passed, and set it into the state
            newState.render = action.payload.render;
            newState.target = action.payload.target;
            // Return the updated state
            return newState;
        }
        // As default
        default:
            // Return the default state
            return state;
    }
}
