import sha1 from 'sha1';

import Utility from '../Utility.js';
import Config from '../Config.js';

const Metadata = {
    /**
     * Metadata data downloaded from server
     * @type object
     */
    raw: {},

    /**
     * Init object and return self instance
     * @param  object data  Data downloaded form server
     * @return object       Self object
     */
    init: function(data) {
        // Store data into raw value
        this.raw = data;
        // Return self instance
        return {...this};
    },

    /*
    |-------------------------------------------------------------------------|
    |                               GETTER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Return metadata id value
     * @return string    Metadata id
     */
    id: function() {
        // Return metadata id value
        return this.raw.uuid;
    },

    /**
     * Return metadata type value
     * @return string    Metadata type
     */
    type: function() {
        // Return metadata type value
        return this.raw.type;
    },

    /**
     * Return metadata value
     * @return string    Metadata value
     */
    value: function() {
        // Return metadata value value
        return this.raw.value;
    },

    /**
     * Return metadata value
     * @return string    Metadata value
     */
    isMultiworld: function() {
        // Return if metadata value is defined, and timmed + splitted has more
        // then 1 word
        return this.raw.value && this.raw.value.trim().split(' ').length > 1;
    },

    /**
     * Return metadata deleted value
     * @return boolean    Metadata deleted
     */
    deleted: function() {
        // Return metadata deleted value
        return this.raw.deleted === "true";
    },

    /**
     * Return the start date time of the task
     * @param  string format    The format to be returned (unix, moment, string, human)
     * @return multy            The value formatted, string value as default
     */
    start: function(format) {
        // Return value formatted
        return Utility.momentFormat(this.raw.start_datetime, format);
    },

    /**
     * Return the end date time of the task
     * @param  string format    The format to be returned (unix, moment, string, human)
     * @return multy            The value formatted, string value as default
     */
    end: function(format) {
        // Return value formatted
        return Utility.momentFormat(this.raw.end_datetime, format);
    },

    /**
     * Check if have to print or not
     * @return boolean  True if have to print it
     */
    haveToPrint: function() {
        // Return TRUE if inside types is the current type
        return this.getAvailableTypes().includes(this.type()) ? true : false;
    },

    /**
     * Load all availabe type
     * @return array    Array of string types available
     */
    getAvailableTypes: function() {
        // Get if default show keyword is with A type
        const aType = parseInt(Config.get("default_show_keyword_a", 0));
        // Check if a type return it
        return aType
            ? ['keyword', 'thumbnail', 'tag', 'keyword_a']
            : ['keyword', 'thumbnail', 'tag'];
    },

    /*
    * Return sha1 of raw data
    * @return string  The sha1 string of eaw data
    */
   hash: function() {
       // Make the sha1 and return
       return sha1(this.raw);
   }
}

export default Metadata
