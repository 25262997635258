import moment from 'moment';

import Config from './Config.js';

const MetadataUtil = {
    /*
    |-------------------------------------------------------------------------|
    |                               GETTER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Get dataset from HTML element
     * @param  HTMLEntity node    HTML node
     * @return object             The dataset object
     */
    getData: function(node) {
        // If node is defined, return it, otherwise return null
        return node ? node.dataset : null;
    },

    /*
    |-------------------------------------------------------------------------|
    |                       METHODS BASED ON HTML NODE                        |
    |-------------------------------------------------------------------------|
    */

    /**
     * Get data attribute from HTML node dataset
     * @param  HTMLEntity node          HTML node
     * @param  string     attribute     The string attribute to return
     * @return multy                    The value of the data-attribute
     */
    getDataAttribute: function(node, attribute) {
        // Get dataset
        let data = this.getData(node);
        // If dataset is defined return the attribute, otherwise return null
        return data ? data[attribute] : null;
    },

    /**
     * Get metadata id
     * @param  HTMLEntity node          HTML node
     * @return string                   Get metadata id
     */
    getId: function(node) {
        // Return metadata id
        return this.getDataAttribute(node, 'id')
            ? parseInt(this.getDataAttribute(node, 'id'))
            : null;
    },

    /**
     * Get metadata type
     * @param  HTMLEntity node          HTML node
     * @return string                   Get metadata type
     */
    getType: function(node) {
        // Return metadata type
        return this.getDataAttribute(node, 'type');
    },

    /**
     * Get time of a metadata and format it
     * @param  HTMLEntity node      HTML node
     * @param  string     format    The format: unix(def) | human | moment
     * @param  string     attribute The key attribute
     * @return multy                The date formatted
     */
    getTime: function(node, format, attribute) {
        // // Write console log
        // Logger.write('MetadataUtil@getTime: start', 1);
        // // Write console debug
        // Logger.write('Data:', 0, [node, format, attribute]);
        // If no attribute is defined, set datetime as default
        attribute = attribute || 'datetime';
        // Get the value in timestamp
        let value = this.getDataAttribute(node, attribute);

        // If value is not defined
        if (!value) {
            // Return null
            return null;
        }

        // Parse integer
        value = parseInt(value);
        // get moment object
        let momentObj = moment(value);

        // If have moment format
        if (format === 'moment') {
            // Return moment instance
            return momentObj;
        }
        // If have human format
        else if (format === 'human') {
            // Return data human formatted
            return momentObj.format('DD/MM/YYYY hh:mm:ss');
        }

        // Return unix timestamp as default
        return momentObj.valueOf();
    },

    /**
     * Get start time of a clip and format it
     * @param  HTMLEntity node      HTML node
     * @param  string     format    The format: unix(def) | human | moment
     * @return multy                The date formatted
     */
    getStartTime: function(node, format) {
        // Return the stat time formatted
        return this.getTime(node, format, 'time_start');
    },

    /**
     * Get end time of a clip and format it
     * @param  HTMLEntity node      HTML node
     * @param  string     format    The format: unix(def) | human | moment
     * @return multy                The date formatted
     */
    getEndTime: function(node, format) {
        // Return the end time formatted
        return this.getTime(node, format, 'time_end');
    },

    /**
     * Return true if is a metadata, false if is a clip or others.
     * @param  HTMLEntity node          HTML node
     * @return boolean                  True if is a metadata
     */
    isMetadata: function(node) {
        // Get the type from node dataset attribute
        let type = this.getDataAttribute(node, 'type');
        // Return if the type is included in metadata types
        return this.getAvailableTypes().includes(type)
            // Then return true
            ? true
            // Otherwise return false
            : false;
    },

    /**
     * Return true if is a clip, false otherwise.
     * @param  HTMLEntity node          HTML node
     * @return boolean                  True if is a clip
     */
    isClip: function(node) {
        // Get the type from node dataset attribute
        let type = this.getDataAttribute(node, 'type');
        // Return if type is clip
        return type === "clip"
            // Then return true
            ? true
            // Otherwise return false
            : false;
    },

    /**
     * Load all availabe type
     * @return array    Array of string types available
     */
    getAvailableTypes: function() {
        // Get if default show keyword is with A type
        const aType = parseInt(Config.get("default_show_keyword_a", 0));
        // Check if a type return it
        return aType
            ? ['keyword', 'thumbnail', 'tag', 'keyword_a']
            : ['keyword', 'thumbnail', 'tag'];
    }
}

export default MetadataUtil
