import React from 'react';
import moment from 'moment';
// Import redux actions and store
import { connect } from 'react-redux';
import { changeView, reconciliationControl } from '../../redux/actions';
// Import classes
import Api from '../classes/Api.js';
import Config from '../classes/Config.js';
import Utility from '../classes/Utility.js';
import Logger from '../classes/Logger.js';
import History from '../classes/History.js';
import WordUtil from '../classes/WordUtil.js';
import ActionUtil from '../classes/ActionUtil.js';
import TranscriptionHelper from '../classes/TranscriptionHelper.js';
import Permission from '../classes/Permission.js';
// Import models
import TaskModel from '../classes/models/Task.js'
// Import components
import Player from '../partials/Player.js';
import Timeline from '../partials/Timeline.js';
import TaskError from '../partials/TaskError.js';
import ContextMenu from '../partials/ContextMenu.js';
import TaskShimmer from '../partials/TaskShimmer.js';
import ActionButton from '../partials/ActionButton.js';
import TranscriptionBox from '../partials/TranscriptionBox.js';
// Import dialogs
import AddKeywordModal from '../partials/modals/AddKeywordModal.js';
import SetClipStartModal from '../partials/modals/SetClipStartModal.js';
import SetClipEndModal from '../partials/modals/SetClipEndModal.js';
import DeleteClipModal from '../partials/modals/DeleteClipModal.js';
import CreateClipModal from '../partials/modals/CreateClipModal.js';
import MergeClipsModal from '../partials/modals/MergeClipsModal.js';
import SetThumbnailModal from '../partials/modals/SetThumbnailModal.js';
import ResetTaskModal from '../partials/modals/ResetTaskModal.js';
import EndProductionModal from '../partials/modals/EndProductionModal.js';
import ConfirmClipsAndEndProductionModal from '../partials/modals/ConfirmClipsAndEndProductionModal.js';
import ConfirmClipsAndCreateTaskModal from '../partials/modals/ConfirmClipsAndCreateTaskModal.js';
import ClipMetadataModal from '../partials/modals/ClipMetadataModal.js';
import TaskInProgressModal from '../partials/modals/TaskInProgressModal.js';
import ErrorModal from '../partials/modals/ErrorModal.js';

// Import style
import '../styles/Task.css';


class Task extends React.Component {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'Task',
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Log in verbose
        Logger.write('Task@constructor -> start.', 0, props);
        // Get task id from URL
        let taskId = this.props.match.params
            ? parseInt(this.props.match.params.id)
            : null;
        // Disable render to avoid many time rendering
        this.disableRender();

        // Set default state
        this.state = {
            visible: true,
            value: '',
            id: taskId,
            task: TaskModel.init({}),
            progress: 0,
            contextMenuScopes: ["transcription-box", "timeline"],
            action: {},
            runningCalls: [],
            isPlaying: false,
            specialKeyPressed: false,
            selected: undefined,
            // This means that all ajax are done, only renders should finish
            loaded: false,
            taskCompleted: false,
            initializationCompleted: false,
            showError: false
        }

        // Init task
        this._taskInitialization(taskId);
    }

    /**
     * React lifecircle method: component did mount
     * @return void
     */
    componentDidMount = () => {
        // Add a event listener to check the browser closing/page refresh
        window.addEventListener(
            "beforeunload", this._handleWindowBeforeUnload
        );
    }

    /**
     * React lifecircle method: component did mount
     * @return void
     */
    componentWillUnmount = () => {
        // Remove the listener when the component did unmount
        window.removeEventListener(
            "beforeunload", this._handleWindowBeforeUnload
        );
    }

    /**
     * Download a process all the task informations needed
     * @param  integer  id  The Id of the task to be downloaded
     * @return {Promise}    The async promise
     */
    _taskInitialization = async (id) => {
        // First download task
        let task = await this._downloadTask(id);
        // After that start the transcription download
        let transcription = await this._downloadTranscription(task);
        // Last but not least, download clips information like profiles
        task = await this._loadTaskClipsProfiles(task);
        // Check if is completed or not
        let completed = task.isCompleted();

        // All ajax are ended, update state to start rendering
        this.setState({
            task: task,
            transcription: transcription,
            // Set true because all ajax are fired
            loaded: true,
            taskCompleted: completed
        });
        // Run initializationCompleted
        this._initializationCompleted();
        // Log in Debug
        Logger.write("Task@_taskInitialization -> end, Task: ", 0, task);
    }

    /**
     * After reset task is necessary update state data to save correct
     * original data and run renders (transcription and timeline).
     * @param  object  task     The task updated
     * @return Promise          Return async promise
     */
    _resetTaskInitialization = async (task) => {
        // Last but not least, download clips information like profiles
        task = await this._loadTaskClipsProfiles(task);
        // Check if is completed or not
        let completed = task.isCompleted();
        // All ajax are ended, update state to start rendering
        this.setState({
            task: task,
            // Set true because all ajax are fired
            loaded: true,
            // Updating state transcription will run render method, to
            transcription: [...this.state.transcription],
            taskCompleted: completed
        });
        // Run initializationCompleted
        this._initializationCompleted();
        // Log in Debug
        Logger.write("Task@_resetTaskInitialization -> end.", 0);
    }

    /**
     * Download the task object
     * @param  integer id   The Id of the task
     * @return TaskModel    The task objcet
     */
    _downloadTask = (id) => {
        // Return the promise of load task
        return this._loadTask(id).then(downloadedTask => {
            // Override task if OK otherwise take the empty initialized
            let task = downloadedTask || TaskModel.init({});
            // Create the action payload
            let viewData = {'name':'task', 'data':task};
            // Dispatches action to change View
            this.props.changeView(viewData);
            // Return task
            return task;
        });
    }

    /**
     * Check if after a call the initialization is completed or not.
     * Then you have to enable render to hide shimmer, show TaskInProgressModal
     * dialog and assing task to user.
     * @return boolean  True if is completed
     */
    _initializationCompleted = () => {
        // Log in Debug
        Logger.write(
            "Task@_initializationCompleted -> start with calls:", 0,
            this.props.callsUpdate
        );
        // Assing callsUpdate redux to a var
        let calls = this.props.callsUpdate;

        // If start and end calls are 0
        if (calls.started.length === 0 && calls.ended.length === 0)
            // It means that no ajax was fired, initialization is not completed
            return false;

        // If started calls are NOT the same number of ended calls
        if ((calls.started.length !== calls.ended.length)
            // And every call not ended ARE NOT attachments
            && (calls.calls.every(call => !call.url.includes('/attachments/')))
        // return false.
        // NOTE: we check for attachments because task_media are downloaded
        // in player component in a different moment. This checks will be always
        // be true because that calls are the last!
        ) return false;

        // Enable render to build transcription box
        this.enableRender('transcriptionBox');
        // It means that is ended!
        this.setState({ initializationCompleted: true }, () => {
            // Get the task
            let task = this.state.task;
            // Remove the listener when the component did unmount
            window.removeEventListener(
                "beforeunload", this._handleWindowBeforeUnload
            );
            // Check if task is already in progress, if not, assign
            let inProgress = this._taskProgressCheck(task);
            // If is not in progress
            if (!inProgress) this._setTaskInProgress(task.id());
        });
        // Say React gently force update please!
        this.forceUpdate();
        // Return ture
        return true;
    }

    /**
     * Show an error
     * @param  {[type]} error [description]
     * @return {[type]}       [description]
     */
    _showError = (error) => {
        // Set error
        this.setState({
            error: error,
            showError: true
        });
    }

    /**
     * Handle event before window unload
     * @param  Event e  The event of user reload page/close window
     * @return void
     */
    _handleWindowBeforeUnload = (e) => {
        // Cancel the event
        e.preventDefault();
        // If there are some calls running
        let areRunningCalls = this.props.callsUpdate.calls
            // If the runningCalls are almost 1 return true, otherwise false
            ? (this.props.callsUpdate.calls.length > 0 ? true : false)
            // If no running calls are defined, return false
            : false;
        // Return if task is confirmed or not
        let isConfirmedTask = this.state.task.isConfirmed();

        // If there are not running calls and it was confirmed
        if (!areRunningCalls && isConfirmedTask) {
            // Go on without alerting
            return true;
        }

        // Compose text to show: may not work.
        let confirmationText = "Sei sicuro di voler chiudere la finestra? ";

        // If there are running calls
        if (areRunningCalls) {
            // Return the base text with the second part
            confirmationText += "Alcune chiamate in corso non sono ancora terminate.";
            // Gecko, Trident, Chrome 34+
            e.returnValue = confirmationText;
            // Gecko, WebKit, Chrome <34
            return confirmationText;
        }

        // Otherwise return the base text with a second part
        confirmationText += "La task non è stata ancora confermata."
        // Gecko, Trident, Chrome 34+
        e.returnValue = confirmationText;
        // Gecko, WebKit, Chrome <34
        return confirmationText;
    }

    /**
     * Return the current task id
     * @return integer      The task ID
     */
    getTaskId = () => {
        return this.state.id;
    }

    /**
     * Return the current task
     * @return object      The task
     */
    getTask = () => {
        // Return task from state
        return this.state.task;
    }

    /*
    |-------------------------------------------------------------------------|
    |                               ASYNC DOWNLOADING                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Make API call to load task
     * @param  integer id   The ID of the task to be load
     * @return object       The task object
     */
    _loadTask = (id) => {
        // Call get task from API
        return Api.getTask(id).then(
            // Manage success
            (response) => {
                // Log in debug the call
                Logger.write('Task@_loadTask -> success.', 0, response);
                // Set task
                let data = response.data;
                // Create task object
                let task = TaskModel.init(data);
                // Return the task loaded
                // NOTE: here was: JSON.parse(JSON.stringify(task));
                return task;
            }
        )
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Task@_loadTask -> error.', 3, err);
                // Check for initialization completed
                this._initializationCompleted();
                // Show error
                this._showError(err);
                // Return empty object
                return null;
            }
        );
    }

    /**
     * Call API to set the current task as in progress
     * @param integer id    The ID of the task
     */
    _setTaskInProgress = (id) => {
        // if id is not defined
        if (!id) {
            Logger.write(
                'Task@_setTaskInProgress -> Task ID is null,'
                + ' can\'t set progress.', 2
            );
            // Return
            return false;
        }

        // Load user
        let user = Utility.loadPersistData('user');
        // Create the body of patch
        let body = {
            'owner_user': user.url,
            'status': Config.get('URL_API_STATUS')
                + Config.get('DEFAULT_STATUS_IN_PROCESS_ID') + "/",
            'assignment_datetime': Utility.momentFormat(moment(), 'api')
        }
        // Call get task from API to patch
        Api.partialUpdateTask(id, body).then((response) => {
            // Log in debug the call
            Logger.write('Task@_setTaskInProgress -> success.', 0, response);
            // Return true
            return true;
        })
        // Catch the error
        .catch((err) => {
            // Log in error level
            Logger.write('Task@_setTaskInProgress -> error.', 3, err);
            // TODO: create a great alert instead of a default alert
            return alert(
                "Attenzione! Si è verificato un problema con l"
                + "'assegnazione dell'utente per questa task: "
                + "si consiglia di ricaricare la pagina."
            );
        });
    }

    /**
     * Downlaod the transcription data and update the store
     * @param  Task task    The task model
     * @return array        The array with words
     */
    _downloadTranscription = (task) => {
        // Load transcription
        let trasncriptions = task.getAttachmentsByType('task_transcription');

        // If no transcriptions was found
        if (!trasncriptions || trasncriptions.length < 1) {
            // Resolve the promise with an empty array
            return Promise.resolve([]);
        }

        // TODO: NOTE: take only the first transcription
        let id = trasncriptions[0].id;

        // load transcription attachemnt with stream
        return Api.getAttachment(id, true).then(
            // Manage success
            (response) => {
                // Log in debug the call
                Logger.write('Task@getAttachmentsByType -> success.', 0, response);
                // Parse the data response and return the words object
                return TranscriptionHelper.parseXML(response.data);
            }
        )
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Task@getAttachmentsByType -> error.', 3, err);
                // Check for initialization completed
                this._initializationCompleted();
                // Return empty array
                return [];
            }
        );
    }

    /**
     * Load task clips profiles and update store
     * @param  Task task    The task model
     * @return void
     */
    _loadTaskClipsProfiles = async (task) => {
        // Log in verbose
        Logger.write('Task@_loadTaskClipsProfiles -> start.', 0, null);
        // Collect all clip ids
        let ids = task.clips().map(taskClip => taskClip.id());

        // If no clips, return the task
        if (!ids || ids.length === 0) return task;

        // Load task clips profile
        let downloadedProfiles = await this._loadProfiles(ids);
        // Init for circle vars
        let taskClip, response, clip, workingProfiles, profiles;
        // HOTFIX: Make a const copy to avoid variable reuse
        const taskClips = [...task.clips()];

        // Iterate each clips
        for (var i = 0; i < taskClips.length; i++) {
            // Get task clip
            taskClip = taskClips[i];
            // Get current clip and make the API call to download all infos
            response = await Api.getClip(taskClip.id()).catch(e => undefined);

            // If clip is undefined, go next
            if (!response) continue;

            // Get the clip (new copy)
            clip = JSON.parse(JSON.stringify(response.data));
            // Get the profiles (new copy)
            workingProfiles = JSON.parse(JSON.stringify(downloadedProfiles));
            // Process the clip profiles to get a complete profile
            profiles = this.processClipProfileResponse(clip, workingProfiles);
            // Override clip profiles with new profiles
            clip.profiles = profiles;
            // Update Clips
            task.editClip(clip);
        }

        // Return the updated task
        return task;
    }

    _loadProfiles = (ids) => {
        // Call getProfiles with all clip ids
        return Api.getProfiles("?clip__id__in=" + ids).then(
            // Return response to the other promise
            response => (response.data ? response.data : [])
        );
    }

    /**
     * Downloaded profiles does't have metadata.
     * Downloaded clip -> profiles -> have metadatas!
     * That profiles have not profile informations (only url).
     * Take the downloaded profile and override the metadata with clip profile
     * metadata.
     *
     * @param  object clip               [description]
     * @param  array downloadedProfiles [description]
     * @return array                    [description]
     */
    processClipProfileResponse = (clip, downloadedProfiles) => {
        // Get all ids of profile assigned to current clip
        let profileIds = clip['profiles'].map(
            clipProfile => clipProfile.id
        );

        // Get all downloadedProfiles data that are matching current clip
        let profiles = downloadedProfiles.filter(
            profile => profileIds.includes(profile.id)
        );

        // Made it unique
        profiles = profiles.filter(
            // Make unique profiles based on profile.id
            (e, i) => profiles.findIndex(
                    a => a['id'] === e['id']
            ) === i
        );

        // Iterate each unique profiles
        profiles = profiles.map(profile => {
            // Take the profile of the clip
            let clipProfile = clip.profiles.find(clipProfile => profile.id === clipProfile.id);
            // Override metadata by setting all clip metadata filtered
            profile['metadata'] = clipProfile['metadata'];
            // Return profile
            return profile;
        });

        // Return profiles with metadata modified
        return profiles;
    }

    /**
     * WARNING: DEPRECATED!!!
     *
     * HACK: COMBAK: This method is used to mapping 2 server responses API data
     * into the theorically descripbed data object defined in project.
     * What we need is:
     * clip: {
     *      // object with all props
     *      metadata: [
     *          {metadata object assigned to CLIP}
     *      ],
     *      profiles: [
     *          {
     *              // object with all profiles props
     *              metadata: [
     *                  {metadata object assigned to PROFILE}
     *              ],
     *          }
     *      ]
     * }
     *
     * To get the clip metadatata and profile metadata we used /clips/{id} call
     * and re-elaborate the profile metadata (is not in correct structure).
     * To take the profile props we used /profiles/?clip__id__in=[ids] call
     * and merge the profile props into each profile of clip.
     *
     * @param  object   data        Raw clip object
     * @param  array    profiles    The array of profiles before downloaded
     * @return object               The correct clip object
     */
    _elaborateDownloadedClip = (data, profiles) => {
        // If data is undefined
        if (!data) return undefined;

        // Inside profiles there are some doubles
        profiles = profiles.filter(
            // Make unique profiles based on profile.id
            (e, i) => profiles.findIndex(a => a['id'] === e['id']) === i
        );

        // Get all clips profile
        let clipProfiles = profiles.filter(
            // If current profile has almost 1 clip that has the same id of
            // the current clip that we are checking return true
            profile => profile.clips.some(clip => clip.id === data.id)
        );

        // Iterate each profile of the clip
        let completeProfiles = data.profiles.map(profile => {
            // Find the clipPorofile object
            let clipProfile = clipProfiles.find(el => el.id === profile.id);
            // Inside profile.metadata there is an array with this structure:
            // [{metadata: Array(4), clip: 150}]
            // take only the metadata that has same clip id
            let metadata = profile.metadata.find(meta => meta.clip === data.id)
            // Make a copy of clipProfile
            let result = {...clipProfile};
            // Override metadata if metadata was found and is not null
            result['metadata'] = (metadata && metadata.metadata)
                // Copy array of metadata object, otherwise set empty array
                ? [...metadata.metadata] : [];
            // Return the complete profiles
            return result;
        });

        // Override profiles with new one composed
        data['profiles'] = [...completeProfiles];
        // Return the data raw
        return data;
    }

    /**
     * Define the target from action passed
     * @param  object       action  Action object with event, context, & CO.
     * @return HTMLElement          The word target of transcription
     */
    _getTargetFromAction = (action) => {
        // Log in verbose
        Logger.write('Task@_getTargetFromAction -> start.', 0, action);
        // Init target eleemnt
        let target = null;

        //  If is an rapid action (and player is playing)
        if (this._isRapidAction(action)
            // Or has a selected clip AND the action is set clip end or start
            || (this.state.selected
                    && (action.mode === "set_clip_end"
                        || action.mode === "set_clip_start"))
        ) {
            // If mode is set clip end, calculate for the end of the word
            let end = action.mode === "set_clip_end";
            // Manage a rapid action: get timer time, get word, process
            target = WordUtil.getWordCurrentlyPlayed(
                this.state.progress.playedSeconds,
                end
            );
        }

        // Otherwise if action is
        else if (action.context === 'contextmenu-timeline') {
            // Override target with word calculated from timeline click
            target = WordUtil.getWordTargetFromTimelineClick(
                action.event, this.state.task
            );
        }

        // Otherwise...
        else {
            // Get the target of click if defined
            target = action && action.event
                ? (action.event.target.nodeName === "I"
                    ? action.event.currentTarget : action.event.target)
                : undefined;
        }

        // If have to select the current target
        if (this._isSelectAction(action)) {
            // Enable slect mode
            this._enableSelectMode(action, target);
            // COMBAK: : here it was "return true", is this necessary?
        }

        // Return the target
        return target;
    }

    /*
    |-------------------------------------------------------------------------|
    |                             TWO-BINDING METHODS                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Action handled in action button
     * @param  boolean status  True if is pressed
     * @return void
     */
    onSpecialKeyPressed = (status) => {
        // Save the new status
        this.setState({ isSpecialPressed: status });
    }

    /**
     * Action fired when an ajax call is running
     * @param  array calls      The calls running
     * @return void
     */
    onRunningCallChange = (calls) => {
        this.setState({
            runningCalls: calls
        });
    }

    /**
     * Action from player, transcription or timeline.
     * Fired when one component seek player, this is the callback of the player
     * @param  string   context     The context where the action is fired
     * @param  Event    ev          The original event
     * @return void
     */
    onTaskProgressChange = (context, ev) => {
        // Set progress
        this.setState({progress: ev});

        // If context is player
        if (context === 'player') {
            // Call update timeline progress in timeline component
            this.refs.timeline_component.updateTimelineProgress(ev);
            // Call update transcription progress in transcriptionbox component
            this.refs.transcription_box_component.updateTranscriptionProgress(ev);
        }
        // Otherwise if is timeline or transcription
        else if (context === 'timeline' || context === 'transcriptionbox') {
            // Call update player progress in player component
            this.refs.player_component.updatePlayerProgress(ev);
        }
    }

    /**
     * Get the value if is playing or not from player component
     * @param  boolean isPlaying        True if is playing
     * @return void
     */
    onPlayerStatusChange = (isPlaying) => {
        // Set is playing
        this.setState({
            isPlaying: isPlaying
        });
    }

    /**
     * When an user click on an action set it and make changes
     * @param  object action    The object with context, mode & event
     * @return void
     */
    onActionChange = (action) => {
        // Write debug
        Logger.write('Task@onActionChange -> start.', 0, action);
        // Set the current action
        this.setState({action: action});

        // If no action do nothing
        if (!action) {
            // Disable select mode
            this._disableSelectMode();
            // Disable mouse cursor
            this._disableMouseCoursor();
            // Exit please
            return false;
        }

        // If have to enabled pointer
        if (this._haveToEnableMouseCursor(action)) {
            // Enable mouse cursor
            this._enableMouseCoursor();
            // Return don't do nothing
            return true;
        }

        // Get the target from current action
        let target = this._getTargetFromAction(action);

        // Check if is an set start/end clip AND if is playing.
        // This means that you have to create clip (beacuse of a rapid aciton!)
        // instead of edit the nearest
        if (this.state.isPlaying && !this._hasSelection(action) &&
            (action.mode === "set_clip_start" || action.mode === "set_clip_end")
        ) {
            // Override mode to compose data and call API for "create_clip"
            action['mode'] = "create_clip"
        }

        // Compose the data to be processed from dialog
        let data = ActionUtil.composeData(
            action, target, this.state.task, this.state.selected
        );

        // If data is not defiend, return
        if (!data) return false;

        // TODO: manage target null, like for music!

        // Toggle modal to add keyword
        this.toggleActionDialog(action, data);
    }

    /**
     * Dismiss an action in progress
     * @param  object action    The object with context, mode & event
     * @return void
     */
    onActionDismiss = (action) => {
        // Log in verbose
        Logger.write('Task@onActionDismiss -> start.', 0, action);
        // Disable mouse coursor
        this._disableMouseCoursor();
        // Set the current action
        this.setState({action: undefined});
    }

    /**
     * Some actions need a dialog to be completed, open correct dialog
     * @param  object action    The object with context, mode & event
     * @param  multy data      Data to be passed to dialog
     * @return void
     */
    toggleActionDialog = (action, data) => {
        // Log in verbose
        Logger.write(
            'Task@toggleActionDialog -> toggleActionDialog start.',
            0,
            [action, data]
        );

        // If action is null, return false
        if (!action) return false;

        // Call pause player in player component
        this.refs.player_component.pause();

        // If current mode is add_tag
        if (action.mode === "add_tag") {
            // Open the add keyword dialog
            this.refs.add_tag_modal.toggle(data);
        }

        // If current mode is set_clip_start
        if (action.mode === "set_clip_start") {
            // Open the set clip start dialog
            this.refs.set_clip_start_modal.toggle(data);
        }

        // If current mode is set_clip_end
        if (action.mode === "set_clip_end") {
            // Open the set clip end dialog
            this.refs.set_clip_end_modal.toggle(data);
        }

        // If current mode is delete_clip
        if (action.mode === "delete_clip") {
            // Open the delete clip dialog
            this.refs.delete_clip_modal.toggle(data);
        }

        // If current mode is create_clip
        if (action.mode === "create_clip") {
            // Open the delete clip dialog
            this.refs.create_clip_modal.toggle(data);
        }

        // If current mode is merge_clips
        if (action.mode === "merge_clips") {
            // Open the delete clip dialog
            this.refs.merge_clips_modal.toggle(data);
        }

        // If current mode is set_thumbnail
        if (action.mode === "set_thumbnail") {
            // Open the delete clip dialog
            this.refs.set_thumbnail_modal.toggle(data);
        }

        // If current mode is reload_task
        if (action.mode === "reload_task") {
            // Open the delete clip dialog
            this.refs.reload_task_modal.toggle(data);
        }

        // If current mode is end_production
        if (action.mode === "end_production") {
            // Open the delete clip dialog
            this.refs.end_production_modal.toggle(data);
        }

        // If current mode is confirm_clips_and_end_production
        if (action.mode === "confirm_clips_and_end_production") {
            // Open the delete clip dialog
            this.refs.confirm_clips_and_end_production_modal.toggle(data);
        }

        // If current mode is confirm_clips_and_create_task
        if (action.mode === "confirm_clips_and_create_task") {
            // Open the delete clip dialog
            this.refs.confirm_clips_and_create_task_modal.toggle(data);
        }

        // If current mode is end_production
        if (action.mode === "edit_clip") {
            // Open the delete clip dialog
            this.refs.clip_metadata_modal.toggle(data);
        }

        // Disable select mode
        this._disableSelectMode();
        // Disable mouse cursor
        this._disableMouseCoursor();
    }

    /**
     * Manage the the API call response from retrieven by action dialog
     * @param  object response      Server response
     * @return void
     */
    onActionResponse = (response) => {
        // Log in verbose
        Logger.write(
            'Task@onActionResponse -> onActionResponse start.',
            0,
            [response, this.state.action]
        );
        // Get old data
        let updated = this.state.task;
        // Get server data
        let data = response.data;

        // If action is add keyword
        if (this.state.action.mode === "add_tag"
            || this.state.action.mode === "set_thumbnail"
        ) {
            // Update with new data
            updated.setMetadata(data.metadata);
        }

        // If action is delete clip
        if (this.state.action.mode === "delete_clip") {
            // Update with all data excempt the deleted clip
            updated.removeClip(response.id);
        }

        // If action is add keyword
        if (this.state.action.mode === "create_clip") {
            // Update with new data
            updated.addClip(data);
        }

        // If action is delete clip
        if (this.state.action.mode === "merge_clips") {
            // iterate each clips to be removed
            data.removed.forEach((oldClip) => {
                // Update with all data excempt the deleted clip
                updated.removeClip(oldClip.id);
            });
            // Update with new data
            updated.addClip(data.added);
        }

        // If action is edit clip, edit start or end
        if (this.state.action.mode === "set_clip_start"
            || this.state.action.mode === "set_clip_end"
            || this.state.action.mode === "edit_clip") {
            // Delete clip and add new data clip
            updated.editClip(data);
        }

        // If action is reload task
        if (this.state.action.mode === "reload_task") {
            // Update with new data
            updated.init(data);
            // Update clips and make it render
            this._resetTaskInitialization(updated);
        }

        // If action is reload task
        if (this.state.action.mode === "end_production") {

            // If status is 200
            if (response.status === 200) setTimeout(
                // After close animation, wait 500ms and go back
                function () {History.goBack();}, 500
            );

        }

        // If we had confirmed completed clips and end production
        if (this.state.action.mode === "confirm_clips_and_end_production") {
            // If we have response data
            response.data && response.data.length > 0
                // It meas that we have to show some errors
                ? this.refs.error_modal.toggle(response.data)
                // Otherwise make async to let graphic ui changes
                : setTimeout(() => {History.goBack();}, 500);
        }

        // If we had confirmed completed clips and create task
        if (this.state.action.mode === "confirm_clips_and_create_task") {
            // If we have response data
            response.data && response.data.length > 0
                // It meas that we have to show some errors
                ? this.refs.error_modal.toggle(response.data)
                // Otherwise make async to let graphic ui changes
                : setTimeout(() => {History.goBack();}, 500);
        }

        // Enable render to build transcription box
        this.enableRender('transcriptionBox');
        // Assing this scope to me var
        let me = this;

        // Update state
        this.setState({
            task: updated,
            taskCompleted: updated.isCompleted()
        },
        // Callback of state update
        function() {
            // Enable render to build transcription box
            me.disableRender('transcriptionBox');
        });

        // Disable select mode
        this._disableSelectMode();
        // Dismiss the current action
        this.onActionDismiss(this.state.action);
    }

    /**
     * Called when tha transcriptionBox is fully rendered.
     * You have to hide shimmer and check for users permissions
     * @return void
     */
    onTranscriptionBoxRendered = () => {
        // If initialization completed has been already call, exit
        if (this.state.initializationCompleted) return false;

        // Check for initialization completed
        this._initializationCompleted();
    }

    /*
    |-------------------------------------------------------------------------|
    |                               OTHER METHODS                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Check if have to enable mouse cursor pointer
     * @param  object   action  The action from actionbutton/ContextMenu/...
     * @return boolean          True if have to enable mouse cursor
     */
    _haveToEnableMouseCursor = (action) => {
        // Write a log in debug
        Logger.write("Task@_haveToEnableMouseCursor -> start", 0, action);

        // If action is null, return false
        if (!action) return false;

        // Get context
        let context = action.context;
        // Get mode
        let mode = action.mode;
        // Get if player is playing
        let isPlaying = this.state.isPlaying;
        // Get if is an rapid action
        let isRapid = this._isRapidAction(action);
        // Get if has selected something
        let hasSelection = this._hasSelection(action);

        // If mode is "end production" or "reload task", return false
        if (mode === "end_production"
            || mode === "reload_task"
            || mode === "confirm_clips_and_end_production"
            || mode === "confirm_clips_and_create_task") return false;

        // Default mode: action button + is not playing + has not selection.
        // NOTE: RAPID or not should do the same things!
        if (context === "actionbutton" && !isPlaying && !hasSelection)
            return true;

        // Right click context menu mode: if context is transcription/timeline
        if (context.includes("contextmenu")
            // + is not rapid + is not playing + has not selection
            && !isRapid && !isPlaying && !hasSelection
        ) {
            // If mode is create clip OR set clip start OR set clip end, true!
            return (mode === "create_clip"
                || mode === "set_clip_start"
                || mode === "set_clip_end");
        }

        // If a clip is selected
        if (hasSelection) {
            // If is playing and set clip start or end
            if (mode === "set_clip_start" || mode === "set_clip_end")
                // Don't enable it, take form player
                return false;

            // NOTE: In this case you have 2 state: clip selected with alt and
            // click on it, or normal selection. In the first case you have only
            // 3 buttons available: set_clip_start, set_clip_end and
            // delete_clip.
            // If is "clip selection", avoid mouse pointer cursor for
            //  delete_clip. The other actions will be automatically graphic
            //  disabled.
            //  If is "user selection": avoid mouse pointer cursor only for
            //  create_clip, delete_clip, add_tag and set_thumbnail.
            //  This action is disabled in "clip selection" mode.
            //  All others action should be returned mouse coursor.
            return mode !== "delete_clip"
                && mode !== "create_clip"
                && mode !== "add_tag"
                && mode !== "set_thumbnail";
        }

        // If player is playing
        if (isPlaying) {
            // If mode is delete clip OR set clip start OR set clip end
            if (mode === "create_clip"
                || mode === "merge_clips"
                || mode === "delete_clip") return true;

            // Otherwise return false
            return false;
        }

        // If is NOT a Rapid Action AND is action button and mode is not end
        return (!this._isRapidAction(action)
                && (action.context === 'actionbutton' && !this._hasSelection(action))
                && action.mode !== 'end_production'
                && action.mode !== 'reload_task')
            // OR is context menu
            || (
                (action.context === 'contextmenu-transcription-box' || action.context === 'actionbutton')
                // And has mode create clip
                && action.mode === 'create_clip'
                // And is not a selection
                && !this._hasSelection(action)
            )
            // OR is context menu
            || (action.context === 'contextmenu-transcription-box'
                // And has mode merge clips
                && action.mode === 'merge_clips')
    }

    /**
     * Enable mouse cursor for do an action
     * @return void
     */
    _enableMouseCoursor = () => {
        // Get timeline
        let timeline = document.querySelector('#timeline');
        // Get transcription box
        let transcriptionBox = document.querySelector('#transcription-box');
        // If timeline was found, set pointer
        if (timeline) timeline.style.cursor = "crosshair";
        // If transcriptionBox was found, set pointer
        if (transcriptionBox) transcriptionBox.style.cursor = "crosshair";
        // If transcriptionBox was found, add class for stylign
        if (transcriptionBox) transcriptionBox.classList.add('need-attention');
    }

    /**
     * Dismiss mouse cursor to end an action
     * @return void
     */
    _disableMouseCoursor = () => {
        // Get timeline
        let timeline = document.querySelector('#timeline');
        // Get transcription box
        let transcriptionBox = document.querySelector('#transcription-box');
        // If timeline was found, set pointer
        if (timeline) timeline.style.cursor = "auto";
        // if transcriptionBox was found, set pointer
        if (transcriptionBox) transcriptionBox.style.cursor = "auto";
        // If transcriptionBox was found, add class for stylign
        if (transcriptionBox) transcriptionBox.classList.remove('need-attention');
    }

    /**
     * Enable the select mode by saving the selected target
     * @param  object       action  The action
     * @param  HTMLElement  target  The target element selected
     * @return void
     */
    _enableSelectMode = (action, target) => {
        // Log in debug
        Logger.write('Task@_enableSelectMode -> start.', 0, [action, target]);
        // Init selected array
        let selected = [];

        // NOTE: For now only select clip available, otherwise return null
        if (action.mode !== "select_clip") return false;

        // Get all the clips from the selected word
        let clips = this.state.task.getClipsFromWord(target);

        // If clips are found
        if (clips.length < 1) return false;

        // Set selected
        clips[0].setSelected(true);
        // Get the first word
        let start = clips[0].getHtmlWord(this.state.task, true);
        // Get the last word
        let end = clips[0].getHtmlWord(this.state.task, false);
        // Select selection
        Utility.setSelection(start, end);
        // NOTE: For now use only the first. Create a select object
        let select = {
            type: 'clip',
            target: target,
            data: clips[0]
        }
        // Push object into array of selected
        selected.push(select);
        //  Store selected
        this.setState({
            selected: selected
        });
    }

    /**
     * Disable selection mode
     * @return void
     */
    _disableSelectMode = () => {
        // COMBAK: TODO: this check will save from performance lack but will
        // avoid the selected clip to be unselected!
        // Check if is selected, avoid waist render
        if (!this.state.selected) return false;

        //  Store selected
        this.setState({
            selected: undefined
        });

        // Remove selection from browser
        Utility.removeSelection();
    }

    /**
     * Check if current action is an rapid action: an action executed while
     * player is playing.
     * NOTE: there is a copy of this method used in ActionButton.js
     * @param  object  action  The action of action button/contextmenu/shortcut
     * @return boolean         True if is a rapid action mode
     */
    _isRapidAction = (action) => {
        // If action is null, return false
        if (!action) return false;

        // Return if is an rapid action (action while player is running)
        return this.state.isPlaying && (action.mode === 'add_tag'
            || action.mode === 'set_clip_start'
            || action.mode === 'set_clip_end'
            || action.mode === 'create_clip'
            || action.mode === 'set_thumbnail');
    }

    /**
     * Check if is a select action.
     * NOTE: This mode is temporary. It will be overriden to a common action
     * used to edit task's metadata and clips (add_tag, create_clip, ecc..).
     * All action object is intended to be overriden by new action.
     * @param  object  action   Object of Action
     * @return boolean          True if is a select action
     */
    _isSelectAction = (action) => {
        // If action is null, return false
        if (!action) return false;
        // Return if is a select action
        return action.mode === 'select_clip' || action.mode === 'select_words';
    }

    /**
     * Check if current action has selection
     * @param  object  action   The action object
     * @return boolean          True if has selectino
     */
    _hasSelection = (action) => {
        // If seleciton is defined, check the length, if is > 0 return true
        return action && action.selection ? action.selection.length > 0 : false;
    }

    /**
     * Check if task is in progress.
     * If is a normal user and is not assigned, return true and assign to it
     * If is a normal user and is assigned, return false and dont' assign to it
     * If is a moderator user, show dialog and decide to assign or not
     * @param  TaskModel task   Task model object
     * @return boolean          True if is in progress
     */
    _taskProgressCheck = (task) => {
        // Check if is null, in that case return false
        if (task.isEmpty()) return false;

        // Check if is in progress
        let inProgress = this._taskAlreadyInProgress(task);
        // // Load user
        // let user = Utility.loadPersistData('user');
        // // Check if own task
        // let ownTask = task.user() === user.url;
        // // If is the owner
        // if (ownTask && inProgress) return true;

        // If is not a moderator and task is not in progress, exit and assign it
        if (!Permission.isModerator() && !inProgress) return false;

        // If is not a moderator and task is in progress, exit and don't assign
        if (!Permission.isModerator() && inProgress) return true;

        // Only show dialog if is a moderator
        this._taskInProgressDialog(task);
        // Return true
        return true;
    }

    /**
     * Check if task is already in progress: has a different owner user and
     * status is in progress
     * @param  object task  True if task is already in progress
     * @return boolean      True if is already in progress
     */
    _taskAlreadyInProgress = (task) => {
        // If status is null, return false
        if (!task.status()) return false;

        // Load user
        let user = Utility.loadPersistData('user');
        // Return if statis is in process
        return Utility.resourceUrlComparer(
            task.status(), Config.get('default_status_in_process_id')
        // And is in progress by another user
        ) && task.user() !== user.url;
    }

    /**
     * Show task already in progress error dialog
     * @return void
     */
    _taskInProgressDialog = (task) => {
        // Toggle progress modal
        this.refs.task_in_progress_modal.open(task);
        // NOTE: let it true, because it will block assign task request
        return true;
    }

    /*
    |-------------------------------------------------------------------------|
    |                               RENDER COMPONENT                          |
    |-------------------------------------------------------------------------|
    */

    shouldComponentUpdate = (nextProps, nextState) => {
       return Utility.shallowCompare(this, nextProps, nextState);
    }

    /**
     * Use redux to enable render
     * @param  string target The render target
     * @param  object data   The optional data for render
     * @return void
     */
    enableRender = (target, data) => {
        // If is already enabled, return
        if (this.props.reconciliation.render) return false;

        // Log in debug
        Logger.write('[!] Task@enableRender -> render mode [ON].', 0, target);
        // If target not defined, use transcriptionBox as default
        target = target || 'transcriptionBox';
        // If data is not defined, use undefined as default
        data = data || undefined;
        // Dispatches action to reconciliationControl
        this.props.reconciliationControl({
            render: true,
            target: target,
            data: data
        });
    }

    /**
     * Use redux to diable render
     * @return void
     */
    disableRender = () => {
        // If is already disabled return
        if (this.props.reconciliation.render === false) return false;
        // Log in debug
        Logger.write('[!] Task@disableRender -> render mode [OFF].', 0);
        // Dispatches action to reconciliationControl
        this.props.reconciliationControl({
            render: false, target: 'transcriptionBox'
        });
    }

    /**
    * Render component
    * @return JSX   The task view
    */
    render() {
        return (
            <div className='task-container ms-Grid' dir='ltr'>
                <TaskShimmer hidden={this.state.initializationCompleted}/>
                <TaskError hidden={!this.state.showError} error={this.state.error} />
                <div className='ms-Grid-row'>
                    <div className='ms-Grid-col ms-sm6 no-padding'>
                        <Player ref="player_component"
                            task={this.state.task}
                            taskLoaded={this.state.loaded}
                            handleProgressChange={this.onTaskProgressChange}
                            handlePlayerStatusChange={this.onPlayerStatusChange}/>
                    </div>
                    <div className='ms-Grid-col ms-sm6 no-padding'>
                        <ActionButton ref="action_button_component"
                            task={this.state.task}
                            action={this.state.action}
                            selected={this.state.selected}
                            runningCalls={this.props.callsUpdate.calls}
                            isPlaying={this.state.isPlaying}
                            taskCompleted={this.state.taskCompleted}
                            handleActionChange={this.onActionChange}
                            handleActionDismiss={this.onActionDismiss}
                            handleSpecialKeyPressed={this.onSpecialKeyPressed}
                            />
                    </div>
                </div>

                <div className='ms-Grid-row'>
                    <div className='ms-Grid-col ms-sm12 no-padding'>
                        <Timeline
                            ref="timeline_component"
                            task={this.state.task}
                            action={this.state.action}
                            progress={this.state.progress}
                            selected={this.state.selected}
                            isSpecialPressed={this.state.isSpecialPressed}
                            handleProgressChange={this.onTaskProgressChange}
                            handleActionDialog={this.toggleActionDialog}
                            handleActionChange={this.onActionChange}
                            />
                    </div>
                </div>

                <div className='ms-Grid-row'>
                    <div className='ms-Grid-col ms-sm12 no-padding'>
                        <TranscriptionBox
                            ref="transcription_box_component"
                            task={this.state.task}
                            transcription={this.state.transcription}
                            taskLoaded={this.state.loaded}
                            action={this.state.action}
                            progress={this.state.progress}
                            selected={this.state.selected}
                            isSpecialPressed={this.state.isSpecialPressed}
                            handleActionDialog={this.toggleActionDialog}
                            handleProgressChange={this.onTaskProgressChange}
                            handleActionChange={this.onActionChange}
                            handleWordClick={this.onWordClick}
                            disableRender={this.disableRender}
                            finishRender={this.onTranscriptionBoxRendered}
                            />
                    </div>
                </div>

                <ContextMenu
                    ref="context_menu"
                    task={this.state.task}
                    action={this.state.action}
                    selected={this.state.selected}
                    scopes={this.state.contextMenuScopes}
                    runningCalls={this.props.callsUpdate.calls}
                    taskCompleted={this.state.taskCompleted}
                    handleActionChange={this.onActionChange}/>
                <AddKeywordModal
                    ref="add_tag_modal"
                    taskId={this.state.task.id()}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <SetClipStartModal
                    ref="set_clip_start_modal"
                    taskId={this.state.task.id()}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <SetClipEndModal
                    ref="set_clip_end_modal"
                    taskId={this.state.task.id()}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <DeleteClipModal
                    ref="delete_clip_modal"
                    taskId={this.state.task.id()}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <ClipMetadataModal
                    ref="clip_metadata_modal"
                    taskId={this.state.task.id()}
                    task={this.state.task}
                    taskLoaded={this.state.loaded}
                    transcription={this.state.transcription}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <CreateClipModal
                    ref="create_clip_modal"
                    task={this.state.task}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <MergeClipsModal
                    ref="merge_clips_modal"
                    task={this.state.task}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <SetThumbnailModal
                    ref="set_thumbnail_modal"
                    taskId={this.state.task.id()}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <ResetTaskModal
                    ref="reload_task_modal"
                    taskId={this.state.task.id()}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <EndProductionModal
                    ref="end_production_modal"
                    taskId={this.state.task.id()}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <ConfirmClipsAndEndProductionModal
                    ref="confirm_clips_and_end_production_modal"
                    task={this.state.task}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <ConfirmClipsAndCreateTaskModal
                    ref="confirm_clips_and_create_task_modal"
                    task={this.state.task}
                    handleActionResponse={this.onActionResponse}
                    handleActionDismiss={this.onActionDismiss}
                    />
                <ErrorModal
                    ref="error_modal"
                    handleActionDismiss={this.onActionDismiss}
                    />
                <TaskInProgressModal
                    ref="task_in_progress_modal"
                    handleExitAction={() => {History.push('/tasks/'); History.go();} }
                    handleAssignAction={(id) => this._setTaskInProgress(id)}
                    />
            </div>
        );
    }
}

// Take the redux store
const mapStateToProps = state => {
    // From redux store, take only callsUpdate action
    const callsUpdate = state.callsUpdate;
    // From redux store, take only reconciliationControl action
    const reconciliation = state.reconciliationControl;
    // Return the data to the props
    return {
        callsUpdate: callsUpdate,
        reconciliation: reconciliation
    };
};

// Export default component to be accessible in other components
export default connect(
  mapStateToProps,
  { changeView, reconciliationControl }
)(Task);
