import React from 'react';
import moment from 'moment';
// Import FabricUI compoents
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
// Import classes
import Api from '../../classes/Api.js';
import Logger from '../../classes/Logger.js';
import Config from '../../classes/Config.js';
import Utility from '../../classes/Utility.js';

class MergeClipsModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'MergeClipsModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            data: undefined,
            newData: undefined,
            loading: false
        }
    }

    /**
     * Toggle dialog
     * @param  Object data      The data object to be sent
     * @return void
     */
    toggle = (data) => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: data
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: {},
            loading: false
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Save the data passed to the modal
     * @return void
     */
    save = () => {
        // Log into debug
        Logger.write("MergeClipsModal@save -> start:", 0, this.state);
        // Save the error
        this.setState({loading: true});
        // Save the scope inside me var
        let me = this;
        // Init clips array
        let clips = [];

        // Iterate each words and take the clip
        this.state.data.words.forEach((word) => {
            // TODO: for now, take the first, you have to make user choose.
            let clip = word.clips[0];
            // Push to clips array
            clips.push(clip.raw);
        });

        // Order clips
        clips = clips.sort(
            (a,b) => moment(a.start_datetime).isAfter(moment(b.start_datetime))
                ? 1
                : (
                    moment(b.start_datetime).isAfter(moment(a.start_datetime))
                        ? -1 : 0
                )
        );

        // Compose new clip to be sent
        let data = this._composeData(clips);

        // Delete the clips
        Api.deleteClip(clips[0].id).then(response => {
            // Log in debug
            Logger.write('MergeClipsModal@save -> first deletion success.', 0);
            // Return task
            return response;
        // Then delete the second clips
        }).then(response => {
            // Run download transcription after download transcription
            Api.deleteClip(clips[1].id).then(response => {
                // Log in debug
                Logger.write('MergeClipsModal@save -> second deletion success.', 0);
            });
            // Return task
            return response;
        // Finally create the task
        }).then(task => {
            // Run download transcription after download transcription
            Api.createClip(data).then(response => {
                // Log in debug
                Logger.write(
                    'MergeClipsModal@save -> add clip success.', 0, response
                );
                // Create the added
                response.data['added'] = {...response.data};
                // Store clips to be removed
                response.data['removed'] = clips;
                // Handle action response
                me.props.handleActionResponse(response);
                // Close the current modal
                me.close();
            });
        // Manage error
        }).catch((error) => {
            // Log in error
            Logger.write('MergeClipsModal@save -> got an error.', 3, error);
            // Close the current modal
            me.close();
        });
    }

    /**
     * Compose data object for API request
     * @param  string keyword   The keywrod name to sent
     * @return object           The object with all information needed for API request
     */
    _composeData = (clips) => {
        // load user from local storage
        let user = Utility.loadPersistData('user');
        // Load default date format
        let format = Config.get(
            'DEFAULT_API_DATE_FORMAT', 'YYYY-MM-DDTHH:mm:ss.SSS'
        );
        // Make an unique array of profiles ids
        var ids = new Set(clips[0].profiles.map(profile => profile.id));
        // Concat 2 profiles array with the second filtered
        let profiles = [...clips[0].profiles, ...clips[1].profiles.filter(
            // Check if id was not found in the array
            profile => !ids.has(profile.id)
        )];

        // Return data object for API
        return {
            // Set task url
            task: Config.get('URL_API_TASKS') + this.props.task.id() + "/",
            // Set source url
            source: this.props.task.sourceUrl(),
            // Set user url
            creation_user: user.url,
            // Add profiles to new clip
            profiles: profiles,
            // Set status
            status: Config.get('URL_API_STATUS') + 1 + "/", // Status 1 is "created"
            // Set start time
            start_datetime: moment(clips[0].start_datetime).format(format),
            // Set end time
            end_datetime: moment(clips[1].end_datetime).format(format),
        };
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Unisci Clips',
                    subText: 'I dati verrano inviati al server.',
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <DialogFooter>
                <PrimaryButton onClick={this.save} text={ this.state.loading ? "Caricamento..." : "Salva"} disabled={this.state.loading} />
                <DefaultButton onClick={this.close} text="Cancel" />
            </DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default MergeClipsModal;
