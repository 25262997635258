import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router'
import { IconButton } from 'office-ui-fabric-react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { logout } from '../../redux/actions';
import Utility from '../classes/Utility.js';
import Logger from '../classes/Logger.js';
import Api from '../classes/Api.js';
import History from '../classes/History.js';

import NetworkIndicator from '../partials/NetworkIndicator.js';

import '../styles/Header.css'

class Header extends React.Component {

    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            value: '',
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |               COMPOSE VIEW PART BASED ON VIEW DATA                      |
    |-------------------------------------------------------------------------|
    */

    /**
     * Load View Data from Redux store
     * @return object       The view data taken from changeView redux reducer
     */
    _getViewData = () => {
        // If exist viewData AND exist viewData.data, AND data si not null
        return (!Utility.isEmpty(this.props.viewData)
            && !Utility.isEmpty(this.props.viewData.data))
            // Return view data
            ? this.props.viewData.data
            // Otherwise return null
            : null;
    }

    /**
     * Load View name from Redux store
     * @return string   The id name of the current view
     */
    _getViewName = () => {
        // If exist viewData AND exist viewData.name
        return !Utility.isEmpty(this.props.viewData)
            // Return it, otherwise return empty string
            ? this.props.viewData.name : '';
    }

    /**
    * Get the navbar icon for current page
    * @return string    Navbar icon name
    */
    _getNavbarIcon = () => {
        // Load View name
        let name = this._getViewName();

        // If we are in task
        if (name === "task") {
            // If type is video return the video icon name
            return "VideoSearch";
        }
        // Else if we are in tasklist
        else if (name === "task_list") {
            // Return task list icon
            return "TaskManager";
        }
        // Else if we are in info
        else if (name === "info") {
            // Return info icon
            return "Info";
        }

        // Return home icon as default
        return "Home";
    }

    /**
    * Get the navbar title for current page
    * @return string      the correct navbar title
    */
    _getNavbarTitle = () => {
        // Load View name
        let name = this._getViewName();
        // Load view data
        let data = this._getViewData();
        // If data is defined set the name
        return data
            // If we are in task, use name getter, otherwise get name attr
            ? (name === "task" ? data.sourceRepr() : data.name)
            // Otherwise set "infojucie"
            : 'Infojuice';
    }

    _handleClick = () => {
        // Call get task from API
        Api.logout().then(
            // Manage success
            (response) => {
                // Log in debug the call
                Logger.write('Header@_handleClick -> success.', 0, response);
                // Dispatches action to logout redux
                this.props.logout({'token': null});
                // Redirect to home
                return History.go('/');
            }
        )
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Login@doLogin -> error.', 3, err);
                // Dispatches action to logout redux
                this.props.logout({'token': null});
                // Redirect to home
                return History.go('/');
            }
        );
    }


    /**
     * Set other informations into navbar
     * @return {[type]} [description]
     */
    _renderOtherInformation = () => {
        // Load view data
        let data = this._getViewData();
        // Load View name
        let name = this._getViewName();

        // If data is null OR we are not in task or view
        if (!data || name !== 'task'){
            // Don't return anything
            return '';
        }

        // Return other information div
        return <div className="navbar-other-information">
            <span className="title-bar date end pull-right">{data.end('human')}</span>
            <Icon iconName="ChromeBackMirrored" className="pull-right title-bar icon time-separator" />
            <span className="title-bar date start pull-right">{data.start('human')}</span>
        </div>;
    }

    render() {
        return (
            <div className="NavBar">
                <div className="navbar-container logo ms-font-xl">
                    <IconButton iconProps={{ iconName: this._getNavbarIcon() }} title="HomeSolid" ariaLabel="HomeSolid" className="menu-icon"/>
                    <strong>{this._getNavbarTitle()}</strong>
                    { this._renderOtherInformation() }
                    <IconButton iconProps={{ iconName: "OutOfOffice" }} title="HomeSolid" ariaLabel="HomeSolid" className="menu-icon pull-right" onClick={this._handleClick}/>
                    <NetworkIndicator />
                </div>
            </div>
        );
    }
}

// Take the redux store
const mapStateToProps = state => {
    // From redux store, take only viewChange action
    const viewData = state.viewChange.viewData;
    // Return the view data of view change redux and set it to component props
    return { viewData };
};

export default withRouter(connect(
  mapStateToProps,
  { logout }
)(Header));
