import React from 'react';

import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Label } from 'office-ui-fabric-react/lib/Label';

import '../styles/TaskError.css'

class TaskError extends React.Component {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'TaskError'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true
        }
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // Init code var
        let code;
        // Init message var
        let message;

        // If got an error
        if(this.props.error) {
            // Assign code
            code = this.props.error.response.status;
            // Assign messsage
            message = this.props.error.response.data.detail;
        }

        return (
            <div className='fixed-full-size-overlay' hidden={this.props.hidden}>
                <div className="center-vertical center-horizontal">
                    <FontIcon iconName="Sad" className="error-icon" />
                    <Label className="error-title">{code}</Label>
                    <Label className="error-message">{message}</Label>
                </div>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default TaskError;

// const el = document.body
// ReactDOM.render(<TaskError name='John' />, el)
