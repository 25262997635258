import React, { memo } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

import WordUtil from '../classes/WordUtil.js';

const Word  = props => {
    // Create a copy
    let wordObject = {...props.transcriptionWord};
    // Compose an unique id
    wordObject['id'] = props.transcriptionWord['id'];

    // Get seconds
    let seconds = parseInt(props.transcriptionWord['time']);
    // Calculate the seconds at
    wordObject['seconds'] = seconds;
    // Store seconds accurate
    wordObject['seconds-accurate'] = props.transcriptionWord['time'];

    // Take add seconds to time and take unixtimestamp
    let unix = moment(props.task.raw.start_datetime).add(
        props.transcriptionWord['time'], 'seconds'
    ).valueOf();
    // Override time
    wordObject['time'] = unix;
    // Get all metadata availabe from server download
    let tempMetadata = WordUtil.getMetadataForWord(
        wordObject, props.task.metadata()
    );

    // Get all metadata fixed with HOTFIX
    let fixedKeyword = WordUtil.getMetadataForWord(
        wordObject, props.keywords
    );

    // Filter all metadata that IS NOT in hotfix metadata
    tempMetadata = tempMetadata.filter(meta => {
        // CHeck if current metadata is in hotfix metadata
        let found = fixedKeyword.find(keyword => keyword.id() === meta.id());

        // If is not found, add it into tempMetadata
        if (!found) return true

        // Otherwise don't add it, already is in fixedKeywords!
        else return false;
    });

    // Merge all metadata except fixed keyword with fixed keyword
    tempMetadata = [...tempMetadata, ...fixedKeyword];
    // Filter only meta to be printed
    tempMetadata = tempMetadata.filter(meta => meta.haveToPrint());
    // Add to wordObject to return the normal workflow
    wordObject['metadata'] = tempMetadata;

    // HOTFIX: END HERE!!!

    // Get all clips availabe
    wordObject['clips'] = WordUtil.getClipsForWord(
        wordObject, props.task.clips()
    );

    // // Check if is selected or not
    // wordObject['selected'] = WordUtil.isTargetSelected(
    //     wordObject['clips'],
    //     props.selected
    // );
    // Init classes to apply
    let classes = "transcription-word";
    // Cast to unix timestamp
    let time = wordObject.time
    // Ghost tags are tags not based on word: like tag or thumbnail.
    let ghosts = [];
    // Init nodes array
    let nodes = [];

    // If is selected
    if (wordObject.selected) {
        // Add selected class to word
        classes += " selected";
    }

    // Iterate each metadata
    let metadata = wordObject.metadata.filter((meta) => {
        // If meta is
        if (meta.type() === 'tag' || meta.type() === 'thumbnail') {
            // Push the metadata inside ghosts
            ghosts.push(meta);
            // Don't add it to metadata, is a ghost
            return false;
        }
        // Otherwise if is normal metdata like keywords
        else {
            // Add class the current word is a tag
            classes += " tag tag-" + meta.type();
            // Return tags
            return true;
        }
    });

    // Take care of the number of incomoplete clip
    let incompleteClipsCount = 0;
    // Iterate each clips and check if is OK or not
    wordObject.clips.forEach(c => !c.isOK() ? incompleteClipsCount++ : false);

    // Iterate each clips
    wordObject.clips.forEach((clip, i) =>{

        // If current word has almost 1 clip with metadata missing
        if (incompleteClipsCount > 0) {
            // Take classes and check if has more then 1 clip incomplete
            classes += incompleteClipsCount > 1
                // Take a darker color
                ? " clip metadata-missing" + incompleteClipsCount
                // If only 1, take a brighter
                : " clip metadata-missing";
        }
        // Otherwise calculate the clip color
        else {
            // Add "clip" class to word, if has more then 1 clip,
            // use "clip1", "clip2" ...
            classes += i > 0 ? " clip" + i : " clip";
        }
    });

    // If has not ghost tags
    if (ghosts.length > 0) {
        // ... Otherwise compose a word tag + a ghost tag based on what king of ghost is
        nodes = ghosts.map(function(ghost) {
            // Calculate the id
            let iconId = "icon-" + ghost.id();
            // Calculate the value
            let value = ghost.type() === "thumbnail" ? "thumbnail" : ghost.value();
            // COMBAK: I added transcription-word class to make compatibile
            // with style: remove if there are problems.
            return <div
                key={ghost.id()}
                className={"transcription-word tag tag-" + ghost.type()}
                data-id={ghost.id()}
                data-metadata={JSON.stringify([ghost])}
                data-clips={JSON.stringify(wordObject.clips)}
                data-seconds={wordObject.seconds}
                data-seconds-accurate={wordObject['seconds-accurate']}
                data-seconds-length={wordObject['length']}
                data-datetime={time}
                onDoubleClick={props.handleDoubleClick}
                onClick={props.handleClick}
                onMouseUp={props.handleMouseUp}
                >
                <TooltipHost
                    id={"tip-" + iconId}
                    className="tooltip-metadata"
                    content={value}
                    calloutProps={{
                        gapSpace: 0,
                        target: `#${iconId}`
                    }}>
                <Icon id={iconId} iconName="LocationDot" /></TooltipHost>&nbsp;
                </div>;
        });
    }

    // Compose normal word tag
    let wordJSX = <div
        id={wordObject.id}
        key={wordObject.id}
        className={classes}
        data-id={wordObject.id}
        data-metadata={JSON.stringify(metadata)}
        data-clips={JSON.stringify(wordObject.clips)}
        data-seconds={wordObject.seconds}
        data-seconds-accurate={wordObject['seconds-accurate']}
        data-seconds-length={wordObject['length']}
        data-datetime={time}
        onDoubleClick={props.handleDoubleClick}
        onClick={props.handleClick}
        onMouseUp={props.handleMouseUp}
        >
            {nodes ? nodes : null}
            {wordObject.data}&nbsp;
        </div>;

    if (!wordObject.metadata || wordObject.metadata.length === 0) {
        return wordJSX;
    }

    // Get the JSX elements of tooltip
    let content = _composeTooltipKeywordContent(wordObject);
    // Render toolip with word
    return <TooltipHost
        id={"tip-" + wordObject.id}
        key={"tip-" + wordObject.id}
        className="tooltip-metadata"
        content={content}
        calloutProps={{
            gapSpace: 0,
            target: `#${wordObject.id}`
        }}>
        {wordJSX}
    </TooltipHost>
}

/**
 * Compose the tooltip keyword
 * @param       Object word     Word object with all props
 * @return      JSX             The element rendered
 */
function _composeTooltipKeywordContent(word) {
    // Init lists
    let lists = [];

    // Iterate each clips
    word.clips.forEach(clip => {
        // For each clips iterate each profiles
        clip.profiles().forEach(profile => {
            // Push a row
            lists.push(
                <li key={"tooltip-row-" + word.id } className="tooltip-row">
                    <b>  Id: </b>{profile.id};
                    <b>  Nome: </b>{profile.customer_name};
                    <b>  Note: </b>{
                        !profile.notes || profile.notes.length === 0
                            ? "Nessuna nota" : profile.notes
                        };
                </li>
            );
        })
    });

    // If no elements was composed, return undefined
    if (lists.length === 0) return undefined;

    // Return the div with an UL and
    return <span style={{ margin: 0, padding: 0 }}>{lists}</span>;
}

// if your data prop is an object you can't just use "==="
function arePropsEqual(prevProps, nextProps) {
    // DEBUG: continue here to debug
    // _differences(prevProps, nextProps);

    // If there are new clip
    if (_haveDifferencesClip(prevProps.raw.clips,
        nextProps.raw.clips,
        prevProps.transcriptionWord,
        prevProps.task.raw.start_datetime)) return false;

    // Get all selected clips in prev props
    let prevSelectedClips = prevProps.selected
        ? prevProps.selected.map(select => select.data.raw) : [];
    // Get all selected clips in next props
    let nextSelectedClips = nextProps.selected
        ? nextProps.selected.map(select => select.data.raw) : [];

    // Check if there are differences between selected clips
    if (_haveDifferencesClip(prevSelectedClips,
        nextSelectedClips,
        prevProps.transcriptionWord,
        prevProps.task.raw.start_datetime)) return false;

    // Check if profiles are changed (like if all profiles are complete)
    if (_haveDifferencesProfile(
        prevProps.task.clips(), nextProps.task.clips()
    )) return false;

    // If there are new metadata
    if (_hasDifferencesMetadata(prevProps.raw.metadata,
        nextProps.raw.metadata,
        prevProps.transcriptionWord,
        prevProps.task.raw.start_datetime)) return false;

    // If there new word props
    if (!_.isEqual(
        prevProps.transcriptionWord, nextProps.transcriptionWord)
    ) return false;

    // Otherwise return true
    return true;
}

// function _differences(prev, next) {
//     let pClips = prev.task.clips().map(
//         clip => { return {'id': clip.id(), 'hash': clip.hash()} }
//     );
//     let pMetas = prev.task.metadata().map(
//         meta => { return {'id': meta.id(), 'hash': meta.hash()} }
//     );
//
//     let nClips = next.task.clips().map(
//         clip => { return {'id': clip.id(), 'hash': clip.hash()} }
//     );
//     let nMetas = next.task.metadata().map(
//         meta => { return {'id': meta.id(), 'hash': meta.hash()} }
//     );
//
//     let changedClips = diffId(pClips, nClips);
//     let changedMetas = diffId(pMetas, nMetas);
// }

// function diffId(prev, next) {
//     let newEl = next.filter(
//         el => !prev.find(p => p.id === el.id)
//     );
//     let oldEl = prev.filter(
//         el => !next.find(n => n.id === el.id)
//     );
//     return [...newEl, ...oldEl];
// }

/**
 * Compare old props and new props
 * @param  array  a     The array of prev metadata
 * @param  array  b     The array of next metadata
 * @param  object  word  The word object
 * @param  string  start The datetime of task start
 * @return boolean       True if somethig was changed between prev and next
 */
function _hasDifferencesMetadata(a, b, word, start) {
    // If there are new metadata
    if (_.isEqual(a, b)) return false;
    // Get an array of differences
    let diffs = _.differenceWith(b, a, _.isEqual);

    // Get the unix timestamp
    let unix = moment(start).add(
        word['time'], 'seconds'
    ).valueOf();

    // Iterate each new metadata
    for (var i = 0; i < diffs.length; i++) {
        // If metadata unix timestamp is equal to word unix timestamp, return true
        if (moment(diffs[i]['start_datetime']).valueOf() === unix) return true;
    }

    // Otherwise return false
    return false;
}

/**
 * Compare old props and new props
 * @param  array  a     The array of prev clips
 * @param  array  b     The array of next clips
 * @param  object  word  The word object
 * @param  string  start The datetime of task start
 * @return boolean       True if somethig was changed between prev and next
 */
function _haveDifferencesClip(a, b, word, start) {
    // If there are new clips
    if (_.isEqual(a, b)) return false;
    // Get an array of differences of b not in A
    let diffsA = _.differenceWith(b, a, _.isEqual);
    // Get an array of differences of A not in B
    let diffsB = _.differenceWith(a, b, _.isEqual);
    // Union of diffs
    let diffs = diffsA.concat(diffsB);
    // Get the unix timestamp
    let unix = moment(start).add(
        word['time'], 'seconds'
    ).valueOf();

    // Iterate each new clips
    for (var i = 0; i < diffs.length; i++) {
        // If clips unix timestamp is equal to word unix timestamp, return true
        if ( (unix >= moment(diffs[i]['start_datetime']).valueOf())
            && (unix <= moment(diffs[i]['end_datetime']).valueOf())
        ) return true;
    }

    // // Init profile OK to true
    // let aOK = true;
    // let bOK = true;
    //
    //
    // // Iterate each profiles
    // for (var i = 0; i < a.profiles.length; i++) {
    //     // If is not missing metadata, continue next
    //     if (!Utility.isMetadataMissing(a.profiles[i])) continue;
    //     // Otherwise profiles are not OK!
    //     aOK = false;
    //     // Break for loop
    //     break;
    // }
    //
    // // Iterate each profiles
    // for (var i = 0; i < b.profiles.length; i++) {
    //     // If is not missing metadata, continue next
    //     if (!Utility.isMetadataMissing(b.profiles[i])) continue;
    //     // Otherwise profiles are not OK!
    //     bOK = false;
    //     // Break for loop
    //     break;
    // }
    //
    // // If a is different from b
    // if (aOK !== bOK) return true;

    // Otherwise return false
    return false;
}

/**
 * Check if profiles have some differences like metadata completed or clip
 * missing
 * @param array prevClips [description]
 * @param array nextClips [description]
 * @return      {[type]}           [description]
 */
function _haveDifferencesProfile(prevClips, nextClips) {
    // Init value that says if the 2 clips have profiles differences
    let areDifferences = false;

    // Iterate each old clips
    for (var i = 0; i < prevClips.length; i++) {
        // Take the old clip
        let oldClip = prevClips[i];
        // Take the same clip from new clip
        let newClip = nextClips.find(nClip => nClip.id() === oldClip.id());

        // If the clip was found AND have the same value of OK, continue next
        if (newClip && oldClip.isOK() === newClip.isOK()) continue;

        // Otherwise it meas that new clip was not found OR has different
        // profiles value. Set that the profiles are not equals.
        areDifferences = true;
        // Break to exit
        break;
    }

    // Return if are **DIFFERENCES** the same or not
    return areDifferences;
}

export default memo(Word, arePropsEqual);
