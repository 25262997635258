import moment from 'moment';
import sha1 from 'sha1';

import Metadata from './Metadata.js';
import WordUtil from '../WordUtil.js'
import Utility from '../Utility.js';


const Clip = {
    /**
     * Clip data downloaded from server
     * @type object
     */
    raw: {},

    /**
     * Check if clip is selected or not
     * @type boolean
     */
    selected: false,

    /**
     * Init object and return self instance
     * @param  object data  Data downloaded form server
     * @return object       Self object
     */
    init: function(data) {
        // Log in debug the call
        // Logger.write('Clip@init -> call start.', 0, data);
        // Store data into raw value
        this.raw = data;
        // Return self instance
        return {...this};
    },

    /*
    |-------------------------------------------------------------------------|
    |                               GETTER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Return clip id value
     * @return string    Clip id
     */
    id: function() {
        // Return clip id value
        return this.raw.id;
    },

    /**
     * Return clip value
     * @return string    Clip value
     */
    value: function() {
        // Return clip value value
        return this.raw.repr;
    },

    /**
     * Return clip selected value
     * @return boolean    Clip selected
     */
    isSelected: function() {
        // Return clip selected value
        return this.selected;
    },

    /**
     * Return the start date time of the task
     * @param  string format    The format to be returned (unix, moment, string, human)
     * @return multy            The value formatted, string value as default
     */
    start: function(format) {
        // Return value formatted
        return Utility.momentFormat(this.raw.start_datetime, format);
    },

    /**
     * Return the end date time of the task
     * @param  string format    The format to be returned (unix, moment, string, human)
     * @return multy            The value formatted, string value as default
     */
    end: function(format) {
        // Return value formatted
        return Utility.momentFormat(this.raw.end_datetime, format);
    },

    /**
     * Return clip profiles
     * @return array    Array of js object profiles
     */
    profiles: function() {
        // Return clip profiles value
        return this.raw.profiles || [];
    },

    /**
     * Return clip metadata
     * @return array    Array of metadata objects
     */
    metadata: function() {
        // If metadata are empty
        return !this.raw.metadata
            // Return undefined
            ? []
            // Otherwise, map each element
            : this.raw.metadata.map(metadata => {
                // Return metadata object
                return Metadata.init(metadata);
            });
    },

    /**
     * Return the metadata that match the id passed
     * @param  integer id  The type to match
     * @return Metadata    The metadata object
     */
    getMetadataFromId: function(id) {
        // If metadata are empty return undefined
        if(!this.raw.metadata) return undefined;
        // Find correct metadata
        let meta = this.raw.metadata.find(metadata => metadata.id === id);
        // Return the metadata object if found, undefined otherwise
        return meta ? Metadata.init(meta) : undefined;
    },

    /**
     * Return the metadata that match the type passed
     * @param  string type  The type to match
     * @return Metadata     The metadata object
     */
    getMetadataFromType: function(type) {
        // If metadata are empty return undefined
        if(!this.raw.metadata) return undefined;

        // NOTE: is suppose to have just one type for metadata in clip
        let meta = this.raw.metadata.find(metadata => metadata.type === type);
        // Return the metadata object if found, undefined otherwise
        return meta ? Metadata.init(meta) : undefined;
    },

    /**
     * Return all the metadata that match the type passed
     * @param  string type  The type to match
     * @return array        Array of metadata objects
     */
    getMetadatasFromType: function(type) {
        // If metadata are empty return undefined
        if(!this.raw.metadata) return undefined;

        // NOTE: is suppose to have just one type for metadata in clip
        let metas = this.raw.metadata.filter(metadata => metadata.type === type);
        // Iterate each metadata and init it
        return metas.map(meta => Metadata.init(meta));
    },

    /**
     * Add a new metadata
     * @param  object newMeta   Raw data js object
     * @return boolean          True if stored
     */
    addMetadata: function(newMeta) {
        // If newMeta is undefined
        if (!newMeta) return false;
        // If stored metadata are empty, init them
        if (!this.raw.metadata) this.raw.metadata = [];

        // If raw already contains newMeta type exept for clip pause (are multiple)
        if (this.raw.metadata.some(
            meta => meta.type && newMeta.type !== "clip_pause"
                    && (meta.type === newMeta.type)
        )) {
            // Return true
            return true;
        }

        // Update metadata with new one
        this.raw.metadata = [...this.raw.metadata, newMeta];
        // Return true
        return true;
    },

    /**
     * Remove a metadata to raw data of task
     * @param  integer id   The id of the metadata to be removed
     * @return boolean      True if added
     */
    removeMetadata: function(id) {
        // If new is undefined
        if (!id) return false;
        // If stored metadata are empty, init them
        if (!this.raw.metadata) this.raw.metadata = [];
        // Update with all metadata excempt the deleted metadata
        this.raw.metadata = this.raw.metadata.filter(
            // Return if metadata.id is not the same of data.id
            metadata => metadata.id !== id
        );
        // Return true
        return true;
    },

    /**
     * This is a custom method build because the pause clip Metadata
     * has not an ID between new insert and the clip path
     * @param  string base64    The base64 value to be checked
     * @return boolean
     */
    removePauseMetadata: function(base64) {

        // If new is undefined
        if (!base64) return false;

        // If stored metadata are empty, init them
        if (!this.raw.metadata) this.raw.metadata = [];

        // Take all metadata except pause
        let noPause = this.raw.metadata.filter(
            meta => meta.type !== "clip_pause"
        );
        // Take all pause metadata except the current to be removed
        let filtered = this.raw.metadata.filter(
            meta => {
                // If is pause metadata
                if (meta.type === "clip_pause") {
                    // Calculate base64
                    let newBase64 = btoa(
                        meta.start_datetime+"-"+meta.end_datetime
                    );
                    // If is the same, return true to filter it
                    if (newBase64 !== base64) return true;
                }

                // Return false
                return false;
            }
        );

        // Concat 2 array
        this.raw.metadata = [...noPause, ...filtered];
        // Return true
        return true;
    },

    /**
     * Edit a metadata passing new raw information
     * @param  object newMetadata   The raw data of the metadata to be added
     * @return boolean              True if added
     */
    editMetadata: function(newMetadata) {
        // Remove metadata first
        this.removeMetadata(newMetadata.id);
        // Add metadata after
        this.addMetadata(newMetadata);
        // Return true
        return true;
    },


    /*
    |-------------------------------------------------------------------------|
    |                               SETTER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Set clip selected value
     * @param boolean   selected    Clip selected
     */
    setSelected: function(selected) {
        // Set clip selected value
        this.selected = selected ? true : false;
    },

    /*
    |-------------------------------------------------------------------------|
    |                               OTHER METHODS                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Get the HTML word element from
     * @param  Task     task          Task Model Object
     * @param  boolean  first         Have to take first or last word?
     * @return HTMLElement            The HTML element
     */
    getHtmlWord: function(task, first) {
        // If first is undefined, take true as default, otherwise use that val
        first = first === undefined ? true : first;
        // Get moment start
        let momentTask = task.start('moment');
        // Get clip start or the clip end, depends on first TRUE/FALSE
        let momentClip = first ? this.start('moment') : this.end('moment');
        // Get seconds number differences from clip point and tasks start
        let seconds = moment.duration(momentClip.diff(momentTask)).asSeconds();
        // Take only integer part
        seconds = Math.floor(seconds);
        // Get the box
        let box = document.querySelector('#transcription-box');
        // Get all matches for the seconds
        let nodes = box.querySelectorAll(
            ".transcription-word[data-seconds='" + seconds + "']"
        );

        // If no nodes was found, get the same +1 sec
        if (nodes.length === 0) nodes = box.querySelectorAll(
            ".transcription-word[data-seconds='" + (seconds+1) + "']"
        );

        // If no nodes was found, get the same -1 sec
        if (nodes.length === 0) nodes = box.querySelectorAll(
            ".transcription-word[data-seconds='" + (seconds-1) + "']"
        );

        // Get the word, if we are searching first take first node, otherwise last
        return first ? nodes[0] : nodes[nodes.length - 1];
    },

    /**
     * Check if all profiles are OK, it means that clip is OK
     * @return boolean  True if all profiles are OK
     */
    isOK: function() {
        // Init profile OK to true
        let profilesOK = true;
        // Load all clip profiles
        let profiles = this.profiles();

        // Iterate each profiles
        for (var i = 0; i < profiles.length; i++) {
            // If is not missing metadata, continue next
            if (!Utility.isMetadataMissing(profiles[i])) continue;
            // Otherwise profiles are not OK!
            profilesOK = false;
            // Break for loop
            break;
        }

        // Return if all profile is OK
        return profilesOK;
    },

    /**
     * Return the text of current clip from the transcription passed
     * @param  array transcription  Array of words
     * @return string               The text of clip
     */
    textFromTranscription: function(transcription, task) {
        // Init text
        let text = "";
        // Save the scope
        let me = this;
        // Unit unix var
        let unix;

        // Iterate each word
        transcription.forEach( word => {
            // Make a copy of the word
            let wordObj = {...word};
            // Take add seconds to time and take unixtimestamp
            unix = task.start('moment').add(
                wordObj['time'], 'seconds'
            ).valueOf();
            // Override time
            wordObj['time'] = unix;
            // If word doesn't contains this clips, return true
            return Utility.isEmpty(WordUtil.getClipsForWord(wordObj, [me]))
            // Otherwise cocnatenate word data
            || (text += word.data + " ")
        });
        // Return the words
        return text;
    },

    /**
     * Return sha1 of raw data
     * @return string  The sha1 string of eaw data
     */
    hash: function() {
        // Make the sha1 and return
        return sha1(this.raw);
    }

}
export default Clip
