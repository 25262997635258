import React from 'react';
import ReactDOM from 'react-dom'

// Redux actions and store
import { connect } from 'react-redux';
import { changeView } from '../../redux/actions';
// FabricUI components
import { Icon } from 'office-ui-fabric-react/lib/Icon';
// import { IconButton } from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Checkbox, Label } from 'office-ui-fabric-react';
import { DetailsListLayoutMode, Selection, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import Pagination from 'office-ui-fabric-react-pagination';
// Import components
import RemoteCombobox from '../partials/RemoteCombobox.js'
import ErrorModal from '../partials/modals/ErrorModal.js'
import RejectClipsAndWorkModal from '../partials/modals/RejectClipsAndWorkModal.js'
import RejectClipsAndQueueModal from '../partials/modals/RejectClipsAndQueueModal.js'

// Classes and components
import Api from '../classes/Api.js';
import Config from '../classes/Config.js';
import Logger from '../classes/Logger.js';
import Utility from '../classes/Utility.js';
import history from '../classes/History.js';
import Permission from '../classes/Permission.js';
// Import style
import '../styles/TaskList.css';

import TaskRowAccordion from '../partials/TaskRowAccordion.js';


// SEE: https://developer.microsoft.com/en-us/fabric#/components/detailslist#Overview
class TaskList extends React.Component {
    _selection;

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'Lista Task',
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Init selection
        this._selection = this._getSelection();
        // Create the action payload
        let data = {
            'name': 'task_list',
            'data': {'name':this.props.name}
        };
        // Dispatches action to change View
        this.props.changeView(data);
        // Load permission
        let mode = Permission.isModerator() ? "list" : "button";
        // Init tasks
        let tasks = [];
        // Init currentPage var
        let currentPage = 1;
        // Init limit var
        let limit = Config.get('DEFAULT_PAGINATION_ROW', 20);
        // Init count var
        let count = 0;
        // Define descending
        let order = "desc";
        // Define the default ordering
        let sort = "id";

        // Set default state
        this.state = {
            visible: true,
            value: '',
            items: tasks,
            columns: this._composeColumns(sort, order),
            selectionDetails: this._getSelectionDetails(),
            mode: mode,
            nextTaskId: undefined,
            currentPage: currentPage,
            count: count,
            limit: limit,
            sort: sort,
            order: order,
            // 2019/10/02: was clip__isnull=0
            // 2019/11/27: was status__name=created&clip__isnull=0
            // 2019/11/27: refatored from string to *object!*
            defaultFilters: {
                status__id__in: [
                    Config.get('DEFAULT_STATUS_CREATED_ID'),
                    Config.get('DEFAULT_STATUS_APPROVED_ID'),
                    Config.get('DEFAULT_STATUS_IN_PROCESS_ID'),
                    Config.get('DEFAULT_STATUS_DONE_ID'),
                ].join(',')
            },
            filters: {},
            isDataLoaded: false,
            totalPages: this._getPaginatorTotalPages(limit, count),
            showRejected: false,
            filterProfileId: null
        };

        // If mode is button
        if (mode === "button") {
            // Load next task
            this._loadNextTaskId().then(id => {
                // Set next task id
                this.setState({ nextTaskId: id });
            });
            // EXIT! You have not to show list
            return true;
        }

        // Load tasks
        this._loadTasks(currentPage, sort, order).then(data => {
            // Set data to task
            tasks = data.results || [];
            // Recalculate count
            count = data.count || count;
            // Set the items into state
            this.setState({
                items: tasks,
                isDataLoaded: true,
                currentPage: currentPage,
                count: count,
                totalPages: this._getPaginatorTotalPages(limit, count),
            });
        });
    }

    /*
    |-------------------------------------------------------------------------|
    |                               ASYNC DOWNLOADING                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Load all task to be showed
     * @param  integer  page    The page to be loaded
     * @param  string   sort    The sorting value (id, name, date_from...)
     * @param  string   order   The order value (asc | desc)
     * @param  object   filters Object with key:value filters
     * @return Array            The array of task loaded
     */
    _loadTasks = (page, sort, order, filters) => {
        // Init query params var
        let queryParams = {};
        // Take the limit from state or config (if null)
        let limit = this.state.limit
            || Config.get('DEFAULT_PAGINATION_ROW', 20);
        // Compose ordering
        let ordering = order === "desc" ? ("-" + sort) : sort;

        // If there are default filters
        if (this.state.defaultFilters)
            // Merge default filters with current query parameters
            queryParams = {...queryParams, ...this.state.defaultFilters}

        // Compose page query param
        if (page) queryParams['page'] = page;

        // Compose limit query param
        if (limit) queryParams['limit'] = limit;

        // Compose ordering query param
        if (ordering) queryParams['ordering'] = ordering;

        // If there are filters, merge it with query params
        if (filters) queryParams = {...queryParams, ...filters}

        // Call get tasks API
        return Api.getTasks(queryParams).then( (response) => {
            // Log in debug the call
            Logger.write('TaskList@_loadTasks -> success.', 0, response);
            // Set tasks
            let tasks = response.data;
            // Return tasks
            return tasks;
        })
        // Catch the error
        .catch((err) => {
            // Log in error level
            Logger.write('TaskList@_loadTasks -> error.', 3, err);
            // Return tasks
            return [];
        });
    }

    /**
     * Method that load new page opf tasks
     * @param  integer page     Page to be loaded
     * @return array            The tasks loaded
     */
    _paginate = (page) => {
        // Save scope into me var
        let me = this;
        // Set loading mode ON
        this.setState({ isDataLoaded: false });
        // Remove task rows!
        this._removeTaskRowAccordion();

        // Load tasks
        this._loadTasks(page, this.state.sort, this.state.order, this.state.filters).then(data => {
            // Set the items into state
            me.setState({
                items: data.results,
                currentPage: page,
                isDataLoaded: true
            });
        });
    }

    /**
     * Remove all expanded task row accordion.
     * Use this method before paging, filtering, or make change on table
     * to avoid view don't be removed.
     * @return void
     */
    _removeTaskRowAccordion = () => {
        // Get all expanded rows
        let rows = document.querySelectorAll(".ms-List-cell.expanded");
        // Init row
        let row = null;

        // Iterate each row
        for (var i = 0; i < rows.length; i++) {
            // Get row
            row = rows[i];
            // Remove the class
            row.classList.remove("expanded");
            // Remove the element
            row.querySelector(".accordion-container").remove();
        }
    }

    /**
     * Action made from an NON-staff user.
     * Load all task to be showed
     * @return integer    The id of the task to be loaded
     */
    _loadNextTaskId = () => {
        // Call get tasks API
        return Api.getNextTask().then((response) => {
            // Log in debug the call
            Logger.write('TaskList@_loadNextTaskId -> success.', 0, response);
            // Return next task id
            return response.data.id;
        })
        // Catch the error
        .catch((err) => {
            // Log in error level
            Logger.write('TaskList@_loadNextTaskId -> error.', 3, err);
            // Return tasks
            return undefined;
        });
    }

    /**
     * UNUSED: method to get the sleection of the list.
     * @return Selection    Fabruc UI selection object
     */
    _getSelection = () => {
        return new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails()
                });
            }
        });
    }

    /**
     * UNUSED: method to get the selection detaul
     * @return string   The phrase to show on selection
     */
    _getSelectionDetails = () => {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
            return 'No items selected';
            case 1:
            return '1 item selected: ' + (this._selection.getSelection()[0]).name;
            default:
            return `${selectionCount} items selected`;
        }
    }

    /**
     * Update the selected task with the new status choosen by user.
     * Approve or Reject a task.
     * @param  integer taskId   The id of the task to be updated
     * @param  string  status   The status to be applied (approved | rejected)
     * @return void
     */
    _updateTaskStatus = (taskId, status) => {
        // Init status id
        let statusId = undefined;

        // If is approved
        if (status === "approved") {
            // Load approved id
            statusId = Config.get('DEFAULT_STATUS_APPROVED_ID');
        }
        // If is rejected
        else if (status === "rejected") {
            // Load rejected id
            statusId = Config.get('DEFAULT_STATUS_REJECTED_ID');
        }

        // Compose body
        let body = {
            'status': Config.get('URL_API_STATUS') + statusId + "/",
        }

        // Make PATCH request
        Api.partialUpdateTask(taskId, body).then((response) => {
            // Log in debug the call
            Logger.write('TaskList@partialUpdateTask -> success.', 0, response);
            // Create a mutable copy of items
            const items = this.state.items;
            // Get element
            let el = items.find(el => el.id === taskId);
            // Update status
            el.status = response.data.status;
            // Update status repr
            el.status_repr = response.data.status_repr;
            // Updated
            this.setState({ items: [...items] });
        })
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('TaskList@partialUpdateTask -> error.', 3, err);
                // Go out
                return;
            }
        );
    }

    /**
     * From the status url given, return the icon related.
     * Check the final id in the config.
     * @param  string statusUrl     The url status of the task
     * @return React.component      The Icon component
     */
    _getIconForStatus = (statusUrl) => {
        // Load created id from config. NOTE: Tasks to be approved => jellow
        let createdId = Config.get('DEFAULT_STATUS_CREATED_ID');
        // Load approved id from config. NOTE: Tasks approved => green
        let approvedId = Config.get('DEFAULT_STATUS_APPROVED_ID');
        // Load rejected id from config. NOTE: Tasks rejected => red
        let rejectedId = Config.get('DEFAULT_STATUS_REJECTED_ID');

        // Load confirmed id from configs. NOTE: Tasks already worked
        let doneId = Config.get('DEFAULT_STATUS_DONE_ID');
        // Load exported id from configs. NOTE: Tasks already exported
        let exportedId = Config.get('DEFAULT_STATUS_EXPORTED_ID');
        // Load inProcess id from configs. NOTE: Tasks in progress
        let inProcessId = Config.get('DEFAULT_STATUS_IN_PROCESS_ID');
        // Load exportable id from configs. NOTE: Tasks waiting for export
        let exportableId = Config.get('DEFAULT_STATUS_EXPORTABLE_ID');

        // If task is created
        if (Utility.resourceUrlComparer(statusUrl, createdId))
            return <Icon iconName="ConstructionCone" className="ms-ConstructionCone color-warning2"/>;
        // If task is approved
        if (Utility.resourceUrlComparer(statusUrl, approvedId))
            return <Icon iconName="12PointStar" className="ms-12PointStar color-success"/>;
        // If a task is worked and confirmed
        if (Utility.resourceUrlComparer(statusUrl, doneId))
            return <Icon iconName="CompletedSolid" className="ms-CompletedSolid color-success" />;
        // If a task rejected
        if (Utility.resourceUrlComparer(statusUrl, rejectedId))
            return <Icon iconName="Blocked" className="ms-Blocked color-danger" />;
        // Theorically this will never used
        if (Utility.resourceUrlComparer(statusUrl, exportedId))
            return <Icon iconName="MailForwardMirrored" className="ms-MailForwardMirrored" />;
        // If task in process by an user
        if (Utility.resourceUrlComparer(statusUrl,  inProcessId))
            return <Icon iconName="PenWorkspace" className="ms-PenWorkspace color-warning" />;
        // If task can be exported
        if (Utility.resourceUrlComparer(statusUrl, exportableId))
            return <Icon iconName="WavingHand" className="ms-WavingHand" />;

        // Return defualt
        return <Icon iconName="12PointStar" className="ms-12PointStar"/>;
    }

    /**
     * Get the total number of pages for current pagination
     * @param  integer limit    How many results for each page
     * @param  integer count    The total number of results
     * @return integer          The total number of pages
     */
    _getPaginatorTotalPages = (limit, count) => {
        // If count / limit (take the greatest approx) is < 1, return 1
        return Math.ceil(count/limit) > 0 ? Math.ceil(count/limit) : 1;
    }

    /**
     * Load next task
     * @param  integer id   The id of the next task
     * @return void
     */
    _goNextTask = (id) => {
        // If id is not defined
        if (!id) return false;
        // Compose url
        let url = "/tasks/" + id;
        // Push new url
        history.push(url);
        // HACK: call go to load page
        history.go(url);
    }

    /*
    |-------------------------------------------------------------------------|
    |                               ACTION HANDLERS                           |
    |-------------------------------------------------------------------------|
    */

    /**
     * Click action on row action dropdown button option.
     * @param  Event    ev      Click event
     * @param  Object   dataset The data from component composed
     * @return void
     */
    _onRowActionClicked = (ev, dataset) => {
        // If datasert is null, it means that is the main default action
        let action = dataset.data ? dataset.data.action : undefined;
        // Get the record id to be edited
        let id = dataset.data ? dataset.data.taskId : undefined;

        // Action AND id are mandatory
        if (!action || !id) return false;

        // Call update task status actino
        this._updateTaskStatus(id, action);
    }

    /**
     * When an item is double clicked
     * @param  Item item    The item double clicked
     * @return void
     */
    _onItemInvoked = (item) => {
        // Take the id and load next task
        return this._goNextTask(item.id);
    }

    /**
     * Click action on next task button
     * @return void
     */
    _onNextTaskButtonClicked = () => {
        // Load next task
        this._goNextTask(this.state.nextTaskId);
    }

    /**
     * Action of click on column header
     * @param  Event    ev      Event click
     * @param  Object   column  Column object clicked
     * @return void
     */
    _onColumnClick = (ev, column) => {
        // If sorting is not enabled
        if (!column.sortEnabled) return false;
        // Take sorting
        let sort = column.fieldName;
        // Calculate new order: if was desc, set "asc" or viceversa
        let order = column.isSortedDescending ? "asc" : "desc";
        // Reset pagination
        let currentPage = 1;
        // Init tasks
        let tasks = [];
        // Init count
        let count = 0;

        // Update state with new columns updated and loading mode ON
        this.setState({
            columns: this._composeColumns(sort, order),
            isDataLoaded: false
        });

        // Remove task rows!
        this._removeTaskRowAccordion();

        // Load tasks
        this._loadTasks(currentPage, sort, order).then(
            data => {
                // Set data to task
                tasks = data.results || [];
                // Recalculate count
                count = data.count || count;
                // Set the items into state
                this.setState({
                    items: tasks,
                    currentPage: currentPage,
                    count: count,
                    sort: sort,
                    order: order,
                    isDataLoaded: true,
                    totalPages: this._getPaginatorTotalPages(
                        this.state.limit, count
                    )
                });
            }
        );
    }

    /**
     * Action of checkbox toggled on show rejected task checkbox
     * @return void
     */
    _onToggleRejectedCheckboxChange = () => {
        // Update checkbox state
        let state = !this.state.showRejected;
        // Get filters
        let filters = {...this.state.filters};

        // If state is true, add filter
        if (state) {
            // Update filters with only 1 status filter
            filters['status__id__in'] = Config.get('DEFAULT_STATUS_REJECTED_ID')
        }
        // Otherwise remove filter
        else {
            // Update filters with only 3 status filter
            filters['status__id__in'] = [
                Config.get('DEFAULT_STATUS_CREATED_ID'),
                Config.get('DEFAULT_STATUS_APPROVED_ID'),
                Config.get('DEFAULT_STATUS_IN_PROCESS_ID'),
                Config.get('DEFAULT_STATUS_DONE_ID')
            ].join(',');
        }

        // Update state
        this.setState({
            // Update filters adding status filter
            filters: filters,
            // Update show rejected boolean
            showRejected: state,
            // Enable shimmer lazy loading
            isDataLoaded: false
        });

        // Remove task rows!
        this._removeTaskRowAccordion();

        // Load sort
        let sort = this.state.sort;
        // Load order
        let order = this.state.order;
        // Reset pagination
        let currentPage = 1;

        // Load tasks
        this._loadTasks(currentPage, sort, order, filters).then(
            data => {
                // Set data to task
                let tasks = data.results || [];
                // Recalculate count
                let count = data.count || this.state.count;
                // Set the items into state
                this.setState({
                    items: tasks,
                    currentPage: currentPage,
                    count: count,
                    sort: sort,
                    order: order,
                    isDataLoaded: true,
                    totalPages: this._getPaginatorTotalPages(
                        this.state.limit, count
                    )
                });
            }
        );
    }

    /**
     * Handle action on button inside accrodion
     * @param  string   action  The action name
     * @param  objcet data      The action object data
     * @return void
     */
    onAccordionAction = (action, data) => {
        // Log in console
        Logger.write('TaskList@onAccordionAction -> start: ' + action, 0, data);

        // If action is "reject and queue"
        if (action === "reject_clips_and_queue") {
            // Toggle dialog to confirm
            this.refs.reject_clips_and_queue_modal.toggle(data);
        }

        // If action is "reject and queue"
        if (action === "reject_clips_and_work") {
            // Toggle dialog to confirm
            this.refs.reject_clips_and_work_modal.toggle(data);
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                               ITEMS COMPOSITION                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Compose the main items of command bar.
     * Are all the items on the left of the bar
     * in the bar
     * @return array    Array of objects
     */
    _composeCommandBarItems = () => {
        // Return an array of object to be composed and rendered
        return [
            {
                key: 'show_rejected',
                text: 'Show Rejected',
                iconProps: { iconName: 'Share' },
                onRender: () => this._commandBarItemsRender('show_rejected')
            },
            // {
            //     key: 'newItem',
            //     text: 'New',
            //     // changing this key will invalidate this item's cache
            //     cacheKey: 'myCacheKey',
            //     iconProps: { iconName: 'Add' },
            //     subMenuProps: {
            //         items: [
            //             {
            //                 key: 'emailMessage',
            //                 text: 'Email message',
            //                 iconProps: { iconName: 'Mail' },
            //                 ['data-automation-id']: 'newEmailButton' // optional
            //             }
            //         ]
            //     }
            // },
        ];
    }

    /**
     * Compose the overflow items of command bar.
     * Overflow items are all the items collapsed in the last left menu icon
     * in the bar
     * @return array    Array of objects
     */
    _composeCommandBarOverflowItems = () => {
        return [
            // { key: 'move', text: 'Move to...',
            //     onClick: () => #do something,
            //     iconProps: { iconName: 'MoveToFolder' }
            // }
        ];
    }

    /**
     * Compose the far items of command bar.
     * Far items are items on the right of the bar
     * @return array    Array of objects
     */
    _composeCommandBarFarItems = () => {
        return [
            {
                key: 'label_filter_profiles',
                onRender: () => <Label className="center-commandbar-label">Filtra per profilo&nbsp;</Label>
            },
            {
                key: 'filter_profiles',
                text: 'Filter Profiles',
                onRender: () => <RemoteCombobox
                    id='profile_remote_combobox'
                    key='profile_remote_combobox'
                    class="center-commandbar-combobox"
                    label={null}
                    allowFreeform={true}
                    autoComplete={true}
                    forceSelection={true}
                    remote={Config.get('URL_API_PROFILES')}
                    valueField="id"
                    displayField="customer_name"
                    queryField="customer_name__icontains"
                    selected={this.state.filterProfileId}
                    onSelectionChange={this._onRemoteComboboxSelectionChange}
                />
            }
        ];
    }

    /**
     * Action on remote combobox
     * @param  Event ev         The change event
     * @param  Element option   The option element selected
     * @param  integer index    The index of selection
     * @param  string value     The string value of input box in case of element null
     * @return void
     */
    _onRemoteComboboxSelectionChange = (ev, option, index, value) => {
        // Define the profile id
        const profileId = option ? parseInt(option.key) : undefined;
        // Get filters
        let filters = {...this.state.filters};
        // Get the already applied profile id
        const appliedProdileId = parseInt(filters['profile_id']);

        // if the applied filter is the same of the new filter, exit
        if ((isNaN(profileId) && isNaN(appliedProdileId))
            || (profileId === appliedProdileId)) return;

        // Update filters with only 1 status filter
        filters['profile_id'] = profileId;

        // Update state
        this.setState({
            // Update filters adding status filter
            filters: filters,
            // Update show rejected boolean
            filterProfileId: profileId,
            // Enable shimmer lazy loading
            isDataLoaded: false
        });

        // Remove task rows!
        this._removeTaskRowAccordion();
        // Load sort
        let sort = this.state.sort;
        // Load order
        let order = this.state.order;
        // Reset pagination
        let currentPage = 1;

        // Load tasks
        this._loadTasks(currentPage, sort, order, filters).then(
            data => {
                // Set data to task
                let tasks = data.results || [];
                // Recalculate count
                let count = data.count || this.state.count;
                // Set the items into state
                this.setState({
                    items: tasks,
                    currentPage: currentPage,
                    count: count,
                    sort: sort,
                    order: order,
                    isDataLoaded: true,
                    totalPages: this._getPaginatorTotalPages(
                        this.state.limit, count
                    )
                });
            }
        );
    }

    /**
     * Click on the accordion button.
     * Insert an accordion inside row
     * @param  Event ev     Event click
     * @return void
     */
    _onAccordionButtonClicked = (ev) => {
        // Get the row
        let target = ev.currentTarget;
        // Get the profile ID to be deleted
        let taskId = target.dataset.id
            ? parseInt(target.dataset.id)
            : null;
        // Get row
        let row = target.closest('.ms-List-cell');

        // If row has expanded class
        if (row.classList.contains("expanded")) {
            // Add expanded class
            row.classList.remove("expanded");
            // Find the accordion container
            return document.getElementById(
                "accordion-container-" + taskId
            ).remove();
        }

        // Add expanded class
        row.classList.add("expanded");
        // Init a js container
        let container = document.createElement("DIV");
        // Set the ID
        container.id = "accordion-container-" + taskId;
        // Add accordion container class
        container.classList.add('accordion-container');
        // Insert container into row
        row.append(container);
        // Create an accordion
        let accordion = <TaskRowAccordion taskId={taskId} handleAccordionAction={this.onAccordionAction}/>
        // Render accordion inside container
        ReactDOM.render(accordion, container);
    }

    /**
     * Return the object column configuration for current table
     * @return objcet   The table colimns configuration
     */
    _composeColumns = (sort, order) => {
        // Set default sort if not defined
        sort = sort || "id";
        // Set defined order if not defined
        order = order || "desc";
        // Retunr config
        return [
            {
                key: 'column_accordion',
                fieldName: 'accordion',
                sortEnabled: false,
                className: "cell-table-custom-class accordion",
                iconClassName: 'cell-table-icon-class only-icon-list',
                iconName: 'TriangleRight12',
                isIconOnly: false,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'icon',
                isPadded: true,
                minWidth: 14,
                maxWidth: 14,
                onRender: (item) => {
                    // Return tooltip with status
                    return <Icon
                        key={"accordion-button-" + item.id}
                        className="accordion-button"
                        iconName="ScrollUpDown"
                        iconProps={{ iconName: "ScrollUpDown" }}
                        title="scroll"
                        ariaLabel="accordion"
                        onClick={this._onAccordionButtonClicked}
                        data-id={item.id}/>
                }
            },
            {
                key: 'column_id',
                fieldName: 'id',
                sortEnabled: true,
                isSorted: sort === "id" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                name: '#',
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class only-icon-list',
                ariaLabel: 'Column operations for ids, Press to sort on id.',
                iconName: 'NumberSymbol',
                isIconOnly: true,
                minWidth: 48,
                maxWidth: 48,
                isResizable: true,
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'column_source_repr',
                fieldName: 'source_repr',
                sortEnabled: true,
                isSorted: sort === "source_repr" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class',
                name: 'Emittente',
                // minWidth: 200,
                isRowHeader: true,
                isResizable: true,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true
            },
            {
                key: 'column_start_date',
                fieldName: 'start_datetime',
                sortEnabled: true,
                isSorted: sort === "start_datetime" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class',
                name: ' Data',
                iconName: 'EventDate',
                isRowHeader: true,
                isResizable: true,
                minWidth: 40,
                onColumnClick: this._onColumnClick,
                data: 'date',
                isPadded: true,
                onRender: (item) => {
                    return Utility.momentFormat(
                        item.start_datetime, 'date_short'
                    );
                }
            },
            {
                key: 'column_start_datetime',
                fieldName: 'start_datetime',
                sortEnabled: true,
                isSorted: sort === "start_datetime" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class',
                name: ' Ora Inizio',
                iconName: 'BufferTimeBefore',
                isRowHeader: true,
                isResizable: true,
                minWidth: 55,
                onColumnClick: this._onColumnClick,
                data: 'date',
                isPadded: true,
                onRender: (item) => {
                    return Utility.momentFormat(item.start_datetime, 'time');
                }
            },
            {
                key: 'column_end_datetime',
                fieldName: 'end_datetime',
                sortEnabled: true,
                isSorted: sort === "end_datetime" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class',
                name: ' Ora Fine',
                iconName: 'BufferTimeAfter',
                isRowHeader: true,
                isResizable: true,
                minWidth: 55,
                onColumnClick: this._onColumnClick,
                data: 'date',
                isPadded: true,
                onRender: (item) => {
                    return Utility.momentFormat(item.end_datetime, 'time');
                }
            },
            {
                key: 'column_creation_datetime',
                fieldName: 'creation_datetime',
                sortEnabled: true,
                isSorted: sort === "creation_datetime" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class',
                name: ' Creazione',
                iconName: 'AutoEnhanceOn',
                isRowHeader: true,
                isResizable: true,
                minWidth: 95,
                onColumnClick: this._onColumnClick,
                data: 'date',
                isPadded: true,
                onRender: (item) => {
                    return Utility.momentFormat(
                        item.creation_datetime, 'human_short'
                    );
                }
            },
            {
                key: 'column_assignment_datetime',
                fieldName: 'assignment_datetime',
                sortEnabled: true,
                isSorted: sort === "assignment_datetime" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class',
                name: ' Inizio Lavorazione',
                iconName: 'UserFollowed',
                isRowHeader: true,
                isResizable: true,
                minWidth: 95,
                onColumnClick: this._onColumnClick,
                data: 'date',
                isPadded: true,
                onRender: (item) => {
                    return item.assignment_datetime
                        ? Utility.momentFormat(
                            item.assignment_datetime, 'human_short'
                        )
                        : "-";
                }
            },
            {
                key: 'column_name',
                fieldName: 'name',
                sortEnabled: true,
                isSorted: sort === "name" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                name: 'User',
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class only-icon-list',
                ariaLabel: 'Column operations for user, Press to sort on File type',
                iconName: 'People',
                isIconOnly: true,
                minWidth: 32,
                maxWidth: 32,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    // Get the assigned user
                    let id = Utility.getResourceIdFromUrl(item.owner_user);
                    // Take only the id
                    return (
                        <div>
                            <TooltipHost
                                content={id ? "Id utente assegnato" : "Nessun utente assegnato"}
                                id={this._hostId}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                                >
                                <span>{id || "-"}</span>
                            </TooltipHost>
                        </div>
                    );
                }
            },
            {
                key: 'column_profiles',
                fieldName: 'total_matches_profiles',
                sortEnabled: false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class only-icon-list',
                name: 'Profili',
                iconName: 'MapLayers',
                isIconOnly: true,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'number',
                isPadded: true,
                minWidth: 10,
                maxWidth: 10,
                onRender: (item) => {
                    // Take only the id
                    return (
                        <div>
                            <TooltipHost
                                content="Numero profili"
                                id={this._hostId}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                                >
                                <span>{item.total_matches_profiles}</span>
                            </TooltipHost>
                        </div>
                    );
                }
            },
            {
                key: 'column_profiles_high_priority',
                fieldName: 'total_matches_profiles_with_main_priority',
                sortEnabled: false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class only-icon-list',
                name: 'Profili Alta Priorità',
                iconName: 'Important',
                isIconOnly: true,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'number',
                isPadded: true,
                minWidth: 10,
                maxWidth: 10,
                onRender: (item) => {
                    let n = item.total_matches_profiles_with_main_priority;
                    let customClass = n > 1 ? "color-warning2 text-bold" : "";
                    customClass = n > 5 ? "color-danger text-bold" : "";
                    // COMBAK: change color based on n profiles.
                    customClass = "text-bold";
                    return (
                        <div>
                            <TooltipHost
                                content="Numero profili con alta priorità"
                                id={this._hostId}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                                >
                                <span className={customClass} >{n}</span>
                            </TooltipHost>
                        </div>
                    );
                }
            },
            {
                key: 'column_profiles_priority',
                fieldName: 'priority_repr',
                sortEnabled: true,
                isSorted: sort === "priority_repr" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class only-icon-list',
                name: 'Priorità',
                iconName: 'FunnelChart',
                isIconOnly: true,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'number',
                isPadded: true,
                minWidth: 10,
                maxWidth: 10,
                onRender: (item) => {
                    // Get the repr value
                    let n = item.priority_repr;
                    // Return the call
                    return (
                        <div>
                            <TooltipHost
                                content={"Priorità: " + n }
                                id={this._hostId}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                                >
                                {n}
                            </TooltipHost>
                        </div>
                    );
                }
            },
            {
                key: 'column_status',
                fieldName: 'status',
                sortEnabled: true,
                isSorted: sort === "status" ? true : false,
                isSortedDescending: order === "desc" ? true : false,
                className: "cell-table-custom-class",
                iconClassName: 'cell-table-icon-class only-icon-list',
                iconName: 'TVMonitor',
                name: 'Stato',
                isIconOnly: true,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'icon',
                isPadded: true,
                minWidth: 10,
                maxWidth: 10,
                onRender: (item) => {
                    // Get icon for status
                    let icon = this._getIconForStatus(item.status);
                    // Return tooltip with status
                    return (
                        <div>
                            <TooltipHost
                                content={"Stato: " + item.status_repr}
                                id={this._hostId}
                                calloutProps={{ gapSpace: 0 }}
                                styles={{ root: { display: 'inline-block' } }}
                                >
                                <span>{icon}</span>
                            </TooltipHost>
                        </div>
                    );
                }
            },
            {
                key: 'approval_actions',
                sortEnabled: false,
                isSorted: false,
                isSortedDescending: false,
                name: ' Azioni',
                className: "cell-table-custom-class small-padding",
                iconClassName: 'cell-table-icon-class',
                ariaLabel: 'Column for Approval actions.',
                iconName: 'Manufacturing',
                isIconOnly: false,
                minWidth: 120,
                onColumnClick: this._onColumnClick,
                onRender: (item) => {
                    let me = this;
                    return  <PrimaryButton
                            text="Azioni..."
                            split
                            splitButtonAriaLabel="See actions"
                            aria-roledescription="split button"
                            menuProps={{
                                items:[
                                    {
                                        key: 'approved',
                                        text: 'Approva',
                                        data: {action: 'approved', taskId: item.id},
                                        iconProps: { iconName: '12PointStar' },
                                        onClick: me._onRowActionClicked
                                    },
                                    {
                                        key: 'rejected',
                                        text: 'Rifiuta',
                                        data: {action: 'rejected', taskId: item.id},
                                        iconProps: { iconName: 'Blocked' },
                                        onClick: me._onRowActionClicked
                                    }
                                ]
                            }}
                        />
                }
            },
        ];
    }

    /**
     * Manage dialog dismiss action
     * @return void
     */
    onActionDismiss = () => {
        // Do nothing for now
        return false;
    }

    /**
     * Manage dialog response receive.
     * @return void
     */
    onActionResponse = (response) => {
        // Get the response of API call
        const data = response.data;
        // Get the mode
        const mode = response.mode;

        // If we have an reject clips and work
        if (mode === "reject_clips_and_work") {
            // Get the taskId
            const taskUrl = "/tasks/" + response.taskId + "/";

            // If we have response data
            if (data && data.length > 0) {
                // It meas that we have to show some errors
                this.refs.error_modal.toggle(data)
            }
            // Otherwise
            else {
                // Otherwise go to task/{id} url
                history.push(taskUrl);
                // Load page
                history.go();
            }
        }

        // If we have an reject clips and queue task
        if (mode === "reject_clips_and_queue") {

            // If we have response data
            if (data && data.length > 0) {
                // It meas that we have to show some errors
                this.refs.error_modal.toggle(data)
            }
            // Otherwise
            else {
                // Get updated task
                const task = response.task;
                // Create a mutable copy of items
                const items = this.state.items;
                // Get element
                let el = items.find(el => el.id === response.taskId);
                // Update status
                el.status = task.status;
                // Update status repr
                el.status_repr = task.status_repr;
                // Updated
                this.setState({ items: [...items] });

                // Get all checkboxes
                let button = document.querySelector(
                    '.accordion-button[data-id="' + response.taskId + '"]'
                );

                // If button was found
                if (button) {
                    // Get row
                    let row = button.closest('.ms-List-cell');
                    // Add expanded class
                    row.classList.remove("expanded");
                    // Find the accordion container
                    document.getElementById(
                        "accordion-container-" + response.taskId
                    ).remove();
                }
            }
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                                   RENDERS                               |
    |-------------------------------------------------------------------------|
    */

    _commandBarItemsRender = (key) => {
        // Switch on key passed
        switch (key) {
            // If is show_rejected
            case 'show_rejected': {
                // Compose checkbox to show rejected
                return <Checkbox
                    label="Mostra Task Rifiutate"
                    className="center-commandbar-checkbox"
                    onChange={this._onToggleRejectedCheckboxChange}
                    checked={this.state.showRejected} />;
            }
            default: {
                return null;
            }
        }


    }

    /**
     * Render table footer with custom components
     * @return React.component      React component to be rendered
     */
    _onRenderDetailsFooter = () => {
        // publishers/?page=1&limit=30
        // response => {count:250}
        return <Pagination
            currentPage={this.state.currentPage}
            totalPages={this.state.totalPages}
            onChange={(page) => this._paginate(page)}
        />;
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // Get the loading value
        const { isDataLoaded } = this.state;

        return (
            <div className='content'>
                <div>
                    <CommandBar
                        items={this._composeCommandBarItems()}
                        overflowItems={this._composeCommandBarOverflowItems()}
                        overflowButtonProps={{ ariaLabel: 'More commands' }}
                        farItems={this._composeCommandBarFarItems()}
                        className={this.state.mode === "list" ? '' : 'hidden'}
                        ariaLabel="Use left and right arrow keys to navigate between commands"
                        />
                </div>
                <ShimmeredDetailsList
                    className={this.state.mode === "list" ? '' : 'hidden'}
                    items={this.state.items}
                    compact={false}
                    columns={this.state.columns}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    enableShimmer={!isDataLoaded}
                    onItemInvoked={this._onItemInvoked}
                    enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    onRenderDetailsFooter={this._onRenderDetailsFooter}
                />

            <div className={this.state.mode === "list" ? 'hidden' : 'go-next-button-container'}>
                <PrimaryButton onClick={this._onNextTaskButtonClicked} text="Carica prossima task" disabled={this.state.nextTaskId ? false : true}/>
            </div>

            <RejectClipsAndWorkModal
                ref="reject_clips_and_work_modal"
                task={this.state.task}
                handleActionResponse={this.onActionResponse}
                handleActionDismiss={this.onActionDismiss}
                />
            <RejectClipsAndQueueModal
                ref="reject_clips_and_queue_modal"
                task={this.state.task}
                handleActionResponse={this.onActionResponse}
                handleActionDismiss={this.onActionDismiss}
                />
            <ErrorModal
                ref="error_modal"
                handleActionDismiss={this.onActionDismiss}
                />
        </div>);
    }
}

// Export default component to be accessible in other components
export default connect(
  null,
  { changeView }
)(TaskList);
