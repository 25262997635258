// Framework imports
import React from 'react';
// Fabric UI component imports
import { ComboBox } from 'office-ui-fabric-react';
// Core classes imports
import Api from '../classes/Api';
import Config from '../classes/Config';
import Logger from '../classes/Logger';
// Style imports
import '../styles/RemoteCombobox.css'


class RemoteCombobox extends React.Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        // Name of current component
        name: 'RemoteCombobox',
        // The id, ref name, key name of component
        id: 'remote_combobox',
        // The custom class to be applied
        class: 'remote-combobox',
        // The label to display uppon the component
        label: 'Seleziona un\'opzione',
        // Set true to enable the input text writing and let user type strings
        allowFreeform: false,
        // Set true to show the auto complete based on dropdwon option names
        autoComplete: false,
        // Set true to call "onchange" event only if the selected values is present
        // in the loaded options.
        forceSelection: false,
        // REMOTE MODE: Set the url of the remote endpoint where load data
        remote: null,
        // LOCAL MODE: the array of objects to be loaded into dropdwon
        options: [],
        // The selected option key
        selected: null,
        // ONLY REMOTE: The delay ms between an API call and another
        delay: 1000,
        // ONLY REMOTE: The name of the query parameter for textual search
        queryField: 'q',
        // The field of data object to be assigned as "key"
        valueField: 'id',
        // The field of data object to be assigned as "text"
        displayField: 'name'
    }

    /**
     * Component constructor
     * @param objcet props  The component default props to be applied
     */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Init filter objcet
        let filters =  {}
        // Set page
        filters['page'] = 1;
        // Set limit
        filters['limit'] = Config.get(
            'DEFAULT_REMOTE_COMBOBOX_PAGINATION_LIMIT', 100
        )
        // Set textual search
        filters[this.props.queryField] = undefined;
        // Set default state
        this.state = {
            visible: true,
            value: '',
            filters: filters,
            options: !this.props.remote ? this.props.options : [],
            lastRequest: 0
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                        COMPONENT EVENT HANDLERS                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Handle onChange event of FabricUI Combobox component
     * @param  Event        ev      The change event
     * @param  Component    option  The option component element selected
     * @param  integer      index   The index of sleected option
     * @param  string       value   The value string of the input
     * @return void
     */
    _onChange = (ev, option, index, value) => {
        // Log in debug
        Logger.write(
            'RemoteCombobox@_onChange -> start.', 0, [option, index, value]
        );

        // If force selection was enabled, check if option is null
        // (no list item was selected) AND a value is passed
        // (input string that doesn't match a dorpown value)
        if (this.props.forceSelection && (!option && value)) return false;

        // If user has clickd in the "load more button", don't run onChange
        if (option && option.type === "load-more") return false;

        // Call parent method "onSelectionChange"
        this.props.onSelectionChange(ev, option, index, value);
    }

    /**
     * Handle onItemClick event of FabricUI Combobox component
     * @param  Event ev     [description]
     * @param  Component option [description]
     * @param  integer index  [description]
     * @return {[type]}        [description]
     */
    _onItemClick = (ev, option, index) => {
        // Log in debug
        Logger.write(
            'RemoteCombobox@_onItemClick -> start.', 0, [option, index, index]
        );

        // If is not load more option, exit
        if (option.type !== "load-more") return;

        // Paginate next page.
        // COMBAK: try to stop event workflow with ev.preventDefault(); or
        //  ev.stopPropagation(); but it didn't work...
        this._paginate();
    }

    /**
     * Handle onChange event of FabricUI Combobox component
     * @return void
     */
    _onMenuOpen = () => {
        // Log in debug
        Logger.write('RemoteCombobox@_onMenuOpen -> start.', 0);

        // If is a remote combobox AND have no element loaded...
        if (this.props.remote && this.state.options.length === 0)
            // Load some elmeents
            this._makeSearchAsync(this.state.filters);
    }

    /**
     * Handle ever user typing chars inside combobox input values
     * @param  Component    option  The option component element selected
     * @param  integer      index   The index of sleected option
     * @param  string       value   The value string of the input
     * @return void
     */
    _onPendingValueChanged = (option, index, value) => {
        // Log in debug
        Logger.write(
            'RemoteCombobox@_onPendingValueChanged -> start.', 0, [option, index, value]
        );
        // Open the dorpown menu
        this._openMenu();

        // If last request time is greater current time - the delay, exit
        if (this.state.lastRequest >= (Date.now() - this.props.delay)) return;

        // If user has not value in input box BUT has an option selected, exit
        // NOTE: if you load the normal list without filter AFTER a text search
        // this could not start...
        // IDEA: Try to check the last text filter and the current if are equals
        if (!value) return;

        // If has an value imput but is not almost 3 char, exit
        if (value && value.length < 2) return;

        // Update last request time
        this.setState({ lastRequest: Date.now() });
        // Get all filters
        let filters = {...this.state.filters};
        // Set filter value
        filters[this.props.queryField] = value;
        // Set filters page
        filters['page'] = 1;
        // Download next page
        this._makeSearchAsync(filters);
    }

    /**
     * Open combobox dropdown menu
     * @return void
     */
    _openMenu = () => {
        // Get the combobox
        let button = document.getElementById('profile_remote_combobox')
            // And query the button inside
            .querySelector('button');

        // HACK: make a click on the button to open the dropdown menu
        if (!button.classList.contains('is-checked')) button.click();
    }

    /*
    |-------------------------------------------------------------------------|
    |                                   API CALLS                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Call API and get the posts to display
     * @param  object filters   Object key:value of filters to be applied
     * @return Promise          Axios promise
     */
    _makeSearchAsync = (filters) => {
        // Log in debug
        Logger.write('RemoteCombobox@_makeSearchAsync -> start.', 0, filters);

        // If filters are null
        if (!filters) filters = {};

        // Load tokens from API
        return Api.callAPI("GET", this.props.remote, null, null, filters).then(
            // Manage response
            response => {
                // Log in debug
                Logger.write(
                    'RemoteCombobox@_makeSearchAsync -> success.', 0, response
                );
                // Get data from response
                let data = response.data;
                // Delete all previous results
                let options = [];

                // If the previous search has the same filter that the new one
                if (this.state.filters[this.props.queryField] === filters[
                        this.props.queryField
                    ]) {
                    // Load previous options exept load more button
                    options = this.state.options.filter(
                        e => e.option_type !== 'load-more'
                    );
                }

                // If total numer on server is not 0 and
                if (data.count !== 0
                    // Is greater then downloaded + element already loaded
                    && data.count > (data.results.length + options.length)
                ) {
                    // Init element
                    let el = {};
                    // Set the value id
                    el[this.props.valueField] = -1;
                    // Set the display field
                    el[this.props.displayField] = "Caricamento...";
                    // Set the tupe
                    el['option_type'] = "load-more";
                    // Push load more element
                    data.results.push(el);
                }

                // Store data inside store
                this.setState({
                    // If we are storing 1st page
                    options: filters.page === 1
                        // Take results
                        ? data.results
                        // Otherwise merge new results with old results
                        : [...options, ...data.results],
                    total: data.count,
                    filters: filters
                });

                // Return array of results
                return data.results;
            }
        ).catch(
            // Manage error
            err => {
                // Log in error
                Logger.write('RemoteCombobox@_makeSearchAsync -> error.', 3, err);
                // Set empty array
                this.setState({ filters: filters });
                // Return an empty array
                return [];
            }
        // Finally in each case
        ).finally(() => {
            // Set is isLoading to false
            this.setState({ isLoading: false });
        });
    }

    /**
     * Load more records with current filters
     * @return void
     */
    _paginate = () =>{
        // Log in debug
        Logger.write(
            'RemoteCombobox@_paginate -> start.', 0, this.state.filters
        );
        // Take all filters
        let filters = this.state.filters;
        // Update page
        filters.page = filters.page + 1;
        // Download next page
        this._makeSearchAsync(filters);
    }

    /*
    |-------------------------------------------------------------------------|
    |                        RENDER COMPONENTS METHOD                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Custom render method for each elements in dropdown
     * @param  object item  Object with key, type and text props.
     * @return JSX          JSX element rendered
     */
    _onRenderOption = (item) => {
        // If is load more
        if (item.type === "load-more") {
            // Render an load more lement
            return (<span className="remote-combobox-option load-more">
                Carica altro ...
            </span>);
        }

        // Return normal element
        return (<span>{item.text}</span>);
    }

    /**
     * Render component
     * @return {} []
     */
    render() {
        return (
            <ComboBox
                ref={this.props.id}
                id={this.props.id}
                key={this.props.id}
                label={this.props.label}
                className={this.props.class}
                allowFreeform={this.props.allowFreeform}
                autoComplete={this.props.autoComplete ? "on" : "off"}
                options={this.state.options.map(e => {
                    return {
                        key: e[this.props.valueField],
                        text: (e[this.props.displayField] || "Profilo #" + e.id),
                        type: e.option_type
                    }
                })}
                onMenuOpen={this._onMenuOpen}
                onRenderOption={this._onRenderOption}
                selectedKey={this.props.selected}
                onChange={this._onChange}
                onItemClick={this._onItemClick}
                onPendingValueChanged={this._onPendingValueChanged}
            />
        );
    }
}

// Export default component to be accessible in other components
export default RemoteCombobox;

// const el = document.body
// ReactDOM.render(<RemoteCombobox name='John' />, el)
