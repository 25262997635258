import React from 'react';
import moment from 'moment';

import '../styles/Footer.css';

class Footer extends React.Component {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'Footer'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    // constructor (props) {
    //     // Make property available in this module
    //     super(props);
    // }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <div className="footer-container">
                {'© ' + moment().year() + ' - Infojuice Willie '}
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default Footer;

// const el = document.body
// ReactDOM.render(<Footer name='John' />, el)
