import React from 'react';
import {Nav} from 'office-ui-fabric-react/lib/Nav'
import '../styles/Sidebar.css'

class Sidebar extends React.Component {
    // The menu tree
    tree = {
        name: 'Sidebar',
        groups: [{
            links: [{
                name: 'Home',
                icon: 'Home',
                url: '/'
            },
            {
                name: 'Tasks',
                icon: 'TaskManager',
                url: '/tasks'
            },
            {
                name: 'Info',
                icon: 'Info',
                url: '/info',
                // onClick: () => { Logger.write("prviamo");},
            },
            {
                name: 'Full Screen',
                icon: 'FullScreen',
                onClick: this.props.toggleFullscreen
            }]
        }],
        expanded: 'expanded',
        collapsed: 'collapsed',
    }
    //
    // /**
    // * Component constructor
    // * @param {Object} props [Component props]
    // */
    // constructor (props) {
    //     // Make property available in this module
    //     super(props);
    // }
    //


    /**
     * Render component
     * @return {} []
     */
    render() {
      return (
          <div className='SidebarMenu'>
              <Nav groups={this.tree.groups}
                  expandedStateText={this.tree.expanded}
                  collapsedStateText={this.tree.collapsed}
                  />
          </div>
      );
    }
}



// Export default component to be accessible in other components
export default Sidebar;

// const el = document.body
// ReactDOM.render(<Sidebar name='John' />, el)
