import React from 'react';
// Import FabricUI components
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';

class ErrorModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'ErrorModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            errors: []
        }
    }

    /**
     * Toggle dialog
     * @param  Object word      The data object to be sent
     * @return void
     */
    toggle = (errors) => {
        this.setState({
            hideDialog: !this.state.hideDialog,
            errors: errors
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Close dialog
        this.setState({
            hideDialog: !this.state.hideDialog,
            errors: []
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Compose the body message for the modal.
     * @return string   The string error message to be displayed
     */
    _composeErrorDetail = () => {
        // If erorrs is null or empty
        if (!this.state.errors || this.state.errors.length === 0)
            // Retun a default error message
            return "Si è verificato un errore, ricarica la pagina e riprova";

        // Compose the error message
        return "La chiamata non è andata a buon fine, "
        + (this.state.errors.length === 1
            ? "si è verificato il seguente errore: "
            : "si sono verificati i seguenti errori: ")
        + this.state.errors.join(" | ");
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Errore',
                    subText: this._composeErrorDetail(),
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <DialogFooter><DefaultButton onClick={this.close} text="Chiudi" /></DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default ErrorModal;
