import Logger from './Logger';
import Utility from './Utility';
import WordUtil from './WordUtil';
// import TranscriptionHelper from './TranscriptionHelper';

const ActionUtil = {
    // Init data
    data: {},

    /**
     * Compose the data to be returnet to Dialog.
     * This method is used by TranscriptionBox after word click and
     * after an action button clicked
     * @param  object       action      The action object with action informations
     * @param  HtmlElement  target      The targe of event (a click or else..)
     * @param  object       task        The task object
     * @param  array        selected    The task object
     * @return object                   The data object for Dialog
     */
    composeData: function(action, target, task, selected) {
        // // Log in debug
        Logger.write('ActionUtil@composeData -> start. ', 0, [action, target, selected]);
        // If action is null, return false
        if (!action) return false;

        // If mode is "add_tag"
        if (action.mode === "add_tag") {
            // Nothing to do special here
            return this._getAddKeywordData(action, target);
        }

        // If mode is "create_clip"
        else if (action.mode === "create_clip") {
            // Nothing to do special here
            return this._getCreateClipData(action, target);
        }

        // If mode is "set_clip_start"
        else if (action.mode === "set_clip_start") {
            // Nothing to do special here
            return this._getClipStartData(action, target, task, selected);
        }

        // If mode is "set_clip_end"
        else if (action.mode === "set_clip_end") {
            // Nothing to do special here
            return this._getClipEndData(action, target, task, selected);
        }

        // If mode is "merge_clips"
        else if (action.mode === "merge_clips") {
            // Nothing to do special here
            return this._getMergeClipsData(action, target);
        }

        // If mode is "delete_clip"
        else if (action.mode === "delete_clip") {
            // Nothing to do special here
            return this._getDeleteClipData(action, target, selected);
        }

        // If mode is "set_thumbnail"
        else if (action.mode === "set_thumbnail") {
            // Nothing to do special here
            return this._getThumbnailData(action, target, task);
        }

        // If mode is "end_production"
        else if (action.mode === "end_production") {
            // Nothing to do special here
            return this._getEndProductionData(task);
        }

        // If mode is "confirm_clips_and_end_production"
        else if (action.mode === "confirm_clips_and_end_production") {
            // Nothing to do special here
            return this._getConfirmClipsAndEndProductionData(task);
        }

        // If mode is "confirm_clips_and_create_task"
        else if (action.mode === "confirm_clips_and_create_task") {
            // Nothing to do special here
            return this._getConfirmClipsAndCreateTaskData(task);
        }

        // If mode is "reload_task"
        else if (action.mode === "reload_task") {
            // Nothing to do special here
            return this._getResetTaskData(task);
        }

        // If mode is "edit_clip"
        else if (action.mode === "edit_clip") {
            // Nothing to do special here
            return this._getSelectClipData(action, target, task);
        }

        // Return don't do nothing
        return false;
    },

    /**
     * Compose data for add_tag action
     * @param  object       action  The action object with all action infos
     * @param  HtmlElement  target  The targe element
     * @return object               The object for dialog
     */
    _getAddKeywordData: function(action, target) {
        // Compose a word object and return
        return WordUtil.composeWordObject(target);
    },

    /**
     * Compose data for create_clip action
     * @param  object       action  The action object with all action infos
     * @param  HtmlElement  target  The targe element
     * @return object               The object for dialog
     */
    _getCreateClipData: function(action, target) {
        // Init start and end vars
        let start, end;
        // Create the data object
        let data = WordUtil.composeWordObject(target);
        // Load temp data, id there is
        let tempData = this._loadTempData('first_create_clip');

        // Check if is selection
        if (action.selection) {
            // If there is a selection
            start = action.selection
                // Get the first element
                ? WordUtil.composeWordObject(action.selection[0])
                : WordUtil.composeWordObject(Utility.getSelectedElement(true));
            // Get end data
            end = action.selection
                ? WordUtil.composeWordObject(action.selection[1])
                : WordUtil.composeWordObject(Utility.getSelectedElement(false));
        }

        // Otherwise if is already stored the first create clip
        else if (!tempData) {
            // Store temp data
            this._saveTempData('first_create_clip', data);
            // Set word as action-selected
            target.classList.add('action-selected');
            // Return false to select another word
            return false;
        }

        // Otherwise if has already the first temp data
        else if (tempData) {
            // Get start word by check datetime: if stored data is greater
            start = tempData.datetime > data.datetime
                // Start will be the second selected
                ? data
                // Otherwise start is the first selected
                : tempData;
            // Get start word by check datetime: if stored data is greater
            end = tempData.time > data.time
                // End will be the first selected
                ? tempData.datetime
                // Otherwise end is the second selected
                : data;

            // Delete start data from state
            this._deleteTempData('first_create_clip');
            // Get the word action-selected
            let selected = target.parentElement.querySelector('.action-selected');
            // Remove class action selected
            if (selected) selected.classList.remove('action-selected');
        }

        // Compose new data object
        return {
            'start': start,
            'end': end
        }

        // COMBAK: this is the data structure for modal ClipMetadataModal:
        // you will have to use this to create the modal in the future.
        // data = {
        //     // Array of clips
        //     'clips': [{
        //         'id': null,
        //         'date_start': start.datetime,
        //         'date_end': end.datetime
        //     }],
        //     // Is a new user created clip or returned from server?
        //     'new': true
        // }
    },

    /**
     * Compose data for set_clip_start action
     * @param  object       action      The action object with all action infos
     * @param  HtmlElement  target      The targe element
     * @param  object       task        The task object
     * @param  array        selected    The task object
     * @return object                   The object for dialog
     */
    _getClipStartData: function(action, target, task, selected) {
        // Compose a word object and return
        let data = WordUtil.composeWordObject(target);
        // init clip
        let clip = null;

        // If there is selected elements and are clips
        if (selected && selected.length > 0 && selected[0].type === "clip") {
            // Get selected clips
            let selectedClips = WordUtil.getClips(selected[0].target);
            // Get all IDS avialable
            let ids = selectedClips.map(clip => {return clip.raw.id});
            // TODO: take only first ID
            let id = ids[0];
            // Return clips
            let found = data.clips.filter(clip => {return clip.raw.id === id});
            // Set the clip
            clip = (found && found.length > 0) ? found[0] : selectedClips[0];
        }
        //  else {
        //     // Get the clip start nearest of word
        //     clip = TranscriptionHelper.findNearestClip(
        //         data.datetime, task.clips(), 'start'
        //     );
        // }

        // Check if word clicked is NOT valid for current action
        if (!clip) {
            // Write a log
            Logger.write(
                "Task@_getClipStartData -> word not valid for selected action",
                0,
                [data, action]
            );
            // Return false
            return false;
        }

        // Return data
        return {
            'word': data,
            'clip': clip
        };
    },

    /**
     * Compose data for set_clip_end action
     * @param  object       action      The action object with all action infos
     * @param  HtmlElement  target      The targe element
     * @param  object       task        The task object
     * @param  array        selected    The task object
     * @return object                   The object for dialog
     */
    _getClipEndData: function(action, target, task, selected) {
        // Compose a word object and return
        let data = WordUtil.composeWordObject(target);
        // init clip
        let clip = null;

        // If there is selected elements and are clips
        if (selected && selected.length > 0 && selected[0].type === "clip") {
            // Get selected clips
            let selectedClips = WordUtil.getClips(selected[0].target);
            // Get all IDS avialable
            let ids = selectedClips.map(clip => {return clip.raw.id});
            // NOTE: FOR NOW Take only first ID
            let id = ids[0];
            // Return clips
            let found = data.clips.filter(clip => {return clip.raw.id === id});
            // Set the clip
            clip = (found && found.length > 0) ? found[0] : selectedClips[0];
        }
        // else {
        //     // Get the clip end nearest of the word
        //     clip = TranscriptionHelper.findNearestClip(
        //         data.datetime, task.clips(), 'end'
        //     );
        // }

        // Check if word clicked is NOT valid for current action
        if (!clip) {
            // Write a log
            Logger.write(
                "Task@_getClipStartData -> word not valid for selected action",
                2,
                [data, action]
            );
            // Return false
            return false;
        }

        // Return data
        return {
            'word': data,
            'clip': clip
        };
    },

    /**
     * Compose data for merge_clips action
     * @param  object       action  The action object with all action infos
     * @param  HtmlElement  target  The targe element
     * @return object               The object for dialog
     */
    _getMergeClipsData: function(action, target) {
        // Compose a word object and return
        let data = WordUtil.composeWordObject(target);
        // Load start data from state
        let tempData = this._loadTempData('first_merge_clips');

        // If clips are null or empty
        if (!data.clips || data.clips.length === 0) {
            // Write a log
            Logger.write(
                "TranscriptionBox@handleWordClick -> word not valid for selected action",
                0,
                [data, action]
            );
            // Return false
            return false;
        }

        // If has selected only the first word, the start...
        if (!tempData) {
            // Store start data
            this._saveTempData('first_merge_clips', data);
            // Set word as action-selected
            target.classList.add('action-selected');
            // Return false to select another word
            return false;
        }

        // Delete start data from state
        this._deleteTempData('first_merge_clips');
        // Get the word action-selected
        let selected = target.parentElement.querySelector('.action-selected');
        // Remove class action selected
        if (selected) selected.classList.remove('action-selected');
        // Return the data
        return {
            'words': [tempData, data]
        };
    },

    /**
     * Compose data for delete_clip action
     * @param  object       action  The action object with all action infos
     * @param  HtmlElement  target  The targe element
     * @param  array        selected    The task object
     * @return object               The object for dialog
     */
    _getDeleteClipData: function(action, target, selected) {
        // If has a selection, use it, otherwise use the target passed
        target = selected && selected[0] && selected[0].target
            ? selected[0].target : target;
        // Compose a word object and return
        let data = WordUtil.composeWordObject(target);

        // If clips are null or empty
        if (!data.clips || data.clips.length === 0) {
            // Write a warning
            Logger.write(
                "ActionUtil@_getDeleteClipData -> no clips in word selected",
                2,
                [data, action]
            );
            // Return false
            return false;
        }

        // Return the data
        return data;
    },

    /**
     * Compose data for set_thumbnail action
     * @param  object       action  The action object with all action infos
     * @param  HtmlElement  target  The targe element
     * @param  object       task    The task object
     * @return object               The object for dialog
     */
    _getThumbnailData: function(action, target, task) {
        // Compose a word object and return
        let data = WordUtil.composeWordObject(target);
        // Return data
        return data;
    },

    /**
     * Compose data for end_production action
     * @param  object   task    The task object
     * @return object           The data to be sent to dialog
     */
    _getEndProductionData: function(task) {
        // Get task as data
        return { 'task': task };
    },

    /**
     * Compose data for end_production action
     * @param  object   task    The task object
     * @return object           The data to be sent to dialog
     */
    _getConfirmClipsAndEndProductionData: function(task) {
        // Get all clips to be deleted
        let clips = task.clips().filter(c => !c.isOK());
        // Remove all clips from task object
        clips.forEach(c => task.removeClip(c.id()));
        // Return the object with the clips to be deleted and end production
        return {
            'delete_clip': {'clips': clips},
            'end_production': {'task': task}
        }
    },

    /**
     * Compose data for confirm clips and create task
     * @param  Task task    Task object model
     * @return object       The id of the task and the task
     */
    _getConfirmClipsAndCreateTaskData: function(task) {
        // Return task as data
        return { 'task': task };
    },


    /**
     * Compose data for reload_task action
     * @param  object   task    The task object
     * @return object           The data to be sent to dialog
     */
    _getResetTaskData: function(task) {
        // Return task
        return task;
    },

    /**
     * Compose data for edit_clip action
     * @param  object       action  The action object with all action infos
     * @param  HtmlElement  target  The targe element
     * @param  Task         task    The task object
     * @return object               The object for dialog
     */
    _getSelectClipData: function(action, target, task) {
        // Compose a word object and return
        let data =  WordUtil.composeWordObject(target);
        // Get all ids
        let ids = data.clips.map(clip => clip.raw.id);
        // Return the edit_clip data
        return {
            // Was data.clips, but with this code you get the clips object
            'clips': task.clips().filter(clip => ids.includes(clip.id())),
            'new': false
        };
    },

    /*
    |-------------------------------------------------------------------------|
    |                               OTHER METHODS                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Load a temp variable from a local var
     * @param  string name  The name of variable to load
     * @return multy        The variable
     */
    _loadTempData: function(name) {
        // Return the element
        return this.data[name];
    },

    /**
     * Save a temp variable into a local var
     * @param  string name  The name of variable to load
     * @return multy        The variable
     */
    _saveTempData: function(name, value) {
        // If is empty value
        if (Utility.isEmpty(value)) {
            // Delete the var
            return this._deleteTempData(name);
        }

        // Set the item inside data var
        return this.data[name] = value;
    },

    /**
     * Remove a temp variable from local var
     * @param  string name  The name of variable to load
     * @return void
     */
    _deleteTempData: function(name) {
        // Delete the object passed
        return delete this.data[name];
    },
}

export default ActionUtil;
