import React from 'react';
import moment from 'moment';
// Import FabricUI compoents
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
// Import classes
import Api from '../../classes/Api.js';
import Logger from '../../classes/Logger.js';

class SetClipEndModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'SetClipEndModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            data: undefined,
            loading: false
        }
    }

    /**
     * Toggle dialog
     * @param  Object data      The data object to be sent
     * @return void
     */
    toggle = (data) => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: data
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: {},
            loading: false
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Save the data passed to the modal
     * @return void
     */
    save = () => {
        // Log into debug
        Logger.write("SetClipEndModal@save -> start:", 0);
        // Save the error
        this.setState({loading: true});
        // Save the scope inside me var
        let me = this;
        // Prepare data for API
        let data = this._prepareData(this.state.data);
        // Start axios call to add a new keyword
        let axios = Api.editClip(data.id, data, true);

        // Manage response
        axios.then((response) => {
            // Log in debug
            Logger.write('SetClipEndModal@success -> success.', 0, response);
            // take care of original data
            let originalData = this.state.data.clip.raw;
            // Take care of new value
            let newValue = response.data.end_datetime;
            // Override start datetime with new value
            originalData['end_datetime'] = newValue;
            // Override data with original data
            response.data = originalData;
            // Handle action response
            me.props.handleActionResponse(response);
            // Close the current modal
            me.close();
        })
        // Manage error
        .catch((error) => {
            // Log in error
            Logger.write('SetClipEndModal@error -> got an error.', 3, error);
            // Close the current modal
            me.close();
        });
    }

    /**
     * Prepare data to be sent to API
     * @param  object data  Data from input
     * @return object       Data to be sent
     */
    _prepareData = (data) => {
        // Log into debug
        Logger.write("SetClipEndModal@_prepareData", 0, data);
        // If has override datetime use it
        const end = data.word.overrideDatetime
            // otherwise use datetime
            || data.word.datetime;

        // If has override date time value
        if (data.word.overrideDatetime)
            // Log the value
            Logger.write(
                "SetClipEndModal@_prepareData: use the datetime overriden. ",
                0,
                [data.word.datetime, data.word.overrideDatetime]
            );

        // Amend start time and return raw data for API
        data.clip.raw['end_datetime'] = moment(end).format(
           "YYYY-MM-DD HH:mm:ss.SSS"
        );
        // Make a mutable copy to preserve original data
        let copy = {...data.clip.raw};
        // Deelte attachments to avoid error #76274
        delete copy.attachments;
        // Return clip
        return copy;
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Imposta Fine Clip',
                    subText: 'I dati verrano inviati al server.',
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <DialogFooter>
                <PrimaryButton onClick={this.save} text={ this.state.loading ? "Caricamento..." : "Salva"} disabled={this.state.loading} />
                <DefaultButton onClick={this.close} text="Annulla" />
            </DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default SetClipEndModal;
