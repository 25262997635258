import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// Import classes and components
import Word from './Word.js';
import Config from '../classes/Config.js';
import Utility from '../classes/Utility.js';
import WordUtil from '../classes/WordUtil.js';
import ActionUtil from '../classes/ActionUtil.js';

import Metadata from '../classes/models/Metadata.js';

// Import style
import '../styles/TranscriptionBox.css'

class TranscriptionBox extends React.Component {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'TranscriptionBox',
        transcription: [],
    }

    // Var to manage single and double click on transcription
    static actionDoubleStart = false;

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            value: '',
            transcription: this.props.transcription,
            lastPostion: 0,
            inDownloading: false,
            downloaded: false,
            hasError: false,
            rendered: [],
            chunkInProgress: false
        }
    }

    /**
     * React lifecircle method. Called after render for each component update
     * @return {[type]} [description]
     */
    componentDidUpdate = () => {
        // // Call redux to disable render
        // this.props.disableRender();
        //
        // // If we receive the props that task is fully downloaded
        // if (this.props.taskLoaded
        //     // AND we don't have to render transcription beacuse is empty
        //     && (this.props.transcription.length === 0
        //         // OR there are rendered transcription's elements
        //         || this.refs['transcription-box'].childElementCount > 0
        //     )
        // ) {
        //     // We finish rendered, you can remove Shimmer loading and show all
        //     this.props.finishRender();
        // }


        // // If we have transcription and we haven't compose it yet
        // if (hasWords && !hasElements) {
        //     // Call redux to enable render
        //     this.props.enableRender();
        // }
        // // Otherwise disable render to avoid re-render
        // else {
        //     // Call redux to disable render
        //     this.props.disableRender();
        // }
        // If we receive the props that task is fully downloaded AND
        // this.props.taskLoaded &&





        // If the rendered elements are equals to the transcription elements
        // it meas that you can disable it, all the chunks are done
        // if (this.state.rendered.length === this.props.transcription.length) {
        // if (!this.state.chunkInProgress) {
            // Call redux to disable render
        // }
    }

    /**
     * Catch render error
     * @param  string error     The string error in console
     * @param  object info      Full stack error
     * @return void
     */
    componentDidCatch(error, info) {
        // Display fallback UI. COMBAK: inform server of that error.
        this.setState({ hasError: true });
    }

    /*
    |-------------------------------------------------------------------------|
    |                               ACTION HANDLE                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Handle double click action on transcription box
     * @param  Event ev     Double click event
     * @return void
     */
    handleWordDoubleClick = (ev) => {
        // Set true to sign that we are fired a double click event, prevent
        // single click
        this.actionDoubleStart = true;
        // Get the target of click
        let target =  ev.currentTarget;
        // Create unixtimestamp
        let wordTime = WordUtil.getTime(target, 'moment');
        // Crteate moment
        let taskTime = this.props.task.start('moment');
        // Get seconds
        let seconds = wordTime.diff(taskTime, 'seconds');
        // Get duration task
        let duration = Utility.getTaskDuration(this.props.task);
        // Create progress object
        let progress = Utility.createProgressFromSeconds(seconds, duration);
        // Remove the selection automatically made by double click on text
        Utility.removeSelection();
        // Call parent to manage progress
        this.props.handleProgressChange('transcriptionbox', progress);
    }

    /**
     * Handle click action on transcription box word
     * @param  Event ev     Double click event
     * @return void
     */
    handleWordClick = (event) => {
        // Make event persist for sleep promise callback
        event.persist();
        // Assign event to const ev
        const ev = event;

        // Sleep for 250ms and then..
        Utility.sleep(250).then(() => {

            // If user start a double click
            if (this.actionDoubleStart) {
                // Seret action and return
                this.actionDoubleStart = false;
                // Return because a double click was fired
                return false;
            }

            // HACK: after entered inside this method you HAVE TO override
            // current target with target
            ev.currentTarget = ev.target;
            // Get current action
            let action = this.props.action;
            // Get the target of click
            let target = ev.currentTarget;
            // Init word object
            let data = false;

            // If action is select words, you have to dismiss that action
            if (action
                && (action.mode === "set_clip_start" || action.mode === "set_clip_end")
                && (!this.props.selected || this.props.selected.length === 0)
            ) {
                action.mode = "create_clip"
            }

            // If action is select words, you have to dismiss that action
            if (action && action.mode === "select_words") {
                // Manage wordclick
                this.props.handleActionChange(undefined);
                // Do nothing, exist
                return data;
            }

            // If there is a common click AND is not a clip
            if (Utility.noActionRunning(action) && !WordUtil.isClip(target)
                // AND selected is null or empty
                && Utility.isEmpty(this.props.selected)) {
                // Manage wordclick
                this.props.handleActionChange(undefined);
                // Do nothing, exist
                return data;
            }
            // Otherwise if there are no action AND is a clip
            else if (Utility.noActionRunning(action) && WordUtil.isClip(target)) {
                // User has just created a new "edit_clip" OR "select_clip" action!
                action = {
                    "context": "transcriptionbox",
                    "mode": (this.props.isSpecialPressed
                        ? 'select_clip' : 'edit_clip'),
                    "event": ev
                }

                // TODO: maybe a handleActionChange?
                this.props.handleActionChange(action);
                // Exit with null data
                return data;
            }

            // If action is select_clip && there is not special pressed
            if (!action
                || (action.mode === "select_clip" && !this.props.isSpecialPressed)
            ) {
                // Manage wordclick
                this.props.handleActionChange(undefined);
                // Do nothing, exist
                return data;
            }

            // // If action is select_clip && there is not special pressed
            // if (action.mode === "create_clip") {
            //     // Manage wordclick
            //     this.props.handleActionChange(undefined);
            //     // Do nothing, exist
            //     return data;
            // }

            // Compose the data to be processed from dialog
            data = ActionUtil.composeData(
                action, target, this.props.task, this.props.selected
            );

            // If data is defined: we are ready to continue
            if (data) {
                // Toggle modal to add keyword
                this.props.handleActionDialog(action, data);
            }
        })
    }

    /**
     * Manage word selection
     * @param  Event ev     JS Event onMouseUp
     * @return void
     */
    handleWordMouseUp = (ev) => {
        // If is not a selection
        if (!Utility.isSelectionEnabled(["transcription-box"])) return false;

        // If an action is already in progress (like clip_selection) and
        // is selected
        if (this.props.action && this.props.selected) {
            // Prev defualt
            ev.preventDefault();
            // User has just created a new "select_words" action
            let action = {...this.props.action};
            // override event
            action['event'] = ev;
            // Return the action
            return action;
        }

        // User has just created a new "select_words" action
        let action = {
            "context": "transcriptionbox",
            "mode": "select_words",
            "event": ev
        }

        // Call handle action change
        this.props.handleActionChange(action);
    }

    /*
    |-------------------------------------------------------------------------|
    |                                 ELEMENT UPDATE                          |
    |-------------------------------------------------------------------------|
    */

    /**
     * Update trascription progress based on player progress
     * @param  object progress   Progress object with percentage and seconds
     * @return boolean           True if updated
     */
    updateTranscriptionProgress = (progress) => {
        // Init elements
        let elements;
        // Get seconds time to be highlighted
        let time = parseInt(progress.playedSeconds);

        // Get elements to be highlighted
        let prediction = document.querySelectorAll(
            "[data-seconds='" + time + "']"
        );

        // Check if there is almost 1 word to be selected, If not, don't remove
        // old highlighted! Because actions will be run in error
        if (prediction.length > 0) {
            // Load all elements to be removed
            elements = document.querySelectorAll(
                '.transcription-word.highlighted'
            );

            // Iterate elements to be removed
            elements.forEach((el, i) => {
                // Remove highlighted class
                el.classList.remove('highlighted');
            });
        }

        // Get elements to be highlighted
        elements = document.querySelectorAll("[data-seconds='" + time + "']");

        // Iterate elements
        elements.forEach((el, i) => {
            // Add highlighted calss
            el.classList.add('highlighted');
        });

        // Store last time
        this.setState({
            lastPostion: time
        });

        // Return true
        return true;
    }

    /*
    |-------------------------------------------------------------------------|
    |                                   RENDER                                |
    |-------------------------------------------------------------------------|
    */

    shouldComponentUpdate = (nextProps, nextState) => {
        // if render is not ON, exit
        if (!this.props.reconciliation.render) return false;

        // Check if is a shallow copy
        return Utility.shallowCompare(this, nextProps, nextState);
    }

    /**
     * HOTFIX method: get the correct keyword with correct datetime
     * @return array    Array of metadata
     */
    _fixKeywordWithWrongTime = () => {
        // init fixedKeywords
        let fixedKeywords = [];
        // Load datetime format like API
        let format = Config.get(
            'DEFAULT_API_DATE_FORMAT', 'YYYY-MM-DDTHH:mm:ss.SSS'
        );

        // If transcription or metas are null or empty
        if (!this.props.transcription
            || this.props.transcription.length === 0
            || !this.props.task.metadata()
            || this.props.task.metadata().length === 0
        ) return fixedKeywords;

        // Set transcription var
        let words = this.props.transcription;
        // Get all keywords
        let keywords = this.props.task.metadata().filter(
            meta => meta.type() === "keyword"
        );

        let start = null;
        let end = null;
        let text = null;
        let difference = null;
        let wordTime = null;
        let copyKeyword = null;
        let meta = null;

        // Iterate each words
        for (var i = 0; i < keywords.length; i++) {
            // Define start range
            start = keywords[i].start() - 60000;
            // Define end range
            end = keywords[i].start() + 60000;
            // Define word text
            text = keywords[i].value().toLowerCase();
            // Init difference as Infinity, greatest number possible
            difference = Infinity;
            // Init word start
            wordTime = null;
            // Init the new keyword copied from the one returned from server
            copyKeyword = null;

            // Iterate each transcription words
            for (var j = 0; j < words.length; j++) {
                // Format moment start in unixtimestamp
                wordTime = (parseFloat(words[j]['time']) * 1000)
                    + this.props.task.start();

                // If word is < start OR > end, it means that is not inside
                // valid range, go next.
                if (wordTime < start || wordTime > end) continue;

                // If word is not the same of keyword word, go next.
                if (words[j]['data'].toLowerCase() !== text) continue;

                // Calculate current word time difference
                let current = this._calculateAbsoluteTimeDifferences(
                    wordTime, start, end
                );

                // If calculated differences is greaten then old candidate one
                if (current > difference) continue;

                // Make a mutable copy of server raw data
                copyKeyword = {...keywords[i].raw};
                // Edit start datetime with new value
                copyKeyword['start_datetime'] =  moment(wordTime)
                    .format(format);
                // Edit end datetime with new value
                copyKeyword['end_datetime'] =  moment(wordTime).format(format);
                // Init a Metadata Object with that data
                meta = Metadata.init(copyKeyword);
            }

            // if metadata was found
            if (meta) {
                // Push fixedKeywords
                fixedKeywords.push(meta);
            }
        }

        // Return the fixedKeywords
        return fixedKeywords;
    }

    _calculateAbsoluteTimeDifferences = (time, start, end) => {
        // Take the absolute value of differences from start
        let startDiff = Math.abs(time - start);
        // Take the absolute value of differences from end
        let endDiff = Math.abs(time - end);
        // Return the minor value
        return startDiff < endDiff ? startDiff : endDiff;
    }

    /**
     * This is an async worker that elaborate chunk of words and save it into
     * state.
     * @param  array keywords   The computed keywords
     * @return void
     */
    heavyLoadRender = (keywords) => {
        // Detach from the main thread to run async
        setTimeout(() => {
            // Init word to be rendered
            let tWord = undefined;
            this.setState({chunkInProgress:true});

            // If we don't have rendered all elements
            if (this.state.rendered.length < this.props.transcription.length) {
                // Init array of elements
                let chunks = [];
                // Save the index of current element
                let start = this.state.rendered.length;
                // If we dont have 500 elements
                let chunkSize = this.props.transcription.length - start < 500
                    // Take it, otherwise take only 500
                    ? this.props.transcription.length - start : 500;

                // Create 500 words
                for (var i = 0; i < chunkSize; i++) {
                    // Take the word to be renderd
                    tWord = this.props.transcription[start+i];
                    // Push a word component
                    chunks.push(<Word key={tWord.id}
                        task={this.props.task}
                        raw={{...this.props.task.raw}}
                        selected={this.props.selected}
                        transcriptionWord={tWord}
                        handleDoubleClick={this.handleWordDoubleClick}
                        handleClick={this.handleWordClick}
                        handleMouseUp={this.handleWordMouseUp}
                        keywords={keywords} />);
                }

                // Store rendered elements with the old one
                this.setState({
                    rendered: this.state.rendered.concat(chunks),
                    chunkInProgress: this.state.rendered.length !== this.props.transcription.length
                });
                // Call self to render next chunks
                this.heavyLoadRender(keywords);
            }
        })
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // If has an error,
        if (this.state.hasError) {
            // Retunr empty timeline
            return (<div
                id="transcription-box"
                ref="transcription-box"
                className='task-transcription-box-container border-secondary'>
            </div>);
        }

        // HOTFIX: Get the fixed keywords with correct date-time
        let keywords = this._fixKeywordWithWrongTime();

        // NOTE: this is async way to load.
        // Separate render from main thread to improve UX.
        // It has a problem: with current workflow, render are permitted only
        // 1 time in the component for each update: it means that only it call
        // the "heavyLoadRender", then the "componentDidUpdate" *BEFORE* heavy
        // loada render's end (because is async!) and from that point, render
        // are no long permitted. It will not change the UI with the computed
        // changes. You can not use states variable because you are in render
        // (but you can in async method, beacuse is not part of main thread).
        // this.heavyLoadRender(keywords);
        // // style={{ display: this.state.rendered.length === this.props.transcription.length ? 'block' : 'none' }}
        // return (<div
        //     id="transcription-box"
        //     ref="transcription-box"
        //     className='task-transcription-box-container border-secondary'
        //
        //     >
        //     {this.state.rendered}
        // </div>);

        return (
            <div
                id="transcription-box"
                ref="transcription-box"
                className='task-transcription-box-container border-secondary'>
                {this.props.transcription.map(
                    transcriptionWord => <Word key={transcriptionWord.id}
                        task={this.props.task}
                        raw={{...this.props.task.raw}}
                        selected={this.props.selected}
                        transcriptionWord={transcriptionWord}
                        handleDoubleClick={this.handleWordDoubleClick}
                        handleClick={this.handleWordClick}
                        handleMouseUp={this.handleWordMouseUp}
                        keywords={keywords} />
                )}
            </div>
        );
    }
}

// Take the redux store
const mapStateToProps = state => {
    // From redux store, take only reconciliationControl action
    const reconciliation = state.reconciliationControl
    // Return the data to the props
    return { reconciliation };
};

// Export default component to be accessible in other components
// export default TranscriptionBox;
// Export default component to be accessible in other components
export default connect(
  mapStateToProps,
  null, null, { forwardRef: true }
)(TranscriptionBox);
