import React from 'react';
// Import FabricUI compoents
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
// Import classes
import Api from '../../classes/Api.js';
import Logger from '../../classes/Logger.js';

class ConfirmClipsAndEndProductionModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'ConfirmClipsAndEndProductionModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            data: undefined,
            newData: undefined,
            loading: false
        }
    }

    /**
     * Toggle dialog
     * @param  Object data      The data object to be sent
     * @return void
     */
    toggle = (data) => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: data
        });

    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: {},
            loading: false
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Save the data passed to the modal
     * @return void
     */
    save = () => {
        // Init task
        this._apiCalls();
    }

    /**
     * Make all API call: first delete unfinished clips, then confirm task
     * @return Promise    The promises
     */
    _apiCalls = async () => {
        // Save the error
        this.setState({loading: true});
        // Get all ids
        const ids = this._getClipsToDelete().map(c => c.id());
        // Init errors var
        let errors = [];

        // Iterate each ids
        for (var i = 0; i < ids.length; i++) {
            // Do async operation and if gotta error, push inside error bag
            await this._deleteClip(ids[i]).catch(err => errors.push(err));
        }

        // Start axios call to end production
        await this._endProduction(this.props.task.id()).catch(err => errors.push(err));
        // Save the error
        this.setState({loading: false});
        // Handle action response
        this.props.handleActionResponse({data: errors});
        // Close the current modal
        this.close();
        // Exit
        return false;
    }

    _deleteClip = (id) => {
        // Delete the clips
        return Api.deleteClip(id).then(response => {
            // Log in debug
            Logger.write('ConfirmClipsAndEndProductionModal@_deleteClip -> first deletion success.', 0);
            // Return task
            return null;
        // Then delete the second clips
        }).catch((error) => {
            // Log in error
            Logger.write('ConfirmClipsAndEndProductionModal@_deleteClip -> got an error.', 3, error);
            // Return task
            return "Error while deleting clip ID " + id + ".";
        });
    }

    _endProduction = (id) => {
        // Delete the clips
        return Api.endProduction(id).then(response => {
            // Log in debug
            Logger.write('ConfirmClipsAndEndProductionModal@_endProduction -> first deletion success.', 0);
            // Return task
            return null;
        // Then delete the second clips
        }).catch((error) => {
            // Log in error
            Logger.write('ConfirmClipsAndEndProductionModal@_endProduction -> got an error.', 3, error);
            // Return task
            return "Error while terminating task ID " + id + ".";
        });
    }

    /**
     * Return the clips to be deleted
     * @return array    The array with all clips to be deleted
     */
    _getClipsToDelete = () => {
        // If no data is provided
        if (!this.state.data
            || !this.state.data.delete_clip
            || !this.state.data.delete_clip.clips
        // Return empty array
        ) return [];

        // Return the number of clips to be deleted
        return this.state.data.delete_clip.clips;
    }

    /**
     * Return the clips to be saved
     * @return array    The array with all clips to be saved
     */
    _getClipsToSave = () => {
        // If no data is provided
        if (!this.state.data
            || !this.state.data.end_production
            || !this.state.data.end_production.task
        // Return empty array
        ) return [];

        // Return the number of clips to be saved
        return this.state.data.end_production.task.clips();
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Conferma Clips e Termina Lavorazione',
                    subText: 'Premendo su "Conferma" verranno cancellate '
                    + this._getClipsToDelete().length
                    + " clips incomplete e verrà terminata la task con "
                    + this._getClipsToSave().length
                    + " clips complete.",
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <DialogFooter>
                <PrimaryButton onClick={this.save} text={ this.state.loading ? "Caricamento..." : "Conferma"} disabled={this.state.loading} />
                <DefaultButton onClick={this.close} text="Cancel" />
            </DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default ConfirmClipsAndEndProductionModal;
