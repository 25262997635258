import React from 'react';
// import moment from 'moment';
// Import FabricUI components
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
// Import other views
import SelectClipModalView from './views/SelectClipModalView.js';
import InformationClipModalView from './views/InformationClipModalView.js';
import ProfileClipModalView from './views/ProfileClipModalView.js';
import PlayerClipModalView from './views/PlayerClipModalView.js';
// Import classes
import Clip from '../../classes/models/Clip.js';
import Api from '../../classes/Api.js';
import Logger from '../../classes/Logger.js';

class ClipMetadataModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'ClipMetadataModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Write in log
        Logger.write('ClipMetadataModal@constructor -> START!', 0, this.state);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            clips: [],
            selectionClips: [],
            selectedClip: undefined,
            editingProfiles: [],
            currentView: 'select',
            loading: false
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                               GETTER / SETTER                           |
    |-------------------------------------------------------------------------|
    */

    /**
     * Get current view
     * @return string   the current view
     */
    _getCurrentView = () => {
        // Return the value inside state
        return this.state.currentView;
    }

    /**
     * Set the current view
     * @param string view   The view name to be set
     */
    _setCurrentView = (view) => {
        // If the view is not defined, set select as default
        view = view || 'select';
        // Set state with the view name
        this.setState({ currentView: view });
    }

    /*
    |-------------------------------------------------------------------------|
    |                           HANDLE ACTION METHODS                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Toggle the modal
     * @param  object data  The clips object from word clicked
     * @return void
     */
    toggle = (data) => {
        // Log into debug
        Logger.write("ClipMetadataModal@toggle -> start", 0, data);
        // Init data
        let selected = undefined;

        // If data or clips are null
        if (!data || !data.clips) {
            // Init data
            data = {};
            // Init clips
            data['clips'] = [];
        }

        // Iterate each clips and create a the clip object array
        let clips = data.clips.map(clip => Clip.init(clip.raw));

        // Check if clip is new
        if (this._isNew(data)) {
            // Log into debug
            Logger.write("TODO: START LOADING CLIP INFOS FROM API!!!", 1);
        }

        // If clips are only 1
        if (clips.length === 1) {
            // Store selected clip
            selected = clips[0];
            // If we have only 1 clips, set as selected clip
            this.setState({
                selectedClip: selected,
                // TODO: Pay attention to doubles
                editingProfiles: [...this.state.editingProfiles, selected.profiles()]
            });
        }
        // Otherwise
        else {
            // Set the clips to be selected
            this.setState({ selectionClips: clips });
        }

        // Update data clip with formed clips
        data['clips'] = clips;

        // Set in store...
        this.setState({
            // The dialog visibility, if have to show dialog
            hideDialog: !this.state.hideDialog,
            // The data passed to toggle (the word with clips)
            data: data,
        });

        // If clips are only 1
        if (clips.length === 1) {
            // Set view to information mode
            this._setCurrentView('information');
        }

        // Change the title of dialog
        this._composeDialogTitle(selected);
        // Log into debug
        Logger.write("ClipMetadataModal@toggle -> end", 0);
    }

    /**
     * Action from clip player or timeline.
     * Fired when one component seek player, this is the callback of the player
     * @param  string   context     The context where the action is fired
     * @param  Event    ev          The original event
     * @return void
     */
    onClipProgressChange = (context, ev) => {
        // Set progress
        this.setState({progress: ev});
        // Call update timeline progress in timeline component
        let profileClipModalView = this.refs.profile_clip_modal_view;

        // If we don't have the view, it mease that we are in other one.
        if (!profileClipModalView) return false;

        //Update current timeline progress
        profileClipModalView.updateTimelineProgress(ev);
    }

    /**
     * Handle click on "Previous" button
     * @param  object data      Data passed between dialog views
     * @return void
     */
    onPreviousButtonClicked = (data) => {
        // Log into debug
        Logger.write("ClipMetadataModal@onPreviousButtonClicked -> start", 0, data);

        // If current view is select
        if (this._getCurrentView() === "select" ) {
            // Close the view
            this._close();
        }
        // Else if view is information && selectionClips are gt 0
        else if (
            this._getCurrentView() === "information"
            && this.state.selectionClips.length > 0
        ) {
            // Go to select view
            this._setCurrentView('select');
        }
        // Else if view is information && selectionClips are 0
        else if (
            this._getCurrentView() === "information"
            && this.state.selectionClips.length === 0
        ) {
            // Close the view
            this._close();
        }
        // Else if view is profile
        else if (this._getCurrentView() === "profile") {
            // Set view to information
            this._setCurrentView('information');
            // Set previousProfiles data
            this.setState({ previousProfiles: data });
        }
        // Otherwise
        else {
            // Print an error
            Logger.write(
                'ClipMetadataModal@onPreviousButtonClicked -> Whooops, '
                + 'somethings went wrong with metadata modal: '
                + 'you are in a unknow state!',
                2,
                this._getCurrentView()
            );
        }

        // Log into debug
        Logger.write("ClipMetadataModal@onPreviousButtonClicked -> end", 0);
    }

    /**
     * Handle click on "Next" button
     * @param  object data      Data passed between dialog views
     * @return void
     */
    onNextButtonClicked = (data) => {
        // Write in log
        Logger.write('ClipMetadataModal@onNextButtonClicked -> start', 0, data);

        // If current view is select
        if (this._getCurrentView() === "select" ) {
            // Save selected clip
            this.setState({ selectedClip: data })
            // Set view into information mode
            this._setCurrentView('information');
        }
        // Else if view is information && has almost 1 profile to be edited
        else if (this._getCurrentView() === "information" && data) {
            // Store editing profiles
            this.setState({
                editingProfiles: data['profiles'],
                // Update data with clip info (new metadata was downloaded)
                selectedClip: data['clip']
            })
            // Save current view
            this._setCurrentView('profile');
        }
        // Else if view is information && profiles to be edited are 0
        else if (
            this._getCurrentView() === "information" && data.length === 0
        ) {
            // Save infromation
            this._save();
        }
        // Else if view is profile
        else if (this._getCurrentView() === "profile") {
            // Save information
            this._save();
        }
        // Otherwise print an error
        else {
            Logger.write(
                'ClipMetadataModal@onNextButtonClicked -> Whooops, '
                + 'somethings went wrong with metadata modal: '
                + 'you are in a unknow state!',
                1,
                this._getCurrentView()
            );
        }

        // Write in log
        Logger.write('ClipMetadataModal@onNextButtonClicked -> end', 0, data);
    }

    /*
    |-------------------------------------------------------------------------|
    |                                OTHER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Close the dialog and reset all vars
     * @return void
     */
    _close = () => {
        // Set in state all default values
        this.setState({
            hideDialog: true,
            isDraggable: false,
            clips: [],
            selectionClips: [],
            selectedClip: undefined,
            editingProfiles: [],
            currentView: 'select',
            loading: false,
            previousProfiles: undefined
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Save the modal data and close the dialog
     * @return void
     */
    _save = () => {
        // Log into debug
        Logger.write("ClipMetadataModal@_save -> start:", 0);
        // Save the error
        this.setState({loading: true});
        // Save the scope inside me var
        let me = this;
        // Create data from API
        let data = this._composeAPIData();
        // Start axios call to add a new keyword
        let axios = Api.editClip(data.id, data, true);

        // Manage response
        axios.then((response) => {
            // Log in debug
            Logger.write('CreateClipModal@save -> axios success.', 0, [response, this.state.selectedClip.raw]);
            // Create a copy of profiles from response. It contains only
            // updated metadata. The rest of informations are missing!
            let responseProfiles = [...response.data.profiles];

            // Iterate all old stored profiles with all infromations
            let profiles = this.state.editingProfiles.map(profile => {
                // Take same profile updated
                let responseProfile = responseProfiles.find(
                    p => p.id === profile.id
                );
                // Override profile metadata with updated metadata
                profile.metadata = [...responseProfile.metadata];
                // Return profile
                return profile;
            });

            // Override response data profiles with new ones
            response.data.profiles = [...profiles];
            // Handle action response
            me.props.handleActionResponse({data: response.data});
            // Close the current modal
            me._close();
        })
        // Manage error
        .catch((error) => {
            // Log in error
            Logger.write('CreateClipModal@save -> got an error.', 3, error);
            // Close the current modal
            me._close();
        });
    }

    /**
     * Compose api data object
     * @return object   The clip object with the profiles edited
     */
    _composeAPIData = () => {
        // Create selected clip
        let data = {...this.state.selectedClip.raw};
        // Deelte attachments to avoid error #76274
        delete data.attachments;
        // Override profiles by return only url and metadata
        data.profiles = this.state.editingProfiles.map(profile => {
            return {'url': profile.url, 'metadata': profile.metadata}
        });
        // Return data
        return data;
    }

    /**
     * Check if current clip passed is new or not
     * @param  object  data     The clip data to be checked
     * @return boolean          True if is new
     */
    _isNew = (data) => {
        // Return if data is defined
        return data
            // Check if has "new" props and return that value, false otherwise
            ? (data.new ? data.new : false)
            // If data is not defined check if state has data object
            : this.state.data
                // If is in state, check if has "new" props and return true
                ? (this.state.data.new ? this.state.data.new : false)
                // Return false otherwise
                : false
    }

    /**
     * Return a dialog title for current dialog state and view
     * @return string   The title of the dialog to be show
     */
    _composeDialogTitle = () => {
        // Create title var
        let title = "";

        // If is in select view
        if (this._getCurrentView() === "select") {
            // Set title fixed to "select clips"
            title = "Seleziona una clips";
        }
        // Otherwise..
        else if (
            // If is in information view
            this._getCurrentView() === "information"
            // And has selected clip
            && this.state.selectedClip
        ) {
            // Get start moment
            let clip = this.state.selectedClip

            // Compose title
            title = "Clip " + this.props.task.sourceRepr() + " "
                + clip.start('date')
                + " ora [" + clip.start('time') + "] - ["
                + clip.end('time') + "]";
        }
        // Otherwise if is in profile view
        else if (this._getCurrentView() === "profile") {
            // Set title fixed to "edit profiles"
            return "Modifica i profili";
        }

        // Return the title
        return  title;
    }

    /**
     * Compose the dialog body based on the current state
     * @return JSX      The JSX element to be insert inside dialog body
     */
    _composeDialogBody = () => {
        // Take the selection clips from state
        const { selectionClips } = this.state;
        // Take the selected clip from state
        const { selectedClip } = this.state;
        // Take the editing prioiles from state
        const editingProfiles = this.state.editingProfiles;

        // If we are in select view with almost 1 clip to be selected
        if (this._getCurrentView() === "select" && selectionClips.length > 0) {
            // Return the SelectClipModalView component
            return <SelectClipModalView
                key="select_clip_modal_view"
                ref="select_clip_modal_view"
                hidden={false}
                clips={selectionClips}
                task={this.props.task}
                handlePreviousViewChange={this.onPreviousButtonClicked}
                handleNextViewChange={this.onNextButtonClicked}
                />
        }

        // Otherwise if we are in information view
        else if (this._getCurrentView() === "information") {
            // If previous profiles are defined
            const profiles = this.state.previousProfiles
                // Create a copy of previousProfiles, otherwise set undefined
                ? [...this.state.previousProfiles] : undefined;
            // Return InformationClipModalView component
            return <div>
                <div className="clip-metadata-view-shared">
                    <PlayerClipModalView
                        task={this.props.task}
                        taskLoaded={this.props.taskLoaded}
                        selectedClip={this.state.selectedClip}
                        handleProgressChange={this.onClipProgressChange}
                        />
                </div>
                <div className="clip-metadata-view-shared">
                    <InformationClipModalView
                        key="information_clip_modal_view"
                        ref="information_clip_modal_view"
                        hidden={false}
                        clip={{...selectedClip}}
                        task={this.props.task}
                        previousProfiles={profiles}
                        handlePreviousViewChange={this.onPreviousButtonClicked}
                        handleNextViewChange={this.onNextButtonClicked}
                        />
                </div>
            </div>
        }

        // Otherwise if we are in profile view
        else if (this._getCurrentView() === "profile") {
            // Return ProfileClipModalView component
            return <div>
                <div className="clip-metadata-view-shared">
                    <PlayerClipModalView
                        task={this.props.task}
                        taskLoaded={this.props.taskLoaded}
                        selectedClip={this.state.selectedClip}
                        handleProgressChange={this.onClipProgressChange}
                        />
                </div>
                <div className="clip-metadata-view-shared">
                    <ProfileClipModalView
                        key="profile_clip_modal_view"
                        ref="profile_clip_modal_view"
                        hidden={false}
                        loading={this.state.loading}
                        profiles={editingProfiles}
                        clip={{...selectedClip}}
                        transcription={this.props.transcription}
                        task={this.props.task}
                        handlePreviousViewChange={this.onPreviousButtonClicked}
                        handleNextViewChange={this.onNextButtonClicked}
                        />
                </div>
            </div>
        }

        // Return null as default
        return null;
    }


    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this._close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this._composeDialogTitle(),
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,

                    styles: {
                        // main: { maxWidth: 1280, minWidth: 640 }
                        main: [{
                            selectors: {
                                // Apply at root
                                [""]: {  // eslint-disable-line no-useless-computed-key
                                    // minWidth: '520px',
                                    maxWidth: '1280px',
                                    margin: '20px',
                                    textAlign: 'center'
                                }
                            }
                        }]
                    },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
                >
                {this._composeDialogBody()}
            </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default ClipMetadataModal;
