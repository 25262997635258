import React from 'react';
import moment from 'moment';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';

import Api from '../../classes/Api.js';
import Config from '../../classes/Config.js';
import Logger from '../../classes/Logger.js';

class SetThumbnailModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'SetThumbnailModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            word: undefined,
            loading: false
        }
    }

    /**
     * Toggle dialog
     * @param  Object word      The data object to be sent
     * @return void
     */
    toggle = (word) => {
        this.setState({
            hideDialog: !this.state.hideDialog,
            word: word
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        this.setState({
            hideDialog: !this.state.hideDialog,
            word: {},
            loading: false
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Save the data passed to the modal
     * @return void
     */
    save = () => {
        // Log into debug
        Logger.write("SetThumbnailModal@save -> start:", 0);
        // Save the error
        this.setState({loading: true});
        // Save the scope inside me var
        let me = this;
        // Compose data to be sent to API
        let data = this._composeAPIData();
        // Start axios call to add a new keyword
        let axios = Api.manageMetadata(data);

        // Manage response
        axios.then((response) => {
            // Log in debug
            Logger.write('Api@setThumbnail -> success.', 0);
            // Handle action response
            me.props.handleActionResponse(response);
            // Close the current modal
            me.close();
        })
        // Manage error
        .catch((error) => {
            // Log in error
            Logger.write('Api@setThumbnail -> got an error.', 3, error);
            // Close the current modal
            me.close();
        });
    }

    /**
     * Compose data object for API request
     * @return object   The object with all information needed for API request
     */
    _composeAPIData = () => {
        // Load default date format
        let format = Config.get(
            'DEFAULT_API_DATE_FORMAT', 'YYYY-MM-DDTHH:mm:ss.SSS'
        );
        // Compose data to be sent
        return {
            task: this.props.taskId,
            metadata:[{
                type: 'thumbnail',
                start_datetime: moment(this.state.word.datetime).format(format),
                end_datetime: moment(this.state.word.datetime).format(format),
                value: 'Thumbnail',
                // confirmed: false,
                // deleted: false
            }]
        };
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Imposta immagine di anteprima',
                    subText: 'I dati verrano inviati al server.',
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <DialogFooter>
                <PrimaryButton onClick={this.save} text={ this.state.loading ? "Caricamento..." : "Salva"} disabled={this.state.loading} />
                <DefaultButton onClick={this.close} text="Annulla" />
            </DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default SetThumbnailModal;
