import { combineReducers } from "redux";
import viewChange from "./viewChange";
import login from "./login";
import logout from "./logout";
import callsUpdate from "./callsUpdate";
import reconciliationControl from "./reconciliationControl";

// NOTE: use combineReducers to merge different reducers
export default combineReducers(
    {viewChange, login, logout, callsUpdate, reconciliationControl}
);
