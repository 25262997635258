import React from 'react';
// Import FabricUI compoents
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
// Import classes
import Api from '../../classes/Api.js';
import Logger from '../../classes/Logger.js';

class ConfirmClipsAndCreateTaskModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'ConfirmClipsAndCreateTaskModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            data: undefined,
            newData: undefined,
            loading: false
        }
    }

    /**
     * Toggle dialog
     * @param  Object data      The data object to be sent
     * @return void
     */
    toggle = (data) => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: data
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: {},
            loading: false
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Get the server error message from the ajax "error" object returned
     * @param  Error err    The ajax error catched from server response
     * @return string       The error message
     */
    _getErrorMessage = (err) => {
        // Get error object
        err = err && err.response;
        // Get status code
        let code = err.status;
        // If has error data, and has the error data msg from API
        let message = err.data && err.data.message
            // Take it
            ? err.data.message
            // Otherwise, take the status text
            : err.statusText;
        // Compose error message: if has a message
        return message
            // Print with status code
            ? code + ": " + message
            // Otherwise return generic error
            : "An error occurred";
    }

    /**
     * Save the data passed to the modal
     * @return void
     */
    save = () => {
        // Log into debug
        Logger.write("ConfirmClipsAndCreateTaskModal@save -> start:", 0);
        // Save the error
        this.setState({loading: true});
        // Save the scope inside me var
        let me = this;
        // Start axios call to add a new keyword
        let axios = Api.confirmClipsAndCreateTask(this.state.data.task.id());
        // Manage response
        axios.then((response) => {
            // Log in debug
            Logger.write('ConfirmClipsAndCreateTaskModal@save -> axios success.', 0, response);
            // Handle action response
            me.props.handleActionResponse(response);
            // Close the current modal
            me.close();
        })
        // Manage error
        .catch((error) => {
            // Log in error
            Logger.write('ConfirmClipsAndCreateTaskModal@save -> got an error.', 3, error);
            // Take the error message from server response
            let message = this._getErrorMessage(error);
            // Handle action response
            me.props.handleActionResponse({data: [message]});
            // Close the current modal
            me.close();
        });
    }

    /**
     * Count clips OK or not OK
     * @param  boolean ok   If true count clips OK, otherwise count not ok clips
     * @return {[type]}    [description]
     */
    _getClipsCount = (ok) => {
        // If no task or no data was provided, return 0
        if (!this.state.data || !this.state.data.task) return 0;
        // If have to count the clip OK
        return ok
            // Count only clips that are ok
            ? this.state.data.task.clips().filter(c => c.isOK()).length
            // Otherwise count clips not ok
            : this.state.data.task.clips().filter(c => !c.isOK()).length;
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // Count how many clips are OK
        const okClipsCount = this._getClipsCount(true);
        // Count how many clips are not OK
        const notOkClipsCount = this._getClipsCount(false);

        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Conferma Clips e Crea Nuova Task',
                    subText: 'Premendo su "Conferma" '
                        + (okClipsCount > 1
                            ? "verranno confermate " + okClipsCount + " clips"
                            : "verrà confermata " + okClipsCount + " clip")
                        + " e verrà creata una nuova task con "
                        + (notOkClipsCount > 1
                            ? notOkClipsCount + " clips incomplete."
                            : notOkClipsCount + " clip incompleta."),
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <DialogFooter>
                <PrimaryButton onClick={this.save} text={ this.state.loading ? "Caricamento..." : "Conferma"} disabled={this.state.loading} />
                <DefaultButton onClick={this.close} text="Cancel" />
            </DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default ConfirmClipsAndCreateTaskModal;
