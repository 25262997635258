import React from 'react';
import moment from 'moment';
// Import FabricUI compoents
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
// Import classes
import Api from '../../classes/Api.js';
import Config from '../../classes/Config.js';
import Logger from '../../classes/Logger.js';
import Utility from '../../classes/Utility.js';

class CreateClipModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'CreateClipModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            isChecked: true,
            data: undefined,
            loading: false
        }
    }

    /**
     * Toggle dialog
     * @param  Object data      The data object to be sent
     * @return void
     */
    toggle = (data) => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: data
        });
    }

    _onCheckboxChange = () => {
        // Update state
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: {},
            loading: false
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Save the data passed to the modal
     * @return void
     */
    save = () => {
        // Log into debug
        Logger.write("CreateClipModal@save -> start:", 0);
        // Save the error
        this.setState({loading: true});
        // Save the scope inside me var
        let me = this;
        // Create data
        let data = this._composeAPIData();
        // Start axios call to add a new keyword
        let axios = Api.createClip(data);

        // Manage response
        axios.then((response) => {
            // Log in debug
            Logger.write('CreateClipModal@save -> axios success.', 0, response);
            // Handle action response
            me.props.handleActionResponse(response);
            // Close the current modal
            me.close();
        })
        // Manage error
        .catch((error) => {
            // Log in error
            Logger.write('CreateClipModal@save -> got an error.', 3, error);
            // Close the current modal
            me.close();
        });
    }

    /**
     * Compose data object for API request
     * @return object   The object with all information needed for API request
     */
    _composeAPIData = () => {
        // load user from local storage
        let user = Utility.loadPersistData('user');
        // Load default date format
        let format = Config.get(
            'DEFAULT_API_DATE_FORMAT', 'YYYY-MM-DDTHH:mm:ss.SSS'
        );
        // If has override datetime use it
        const start = this.state.data.start.overrideDatetime
            // otherwise use datetime
            || this.state.data.start.datetime;
        // If has override datetime use it
        const end = this.state.data.end.overrideDatetime
            // otherwise use datetime
            || this.state.data.end.datetime;

        // Return data object for API
        return {
            // Set task url
            task: Config.get('URL_API_TASKS') + this.props.task.id() + "/",
            // Specify if backend should recalculate clip metadata or not
            recalculate_clip: this.state.isChecked,
            // Set source url
            source: this.props.task.sourceUrl(),
            // Set user url
            creation_user: user.url,
            // Set metadata as empty array
            metadata: [],
            // Set status (Status 1 is "created")
            status: Config.get('URL_API_STATUS') + 1 + "/",
            // Set the clip type "U", that means user created
            clip_type: Config.get('DEFAULT_CLIP_TYPE'),
            // Set start time
            start_datetime: moment(start).format(format),
            // Set end time
            end_datetime: moment(end).format(format),
        };
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Crea Clip',
                    subText: 'I dati verrano inviati al server.',
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <div >
                <Checkbox label="Ricalcola i metadati della clip" onChange={this._onCheckboxChange} checked={this.state.isChecked} />
            </div>
            <DialogFooter>
                <PrimaryButton onClick={this.save} text={ this.state.loading ? "Caricamento..." : "Salva"} disabled={this.state.loading} />
                <DefaultButton onClick={this.close} text="Annulla" />
            </DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default CreateClipModal;
