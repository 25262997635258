import React from 'react';

import Utility from '../classes/Utility.js';
import MetadataUtil from '../classes/MetadataUtil.js';
import WordUtil from '../classes/WordUtil.js';

import '../styles/ContextMenu.css'

class ContextMenu extends React.Component {

    state = {
        visible: false,
        scope: null,
        mouseEvent: null
    };

    componentDidMount() {
        document.addEventListener('contextmenu', this._handleContextMenu);
        document.addEventListener('click', this._handleClick);
        document.addEventListener('scroll', this._handleScroll);
    };

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
        document.removeEventListener('click', this._handleClick);
        document.removeEventListener('scroll', this._handleScroll);
    }

    /**
     * Action on context menu open
     * @param  Event event  The event
     * @return void
     */
    _handleContextMenu = (event) => {
        // Get targe click
        let target = event.target;
        // Check if have to open context menu returning scope
        let scope = this._shouldOpenContextMenu(target);

        // If should not open context menu
        if (!scope) {
            // Return false
            return false;
        }

        // Prevent default right click
        event.preventDefault();
        // Init range var
        let range = null;

        // Check if is selected
        if (Utility.isSelectionEnabled(this.props.scopes)) {
            // If is a selection
            let selection = typeof window.getSelection != "undefined"
                // Get selection
                ? window.getSelection()
                // Otherwise, use a second way to check the selection
                : (typeof document.selection != "undefined"
                    // If found take the range, otherwise null
                    ? document.selection.createRange() : null);
            // Get start element
            let start = Utility.getSelectedElement(true, selection);
            // Get end element
            let end = Utility.getSelectedElement(false, selection);
            // Create range with HTML elements
            range = [start, end];
        }

        // Set visible to true and save current scope
        this.setState({
            visible: true,
            scope: scope,
            mouseEvent: event,
            selection: range
        });

        // Show/Hide context menu options
        this._composeContextMenuOptions(target, scope);

        // Assign props
        const clickX = event.clientX;
        const clickY = event.clientY;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const rootW = this.root.offsetWidth;
        const rootH = this.root.offsetHeight;
        // Calculate position
        const right = (screenW - clickX) > rootW;
        const left = !right;
        const top = (screenH - clickY) > rootH;
        const bottom = !top;

        if (right) {
            this.root.style.left = `${clickX + 5}px`;
        }

        if (left) {
            this.root.style.left = `${clickX - rootW - 5}px`;
        }

        if (top) {
            this.root.style.top = `${clickY + 5}px`;
        }

        if (bottom) {
            this.root.style.top = `${clickY - rootH - 5}px`;
        }
    }

    _shouldOpenContextMenu = (target) => {
        // Init found var
        let found = false;

        // If target is not defined
        if (!target || !this.props.scopes) {
            // Exit with false
            return false;
        }

        // Iterate each scopes
        for (var i = 0; i < this.props.scopes.length; i++) {
            // Get the scope element from which should attach event
            let node = document.getElementById(this.props.scopes[i]);

            // If node was not found
            if (!node) {
                // Continue next
                continue;
            }

            // If parent don't contain target event node
            if (node && node.contains(target)) {
                // Set found true
                found = this.props.scopes[i];
            }
        }

        // Return if found
        return found;
    }

    _composeContextMenuOptions = (target, scope) => {
        // Get all options and iterate them
        this.root.querySelectorAll('.contextMenu--option').forEach((item, i) => {
            // set display none
            item.style.display = "none";
        });

        // Init is on metadata var
        let isOnMetadata = false;
        // Init is on clip var
        let isOnClip = false;
        // Check if is complete or not
        let isUncomplete = this.props.runningCalls.length > 0
            || Utility.disableButtonForCurrentSelection(
                'end_production', this.props.selected
            ) || !this.props.taskCompleted

        // Check if is has ALL clips not OK
        let totalUncomplete = this.props.runningCalls.length > 0
            || Utility.disableButtonForCurrentSelection(
                'end_production', this.props.selected
            ) || !this.props.task.hasAlmostOneClipOK()

        // if is in timeline
        if (scope === "timeline") {
            // Check if is on a metadata
            isOnMetadata = MetadataUtil.isMetadata(target);
            // Check if is on clip
            isOnClip = MetadataUtil.isClip(target);
        }
        // Otherwise we are in transcription box
        else {
            // Check if is on metadata
            isOnMetadata = WordUtil.isMetadata(target);
            // Check if is on clip
            isOnClip = WordUtil.isClip(target);
        }

        // Get all element that have to be visible as default
        this.root.querySelectorAll('.visible-default').forEach((item, i) => {
            // Show it
            item.style.display = "block";
        });

        // If we are on a metadata
        if (isOnMetadata) {
            // Get only visible metadata options and iterate them
            this.root.querySelectorAll('.visible-metadata').forEach((item, i) => {
                // Show it
                item.style.display = "block";
            });
        }

        // If we are on a clip
        if (isOnClip) {
            // Get only visible CLIP options and iterate them
            this.root.querySelectorAll('.visible-clip').forEach((item, i) => {
                // Show it
                item.style.display = "block";
            });
        }

        // Get all option that will be disabled for selection
        this.root.querySelectorAll('.disabled-for-selection').forEach(
            (item, i) => {
                // If is selected
                if (this.props.selected) {
                    // Hide it by adding disabled option
                    item.classList.add('contextMenu--option__disabled');
                }
                // Otherwise
                else {
                    // Show it bt by remove class
                    item.classList.remove('contextMenu--option__disabled');
                }
            }
        );

        // Get all option disabled for uncomplete
        this.root.querySelectorAll('.disabled-for-uncomplete').forEach(
            (item, i) => {
                // If is uncomplete
                if (isUncomplete) {
                    // Hide it by adding disabled option
                    item.classList.add('contextMenu--option__disabled');
                }
                // Otherwise
                else {
                    // Show it bt by remove class
                    item.classList.remove('contextMenu--option__disabled');
                }
            }
        );

        // Get all option disabled for uncomplete
        this.root.querySelectorAll('.disabled-for-total-uncomplete').forEach(
            (item, i) => {
                // If is uncomplete
                if (totalUncomplete) {
                    // Hide it by adding disabled option
                    item.classList.add('contextMenu--option__disabled');
                }
                // Otherwise
                else {
                    // Show it bt by remove class
                    item.classList.remove('contextMenu--option__disabled');
                }
            }
        );
    }

    _handleClick = (event) => {
        const { visible } = this.state;
        const wasOutside = !(event.target.contains === this.root);


        // If the target of click is not an option
        if (!event.target.classList.contains('contextMenu--option')
            && wasOutside && visible) {
            // Set state
            this.setState({ visible: false});
            // Return false
            return  false;
        }

        // Get dataset
        let data = event.target.dataset;
        // get mode
        let mode = data ? data.action : null;

        // if mode is not defined
        if (!mode) {
            // Return false
            return false;
        }

        // Set state
        this.setState({ visible: false});
        // Create context var if defined scope
        let context = this.state.scope
            // Attach scope to context menu string
            ? 'contextmenu-' + this.state.scope
            // Otherwise return only contextmenu
            : 'contextmenu';
        // Create an action object
        let action = {
            'context': context,
            'mode': mode,
            // NOTE: Save the event of right click instead of click on context
            // menu option
            'event': this.state.mouseEvent,
            'selection': this.state.selection ? this.state.selection : null
        }

        // Show event
        this.props.handleActionChange(action);
    }

    _handleScroll = () => {
        const { visible } = this.state;
        if (visible) this.setState({ visible: false, });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return Utility.shallowCompare(this, nextProps, nextState);
    }

    render() {
        const { visible } = this.state;

        return(visible || null) &&
            <div ref={ref => {this.root = ref}} className="contextMenu">
                <div data-action="add_tag" className="contextMenu--option disabled-for-selection visible-default">Aggiungi tag</div>
                <div data-action="create_clip" className="contextMenu--option disabled-for-selection visible-default">Crea clip</div>
                <div data-action="set_clip_start" className="contextMenu--option visible-default">Imposta inizio clip</div>
                <div data-action="set_clip_end" className="contextMenu--option visible-default">Imposta fine clip</div>
                <div data-action="merge_clips" className="contextMenu--option disabled-for-selection visible-default">Unisci clip</div>
                <div data-action="delete_clip" className="contextMenu--option visible-clip">Elimina clip</div>
                <div data-action="set_thumbnail" className="contextMenu--option disabled-for-selection visible-default">Imposta thumbnail</div>
                <div data-action="end_production" className="contextMenu--option disabled-for-selection disabled-for-uncomplete visible-default">Fine lavorazione</div>
                <div data-action="confirm_clips_and_end_production" className="contextMenu--option disabled-for-selection disabled-for-total-uncomplete visible-default">Conferma clips e termina</div>
                <div data-action="confirm_clips_and_create_task" className="contextMenu--option disabled-for-selection disabled-for-total-uncomplete visible-default">Conferma clips e crea task</div>
                <div data-action="reload_task" className="contextMenu--option disabled-for-selection visible-default">Reset Task</div>
            </div>
    };
}
export default ContextMenu;
