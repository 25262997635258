import React from 'react';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Checkbox, Separator, TooltipHost} from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react/lib/Shimmer';

// Classes and components
import Api from '../classes/Api.js';
import Config from '../classes/Config.js';
import Logger from '../classes/Logger.js';
import Utility from '../classes/Utility.js';
// Model classes
import TaskModel from '../classes/models/Task.js';

class TaskRowAccordion extends React.Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        name: 'TaskRowAccordion'
    }

    /**
     * Component constructor
     * @param {Object} props [Component props]
     */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            elements: undefined,
            downloaded: false
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                           COMPONENT ACTIONS                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Event action on checkbox clicked
     * @param  Event ev     Click event on checkbox
     * @return void
     */
    _onClipCheckboxChange = (ev) => {
        // Get the event target
        let target = ev.target;
        // Get the container
        let container = target.closest(".detail-task-clip-checkbox-container");
        // Set checked or not
        return target.checked
            ? container.classList.add("checked")
            : container.classList.remove("checked");
    }

    /**
     * Action click on Confirm & queue button
     * @param  integer taskId   The task ID
     * @return void
     */
    _onConfirmAndWorkClicked = (taskId) => {
        // Get the unselected clips ids
        let unselectedIds = this._getUnselectedClipIds(taskId);
        // Compose data objcet
        const data = {
            ids: unselectedIds,
            taskId: taskId
        }
        // Call parent method
        this.props.handleAccordionAction('reject_clips_and_work', data);
    }

    /**
     * Action click on Confirm & queue button
     * @param  integer taskId   The task ID
     * @return void
     */
    _onConfirmAndQueueClicked = (taskId) => {
        // Get the unselected clips ids
        let unselectedIds = this._getUnselectedClipIds(taskId);
        // Compose data objcet
        const data = {
            ids: unselectedIds,
            taskId: taskId
        }
        // Call parent method
        this.props.handleAccordionAction('reject_clips_and_queue', data);
    }

    /*
    |-------------------------------------------------------------------------|
    |                              AJAX CALL REQUESTS                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Compose accordion body. First with shimmer, then with data
     * @param  integer taskId   The task ID to create accordion
     * @return JSX              The accordion with data
     */
    download = (taskId) => {
        // If not id was specified, exit, not to load
        if (!taskId || this.state.downloaded) return false;

        // Download data
        this._syncDownloaded(taskId).then(task => {
            // Compose alert elements
            let elements = this._composeElements(task);
            // Update downloaded elements, and set downloaded to true to avoid
            // infinite loop downloaded
            this.setState({
                elements: elements,
                downloaded: true
            });
        });
    }

    /**
     * Make an sinc download
     * @param  integer  taskId  The id of the task
     * @return object           The task downlaoded
     */
    _syncDownloaded = async (taskId) => {
        // Load task, then download clips information like profiles
        let task = await this._loadTask(taskId);
        // Get clips
        let clips = task.clips();
        // Init clip
        let clip = undefined;
        // Init clip profiles
        let profiles = undefined;

        // Iterate each clips
        for (var i = 0; i < clips.length; i++) {
            // Get the clip
            clip = clips[i];
            // Load task clips profile
            profiles = await this._loadTaskClipProfiles(clip.id());
            // Download clip
            clip = await this._downloadClip(clip.id(), profiles);

            // If clip is undefined, go next
            if (!clip) continue;

            // Update Clips
            task.editClip(clip);
        }

        // Return task
        return task;
    }

    /**
     * NOTE: TAKE FROM task.js!
     * Make API call to load task
     * @param  integer id   The ID of the task to be load
     * @return object       The task object
     */
    _loadTask = (id) => {
        // Call get task from API
        return Api.getTask(id).then((response) => {
            // Log in debug the call
            Logger.write('TaskList@_loadTask -> success.', 0, response);
            // Set task
            let data = response.data;
            // Create task object
            let task = TaskModel.init(data);
            // Return the task loaded
            // NOTE: here was: JSON.parse(JSON.stringify(task));
            return task;
        })
        // Catch the error
        .catch((err) => {
            // Log in error level
            Logger.write('TaskList@_loadTasks -> error.', 3, err);
            return {};
        });
    }

    /**
     * NOTE: TAKE FROM task.js!
     * Load task clips profiles and update store
     * @param  integer id    The clip id
     * @return Promise       The axios promise
     */
    _loadTaskClipProfiles = (id) => {
        // Inclide smippets
        let includeSnippet = "&include_keywords_snippet_offset="
                + Config.get('default_keyword_snippet_offset');
        // Call getProfiles with all clip ids
        // NOTE: ?clip_id=X OR ?clip__id=X run into API ERROR!
        return Api.getProfiles("?clip__id__in=" + id + includeSnippet).then(
            // Return response to the other promise
            response => {
                // Return all profiles if there are, otherwise an empty array
                return response.data ? response.data : [];
            });
    }

    /**
     * Download the clip of the current task
     * @param  integer  id                  The id of the clip
     * @param  array    downloadedProfiles  The array of profiles
     * @return Promise                      The call promise
     */
    _downloadClip = (id, downloadedProfiles) => {
        let me = this;
        // Call axios and await response, // Elaborate response for each cliè
        return Api.getClip(id).then(response => {
            // Get the clip (new copy)
            let clip = JSON.parse(
                JSON.stringify(response.data)
            );
            // Get the profiles (new copy)
            let workingProfiles = JSON.parse(
                JSON.stringify(downloadedProfiles)
            );
            // Process the clip profiles to get a complete profile
            let profiles = me._processClipProfileResponse(
                clip, workingProfiles
            );
            // Override clip profiles with new profiles
            clip.profiles = profiles;
            // Return clip edited
            return clip;
        }).catch((err) => {return undefined});
    }

    /*
    |-------------------------------------------------------------------------|
    |                                 RENDER ELEMENTS                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Compose the elements of the accordion
     * @param  Task task    The task
     * @return JSX          The elements composed
     */
    _composeElements = (task) => {
        // Init keywords
        let keywords = [];

        // Iterate each clips
        let clipsJSX = task.clips().map(clip => {
            // Iterate rach profiles
            let profilesJSX = clip.profiles().map(profile => {
                // Check if current profile item has missing data
                let metadataMissing = Utility.isMetadataMissing(profile);
                // Create a name to be displayed
                let name = profile.customer_name
                    ? profile.customer_name : "Profilo #" + profile.id;
                // Return the JSX row element
                return (
                    <div key={"detail-task-clip-profile" + profile.id}  className="task-list modal-profile list-element" data-is-focusable={true} data-id={profile.id}>
                        <div className="">
                            <Icon title="SquareShapeSolid" ariaLabel="SquareShapeSolid" iconName="SquareShapeSolid" className="list-element-icon"/>
                        </div>
                        <div className="">
                            <TooltipHost
                                className="tooltip-metadata"
                                content={profile.notes && profile.notes.length > 0
                                    ? (<div><span className="list-task-detail clip-title">Note: </span><span className="clip-description text-justify">{profile.notes}</span></div>)
                                    : (<div><span className="list-task-detail clip-title">Note: </span><em className="clip-description text-justify">Nessuna nota per questo profilo</em></div>)
                                }>
                            <Icon iconName="Info" className="list-element-icon profiles-info"/>
                            </TooltipHost>
                        </div>
                        <div>
                            <div className="list-element-part max-width">#{profile.id} {name}</div>
                            <div className={"list-element-part " + (metadataMissing ? "" : "hidden")}>
                                <Icon iconName="Warning12" title="Metadati Mancanti" ariaLabel="Metadati Mancanti" className="list-element-part icon color-warning"/>
                            </div>
                            <div className={"list-element-part " + (!metadataMissing ? "" : "hidden")}>
                                <Icon iconName="Accept" title="accept" ariaLabel="accept" className="list-element-part color-success"/>
                            </div>

                        </div>
                    </div>
                );
            });

            // If clip has metdata array
            keywords = clip.metadata()
                // Filter each metdata
                ? clip.metadata().filter(
                    // And take only keyword or tag type
                    k => k.type() === "keyword" || k.type() === "tag"
                // Iterate the filter result and return an new array instance
                ).map(
                    // Compose the string "keyord (HH:mm:ss)"
                    k => (k.value() + " (" + k.start('time') + ")")
                )
                // Otherwise return null
                : null;


            // Take the first profile, and take the snippets array
            const snippet = this._composeSnippet(clip.profiles());

            // Return the clip view
            return <div key={"detail-task-clip-" + clip.id()} className="list-task-detail container">
                    <div className="detail-task-clip-checkbox-container" data-task-id={task.id()} data-clip-id={clip.id()}>
                        <Checkbox
                            label={null}
                            className="clip-checkbox"
                            onChange={this._onClipCheckboxChange}
                            />
                    </div>
                    <div className="right-el">
                        <div className="list-task-detail-infos">
                            <div><span className="list-task-detail clip-title">Clip ID: </span><span className="clip-description">{clip.id()}</span></div>
                            <div><span className="list-task-detail clip-title">Keywords: </span><span className="clip-description">{keywords ? keywords.join(', ') : "-"}</span></div>
                        </div>
                        {clip.profiles() && clip.profiles().length > 0
                            ? (
                                <div>
                                    <div> {snippet} </div>
                                    <div> {profilesJSX} </div>
                                </div>
                            )
                            : (
                                <div className="snippet-box">
                                    <span className="list-task-detail no-profile-title">Questa clip non ha profili assegnati.</span>
                                </div>
                            )
                         }

                    <Separator horizontal/>
                    </div>
                </div>
        });

        // If no clip was found
        if (clipsJSX.length === 0) {
            // Push part of no clips
            clipsJSX.push(
                <div key={"detail-task-no-clip"} className="list-task-detail container">
                    Nessuna clip per questa task. E&apos; possibile che le clip siano state eliminate o modificate da un operatore.
                </div>
            )
        }

        // Return all results inside a div
        return <div key={"detail-task-" + task.id()} className="list-task-container"> {clipsJSX} </div>
    }

    /**
     * Compose snippets list and make keyword bold
     * @param  Array profiles   Array of profiles
     * @return JSX              JSX element composed
     */
    _composeSnippet = (profiles) => {
        // If profiles of a clip are defined and has almost 1 profile
        const snippets = profiles && profiles.length > 0
            // Take the snippets of that profile. Not needed if has more
            // profiles because are on the same clip, that means same words!
            ? profiles[0].snippet
            // Otherwise no profiles, no snippets!
            : undefined;

        // If snippets are null, exit
        if (!snippets) return <div></div>;

        // Init snippet
        let snippet = undefined;
        // Iterate each snippets
        return snippets.map((s, index) => {

                // If s is undefined or s.text is undefined or s.text is empty
                if (!s || !s.text || s.text.trim().length < 1) {
                    // Compose a key
                    let key = "word-" + s.order + "-placeholder";
                    // Compose a "snippet is not available"
                    return <em key={key}>Snippet non disponibile.</em>;
                }

                // Split each word
                snippet = s.text.split(' ').map((word, i) => {
                    // If the current word is keyword
                    if (s.position.find(p => p === i)) {
                        // Compose a key
                        let key = "w-"+ s.order + "-" + i;
                        // Return in bold
                        return <b key={key}>{word+" "}</b>;
                    }
                    // Otherwise return normal
                    return word + " ";
                });

                if (snippets.length-1 !== index) snippet.push(
                    <Icon key={"snippet-break-line-" + index} title="GripperBarVertical" ariaLabel="GripperBarVertical" iconName="GripperBarVertical" className="list-element-icon"/>
                );

                // Compose a snippet roe
                return snippet;
            });
    }

    /**
     * Get the list of ids of the clips not checked in the accordion task.
     * @param  integer taskId   The Task id
     * @return array            Array of clip IDs
     */
    _getUnselectedClipIds = (taskId) => {
        // Get all checkboxes
        let checkboxes = document.querySelectorAll(
            '.detail-task-clip-checkbox-container[data-task-id="'+taskId+'"]'
        );

        // Init unselectedIds array
        let unselectedIds = [];

        // Iterate each chekcboxes
        for (var i = 0; i < checkboxes.length; i++) {
            // If checkboxes has NOT checked
            if (!checkboxes[i].classList.contains("checked"))
                // Push that id
                unselectedIds.push(checkboxes[i].dataset.clipId);
        }

        // Return unselected ids
        return unselectedIds;
    }

    /**
     * NOTE: taken from Task.js!
     * Downloaded profiles does't have metadata.
     * Downloaded clip -> profiles -> have metadatas!
     * That profiles have not profile informations (only url).
     * Take the downloaded profile and override the metadata with clip profile
     * metadata.
     *
     * @param  object clip               [description]
     * @param  array downloadedProfiles [description]
     * @return array                    [description]
     */
    _processClipProfileResponse = (clip, downloadedProfiles) => {
        // Get all ids of profile assigned to current clip
        let profileIds = clip['profiles'].map(
            clipProfile => clipProfile.id
        );

        // Get all downloadedProfiles data that are matching current clip
        let profiles = downloadedProfiles.filter(
            profile => profileIds.includes(profile.id)
        );

        // Made it unique
        profiles = profiles.filter(
            // Make unique profiles based on profile.id
            (e, i) => profiles.findIndex(
                    a => a['id'] === e['id']
            ) === i
        );

        // Iterate each unique profiles
        profiles = profiles.map(profile => {
            // Take the profile of the clip
            let clipProfile = clip.profiles.find(clipProfile => profile.id === clipProfile.id);
            // Override metadata by setting all clip metadata filtered
            profile['metadata'] = clipProfile['metadata'];
            // Return profile
            return profile;
        });

        // Return profiles with metadata modified
        return profiles;
    }

    /**
     * Render component
     * @return {} []
     */
    render() {
        // Take task ID
        const { taskId } = this.props;

        // If not id was specified, exit, not to load
        if (!taskId) return (
            <div>Errore durante la creazione del componente</div>
        );

        // Run downloaded
        this.download(taskId);

        const shimmerButtons = [
            { type: ShimmerElementType.gap, width: '75%' },
            { type: ShimmerElementType.line, width: '10%',  height:30},
            { type: ShimmerElementType.gap, width: '5%' },
            { type: ShimmerElementType.line, width: '10%', height:30}
        ];
        const shimmerCircle = [
            { type: ShimmerElementType.circle, width: '5%' },
            { type: ShimmerElementType.gap, width: '2%' },
            { type: ShimmerElementType.line, width: '98%'},
        ];
        const shimmerRow = [
            { type: ShimmerElementType.gap, width: '4%' },
            { type: ShimmerElementType.line, width: '96%'},
        ];
        const shimmerRowPartial = [
            { type: ShimmerElementType.gap, width: '4%' },
            { type: ShimmerElementType.line, width: '71%'},
            { type: ShimmerElementType.gap, width: '25%' },
        ];

        return (

            <div>
                <div hidden={!this.state.elements}>
                    <div className="accordion-body" >
                        {this.state.elements}
                    </div>
                    <div className="accordion-footer">
                        <PrimaryButton onClick={() => this._onConfirmAndWorkClicked(this.props.taskId)} text="Conferma e lavora"/>
                        <PrimaryButton onClick={() => this._onConfirmAndQueueClicked(this.props.taskId)} text="Conferma e accoda"/>
                    </div>
                </div>
                <div className="accordion-body shimmer"  hidden={this.state.elements}>
                    <div className="accordion-body shimmer">
                        <Shimmer className="accordion-shimmer-default" shimmerElements={shimmerCircle} />
                        <Shimmer className="accordion-shimmer-default" shimmerElements={shimmerRow} />
                        <Shimmer className="accordion-shimmer-default" shimmerElements={shimmerRow} />
                        <Shimmer className="accordion-shimmer-default" shimmerElements={shimmerRowPartial} />
                    </div>
                    <div className="accordion-footer shimmer">
                        <Shimmer shimmerElements={shimmerButtons} />
                    </div>
                </div>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default TaskRowAccordion;

// const el = document.body
// ReactDOM.render(<TaskRowAccordion name='John' />, el)
