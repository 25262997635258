import React from 'react';
// Import FabricUI compoents
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
// Import classes
import Api from '../../classes/Api.js';
import Logger from '../../classes/Logger.js';
import Config from '../../classes/Config.js';

class RejectClipsAndQueueModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'RejectClipsAndQueueModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            data: undefined,
            newData: undefined,
            loading: false
        }
    }

    /**
     * Toggle dialog
     * @param  Object data      The data object to be sent
     * @return void
     */
    toggle = (data) => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            unselectedIds: data.ids,
            taskId: data.taskId
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            data: {},
            taskId: null,
            loading: false
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Get the server error message from the ajax "error" object returned
     * @param  Error err    The ajax error catched from server response
     * @return string       The error message
     */
    _getErrorMessage = (err) => {
        // Get error object
        err = err && err.response;
        // Get status code
        let code = err.status;
        // If has error data, and has the error data msg from API
        let message = err.data && err.data.message
            // Take it
            ? err.data.message
            // Otherwise, take the status text
            : err.statusText;
        // Compose error message: if has a message
        return message
            // Print with status code
            ? code + ": " + message
            // Otherwise return generic error
            : "An error occurred";
    }

    /**
     * Save the data passed to the modal
     * @return void
     */
    save = () => {
        // Log into debug
        Logger.write("RejectClipsAndQueueModal@save -> start:", 0);
        // Init task
        this._apiCalls();
    }

    /**
     * Make all API call: first reject unfinished clips, then confirm task
     * @return Promise    The promises
     */
    _apiCalls = async () => {
        // Save the error
        this.setState({loading: true});
        // Get all ids
        const ids = this.state.unselectedIds;
        // Init errors var
        let errors = [];
        // Init task
        let task = null;

        // Iterate each ids
        for (var i = 0; i < ids.length; i++) {
            // Do async operation and if gotta error, push inside error bag
            await this._rejectClip(ids[i]).catch(err => errors.push(err));
        }

        // Do async operation
        await this._updateTask(this.state.taskId)
            // Get the taks updated
            .then(response => task = response)
            // If gotta error, push inside error bag
            .catch(err => errors.push(err));

        // Save the error
        this.setState({loading: false});
        // Handle action response
        this.props.handleActionResponse({
            mode: 'reject_clips_and_queue',
            data: errors,
            task: task,
            taskId: this.state.taskId
        });
        // Close the current modal
        this.close();
        // Exit
        return false;
    }

    /**
     * Reject clip by changing status
     * @param  integer id The id of the clip to be rejected
     * @return Promise    Axios promise
     */
    _rejectClip = (id) => {
        // Delete the clips
        return Api.rejectClip(id).then(response => {
            // Log in debug
            Logger.write('RejectClipsAndQueueModal@_deleteClip -> first deletion success.', 0);
            // Return task
            return null;
        // Then delete the second clips
        }).catch((error) => {
            // Log in error
            Logger.write('RejectClipsAndQueueModal@_deleteClip -> got an error.', 3, error);
            // Take the error message from server response
            let message = this._getErrorMessage(error);
            // Return error message
            return message;
        });
    }

    /**
     * Update task by changing owner and status (put approved)
     * @param  integer taskId   The Task id
     * @return Promise          The axios promise
     */
    _updateTask = (taskId) => {
        // Create the body of patch
        let body = {
            'status': Config.get('URL_API_STATUS')
                + Config.get('DEFAULT_STATUS_APPROVED_ID') + "/",
        }

        // Make PATCH request
        return Api.partialUpdateTask(taskId, body).then((response) => {
            // Log in debug the call
            Logger.write(
                'RejectClipsAndQueueModal@partialUpdateTask -> success.',
                0,
                response
            );
            // Return the update task
            return response.data;
        })
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write(
                    'RejectClipsAndQueueModal@partialUpdateTask -> error.',
                    3,
                    err
                );
                // Go out
                return "Error while assign task " + taskId + ".";
            }
        );
    }


    /**
     * Count clips OK or not OK
     * @return integer      The number of unselectedClips
     */
    _getClipsCount = () => {
        // If no task or no data was provided, return 0
        if (!this.state.unselectedIds) return 0;

        // Return the number of unselected clips
        return this.state.unselectedIds.length;
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // Count how many clips have to reject
        const unselectedIds = this._getClipsCount();

        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Conferma Clips e Accoda',
                    subText: 'Premendo su "Conferma" '
                    + (unselectedIds === 1
                        ? "verrà rifiutata " + unselectedIds + " clip"
                        : "verranno rifiutate " + unselectedIds + " clips")
                     + " e verrà approvata la task."
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <DialogFooter>
                <PrimaryButton onClick={this.save} text={ this.state.loading ? "Caricamento..." : "Conferma"} disabled={this.state.loading} />
                <DefaultButton onClick={this.close} text="Cancel" />
            </DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default RejectClipsAndQueueModal;
