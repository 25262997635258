import React from 'react';
// Import FabricUI elements
import { Dropdown} from 'office-ui-fabric-react/lib/Dropdown';
import { DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';

class SelectClipModalView extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'SelectClipModalView'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Compose select data
        let elements = this._composeSelectData(this.props.clips);
        // Get the first clip
        let clip = this._getClipFromId(elements[0].key);
        // Set default state
        this.state = {
            visible: true,
            value: '',
            selectedItem: elements[0],
            selectedClip: clip,
            data: elements
        }
    }

    /**
     * Create the select data for Fabric UI component
     * @param  array    clips   Array of clips passed from modal
     * @return array            Array of object with data for component
     */
    _composeSelectData = (clips) => {
        // Init results array
        let results = [];
        // Init loop vars
        let el, title;

        // If clips are null
        if (!clips) {
            // Return
            return false;
        }

        // Iterate each clips
        for (var i = 0; i < clips.length; i++) {
            // Compose title
            title = 'Clip ID:' + clips[i].id()
                + " [" + clips[i].start('time') + " - "
                + clips[i].end('time') + "] ("
                + clips[i].profiles().length + " profili)";
            // Compose element
            el = {
                key: clips[i].id(),
                text: title
            }
            // Push element into array of element
            results.push(el);
        }

        // Return the clips results
        return results;
    }

    /**
     * Get the clip object from an id passed
     * @param  string id    The id of the clip
     * @return object       The clip object
     */
    _getClipFromId = (id) => {
        // Find clip
        let result = this.props.clips.filter(clip => clip.id() === id);
        // Return it if found
        return result.length > 0 ? result[0] : undefined;
    }

    /*
    |-------------------------------------------------------------------------|
    |                           HANDLE ACTION METHODS                         |
    |-------------------------------------------------------------------------|
    */

    /**
    * On Change event of Fabric UI component
    * @param  ReactEvent    event   Event on change
    * @return void
    */
    _onChange = (event, item) => {
        // Get the clip from the id passed
        let clip = this._getClipFromId(item.key);

        // Update state with item selected
        this.setState({
            selectedItem: item,
            selectedClip: clip
        });
    }

    /**
     * Handle click on "Previous" button
     * @return void
     */
    _handlePreviousButtonClicked = () => {
        // Call parent method
        this.props.handlePreviousViewChange();
    }

    /**
     * Handle click on "Next" button
     * @return void
     */
    _handleNextButtonClicked = () => {
        // Call parent method and pass selected clip
        this.props.handleNextViewChange(this.state.selectedClip);
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // Get selected item from state
        const { selectedItem } = this.state;

        // Return component
        return (
            <div hidden={this.props.hidden}>
                <Dropdown
                label="Inserisci metadati per la clip"
                selectedKey={selectedItem ? selectedItem.key : undefined}
                onChange={this._onChange}
                placeholder="Seleziona una clip"
                options={this.state.data}
                />
                <DialogFooter>
                    <DefaultButton text="Annulla" onClick={this._handlePreviousButtonClicked}/>
                    <PrimaryButton text="Avanti" onClick={this._handleNextButtonClicked}/>
                </DialogFooter>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default SelectClipModalView;
