import axios from 'axios';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

// Import redux store for data exchange
import store from '../../redux/store';
import { logout } from '../../redux/actions';
import { callsUpdate, reconciliationControl } from '../../redux/actions';

import Config from './Config.js';
import Logger from './Logger.js';
import History from './History.js';
import Utility from './Utility.js';

// Make call to API with axios
const Api = {
    /**
     * Timeout call value, in ms
     * @type integer
     */
    timeout: Config.get('DEFAULT_API_TIMEOUT_MS', 60000),

    /**
     * Indicate if after call should enable render
     * @type {Boolean}
     */
    render: true,

    /**
     * Login an user
     * @param array     credentials     The user credentials
     * @return Promise                  Axios instance
     */
    login: function(credentials) {
        // Log in debug the call
        Logger.write('Api@login -> call start.', 0);
        // Set verb to GET
        let verb = 'POST';
        // Load the url for current call
        let url = Config.get('URL_API_LOGIN');
        // Init headers
        let headers = {};
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, headers, credentials);
    },

    /**
     * Logout an user
     * @return Promise                  Axios instance
     */
    logout: function() {
        // Log in debug the call
        Logger.write('Api@logout -> call start.', 0);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_LOGOUT');
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Load the list of tasks
     * @return Promise      Axios instance
     */
    getTasks: function(queryParams) {
        // Log in debug the call
        Logger.write('Api@getTasks -> call start.', 0, queryParams);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_TASKS');
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, null, null, queryParams);
    },

    /**
     * Load a single task informations
     * @param  integer id   The id of the task to be load
     * @return Promise      The Axios promise
     */
    getTask: function(id) {
        // Log in debug the call
        Logger.write('Api@getTask -> call start.', 0, id);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_TASKS') + id + "/?clips_not_rejected=true";
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Partial edit a task
     * @param  integer id   The id of the task to be edited
     * @param  object  data The data to be patched
     * @return Promise      The Axios promise
     */
    partialUpdateTask: function(id, data) {
        // Log in debug the call
        Logger.write('Api@partialEditTask -> call start.', 0, [id, data]);
        // Set verb to GET
        let verb = 'PATCH';
        // Load the url for current call
        let url = Config.get('URL_API_TASKS') + id + "/";
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, undefined, data);
    },

    /**
     * Load a clip
     * @param  integer id   The id of clip to load
     * @return Promise      The promise axios
     */
    getClip: function(id) {
        // Log in debug the call
        Logger.write('Api@getClip -> call start.', 0, id);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_CLIPS') + id + "/";
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Load an attachment
     * @param  integer id       The id of the attachement to load
     * @param  boolean stream    True if have to put '?stream' to get stream
     * @return Promise           Axios instance
     */
    getAttachment: function(id, stream) {
        // Log in debug the call
        Logger.write('Api@getAttachment -> call start.', 0, id);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_ATTACHMENTS')
            // Concat the id and the stream parameter if needed
            + id + "/" + (stream ? '?stream' : '');
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Load the list of tasks
     * @param  string   filters     The filters to put in url as query parameters
     * @param  boolean  render      Say if have to render component after response
     * @return Promise              Axios promise instance
     */
    getProfiles: function(filters, render) {
        // Log in debug the call
        Logger.write('Api@getProfiles -> call start.', 0, [filters, render]);
        // If render is specify, use that value, otherwise set true as default
        this.render = render === undefined || render === null ? true : render;
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call and add filters if there are
        let url = Config.get('URL_API_PROFILES') + (filters ? filters : '');
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Create new profile
     * @param  object   body    The profile informations
     * @return Promise          Axios promise instance
     */
    createProfile: function(body) {
        // Log in debug the call
        Logger.write('Api@createProfile -> call start.', 0, body);
        // Set verb to GET
        let verb = 'POST';
        // Load the url for current call and add filters if there are
        let url = Config.get('URL_API_PROFILES')
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, undefined, body);
    },

    /*
    |-------------------------------------------------------------------------|
    |                           TASK ACTIONS                                  |
    |-------------------------------------------------------------------------|
    */

    /**
     * Action to add add/remove or edit metadata of a task
     * @param  object body  The metadata object to be sent
     * @return Promise      THe Axios promise
     */
    manageMetadata: function(body) {
        // Log in debug the call
        Logger.write('Api@manageMetadata -> call start.', 0, body);
        // Set verb to GET
        let verb = 'POST';
        // Load the url for current call
        let url = Config.get('URL_API_ACTIONS_MANAGE_METADATA');
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, undefined, body);
    },

    /**
     * Make a POST request to create a clip
     * @param  object  body     The body to be sent
     * @return Promise          Return the promise of axios
     */
    createClip: function(body) {
        // Log in debug the call
        Logger.write('Api@createClip -> call start.', 0, body);
        // Set verb to GET
        let verb = 'POST';
        // Load the url for current call
        let url = Config.get('URL_API_CLIPS');
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, undefined, body);
    },

    /**
     * Make a PATCH request to delete a clip.
     * NOTE: is not a DELETE request, is a patch request with deleted_at value
     * @param  integer  uuid    The id of the clip to delete
     * @return Promise          Return the promise of axios
     */
    deleteClip: function(id) {
        // Log in debug the call
        Logger.write('Api@deleteClip -> call start.', 0, id);
        // Set verb to GET
        let verb = 'PATCH';
        // Load the url for current call
        let url = Config.get('URL_API_CLIPS') + id + "/";
        // Load default format
        let format = Config.get(
            'DEFAULT_API_DATE_FORMAT', 'YYYY-MM-DDTHH:mm:ss.SSS'
        );
        // Create the body object
        let body = {deleted_at: moment().format(format)}
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, undefined, body);
    },

    /**
     * Make a PATCH request to reject a clip, use body with rejected_at value.
     * @param  integer  id      The id of the clip to reject
     * @return Promise          Return the promise of axios
     */
    rejectClip: function(id) {
        // Log in debug the call
        Logger.write('Api@rejectClip -> call start.', 0, id);
        // Set verb to GET
        let verb = 'PATCH';
        // Load the url for current call
        let url = Config.get('URL_API_CLIPS') + id + "/";
        // Compose reject status
        const status = Config.get('URL_API_STATUS')
            + Config.get('DEFAULT_STATUS_REJECTED_ID')
            + "/";
        // Create the body object
        let body = {status: status}
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, undefined, body);
    },

    /**
     * Make a PATCH request to clip to edit it
     * @param  integer  uuid    The id of the clip to delete
     * @param  object   body    The clip whole body with profiles
     * @param  boolean  render  True if have to enable render
     * @return Promise          Return the promise of axios
     */
    editClip: function(id, body, render) {
        // Log in debug the call
        Logger.write('Api@editClip -> call start.', 0, id);
        // If render is specify, use that value, otherwise set true as default
        this.render = render === undefined || render === null ? true : render;
        // Set verb to GET
        let verb = 'PATCH';
        // Load the url for current call
        let url = Config.get('URL_API_CLIPS') + id + "/";
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url, undefined, body);
    },

    /**
     * Action to reset task
     * @param  integer id   The id of task to be resetted
     * @return Axios        The Axios promise
     */
    resetTask: function(id) {
        // Log in debug the call
        Logger.write('Api@resetTask -> call start.', 0, id);
        // Set verb to GET
        let verb = 'POST';
        // Load the url for current call
        let url = Config.get('URL_API_ACTIONS_RESET_TASK') + id + "/";
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Make a POST request to end production
     * @param  integer  id      The id of the task to be confirmed
     * @return Promise          Return the promise of axios
     */
    endProduction: function(id) {
        // Log in debug the call
        Logger.write('Api@endProduction -> call start.', 0, id);
        // Set verb to GET
        let verb = 'PUT';
        // Load the url for current call
        let url = Config.get('URL_API_ACTIONS_CONFIRM_TASK') + id + "/";
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Action to get the next task id from API
     * @return Promise          Return the promise of axios
     */
    getNextTask: function() {
        // Log in debug the call
        Logger.write('Api@getNextTask -> call start.', 0);
        // Set verb to GET
        let verb = 'GET';
        // Load the url for current call
        let url = Config.get('URL_API_ACTIONS_GET_NEXT_TASK');
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /**
     * Make a POST request to end production
     * @param  integer  id      The id of the task to be confirmed
     * @return Promise          Return the promise of axios
     */
    confirmClipsAndCreateTask: function(id) {
        // Log in debug the call
        Logger.write('Api@confirmClipsAndCreateTask -> call start.', 0, id);
        // Set verb to GET
        let verb = 'PUT';
        // Load the url for current call
        let url = Config.get('URL_API_ACTIONS_CONFIRM_CLIPS_AND_CREATE_TASK')
        + id + "/";
        // Call api and return the axios instance to manage it in component
        return this.callAPI(verb, url);
    },

    /*
    |-------------------------------------------------------------------------|
    |                                OTHER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Compose user data object to be sent to sent as extra param to sentry
     * @return object   User object
     */
    _composeUserReportData: function() {
        // Load user
        const user = Utility.loadPersistData('user');

        // If user was not found, return
        if (!user) return null;

        // Compose data by get only certain informations
        let data = (({
            id, email, username
        }) => ({
            id, email, username
        }))(user);
        // Compose user name
        data['name'] = user.first_name + " " + user.last_name;
        // Return only certain data
        return data;
    },

    /*
    |-------------------------------------------------------------------------|
    |                        CALL THE API WITH DATA PASSED                    |
    |-------------------------------------------------------------------------|
    */

    /**
     * API Call method
     * You can manage it calling:
     * this.ajaxCall().then((response) => {}).cath((response) => {})
     * @param  string verb      The verb of call: GET/POST/PATCH
     * @param  string url       The url to be called
     * @param  object headers    The headers to be add
     * @param  object body      The body to be add
     * @param  object params    The query parameters to be add
     * @return Promise           Axios promise
     */
    callAPI: function(verb, url, headers, body, params) {
        // If headers are null, create it
        if (!headers) headers = {};

        // If inside header there are not AUTHORIZATION field, add it
        if (!headers.hasOwnProperty('AUTHORIZATION')
            && !url.includes("/login/")) {
            // Add because mandatory
            headers['AUTHORIZATION'] = 'Basic ' + localStorage.getItem('token');
        }

        // Log in debug the call
        Logger.write(
            'Api@callAPI -> call start.',
            0,
            [verb, url, headers, body, params]
        );

        // Create an uuid
        let uuid = Utility.uuid();
        // Dispatch the redux action callsUpdate
        store.dispatch(callsUpdate({
            uuid: uuid,
            url: url,
            method: verb,
            body: body,
            parameters: params,
            datatime: moment().valueOf(),
            instance: axios,
            type: 'REQUEST_START'
        }));

        // Compose all axios params
        let axiosParams = {
            // Set the url
            url: url,
            // Set here method GET || POST ...
            method: verb,
            // Put here headers
            headers: headers ? headers : {},
            // Put here the data body
            data: body ? body : {},
            // Put here query parameters
            params: params ? params : {},
            // Set timeout
            timeout: this.timeout
        };

        // Create a new promise
        return new Promise(
            (resolve, reject) => {
                // Make axios request and manage success
                axios(axiosParams).then(data => {
                    // Write a debug log
                    Logger.write('Api@callAPI -> call end with success.', 0);
                    // Dispatch the redux action callsUpdate
                    store.dispatch(callsUpdate({
                        uuid: uuid,
                        url: url,
                        method: verb,
                        body: body,
                        parameters: params,
                        datatime: moment().valueOf(),
                        response: data,
                        instance: axios,
                        type: 'REQUEST_END'
                    }));

                    // If should render
                    if (this.render) {
                        // Dispatch the redux action reconciliationControl
                        store.dispatch(reconciliationControl({
                            render: true,
                            target: 'transcriptionBox',
                        }));
                    }

                    // Resolve promise to be available a second time
                    resolve(data);
                // Catch an axios error if it is
                }).catch(e => {
                    // Write an error log
                    Logger.write('Api@callAPI -> call end with error.', 3, e);
                    // Load X-Request-Id from server header response
                    const requestId = e.response && e.response.headers['x-request-id'];
                    // Compose user object
                    const user = this._composeUserReportData();
                    // Set extra data to sentry
                    Sentry.setExtra("user", user);
                    // Set extra data to sentry
                    Sentry.setExtra("request-id", requestId);
                    // Catch sentry exception
                    let sentryId = Sentry.captureException(e);
                    // Dispatch the redux action callsUpdate
                    store.dispatch(callsUpdate({
                        uuid: uuid,
                        url: url,
                        method: verb,
                        body: body,
                        parameters: params,
                        datatime: moment().valueOf(),
                        response: e,
                        instance: axios,
                        type: 'REQUEST_END',
                        sentry_id: sentryId
                    }));

                    // If status is 401
                    if (e
                        && e.response
                        && e.response.status === 401
                        && !url.includes("/login/")) {
                        // Logout user
                        store.dispatch(logout(
                            {'token': localStorage.getItem('token')}
                        ));
                        // Redirect to home (login)
                        return History.go('/');
                    }

                    // Close promise with error
                    reject(e);
                });
            }
        );
    },
}

export default Api
