import React from 'react';
import ReactDOM from 'react-dom';
// FabricUI compoents
import { IconButton } from 'office-ui-fabric-react/lib/Button';
// Classes and components
import MetadataTag from './Metadata.js';
import Clip from './Clip.js';
import MetadataUtil from '../classes/MetadataUtil.js';
import Config from '../classes/Config.js';
import Logger from '../classes/Logger.js';
import Utility from '../classes/Utility.js';
import WordUtil from '../classes/WordUtil.js';
import ActionUtil from '../classes/ActionUtil.js';
import Metadata from '../classes/models/Metadata.js';
// Style
import '../styles/Timeline.css'

class Timeline extends React.Component {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'Timeline',
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            initialized: false,
            hasError: false,
        }
    }

    /**
     * Catch render error
     * @param  string error     The string error in console
     * @param  object info      Full stack error
     * @return void
     */
    componentDidCatch(error, info) {
        // Display fallback UI. COMBAK: inform server of that error.
        this.setState({ hasError: true });
    }

    /*
    |-------------------------------------------------------------------------|
    |                               ACTION HANDLE                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Click action on timeline
     * @param  event ev     Click event
     * @return void
     */
    _handleTimelineClick = (ev) => {
        // Get the target from word click
        let target = WordUtil.getWordTargetFromTimelineClick(
            ev, this.props.task
        );
        // HACK: Override target, maybe its better to add a var with target
        ev.target = target;
        // Get current action
        let action = this.props.action;
        // Init word object
        let data = false;

        // // If there is a common click AND is not a clip
        if (Utility.noActionRunning(action) && !WordUtil.isClip(target)) {
            // Manage wordclick
            this.props.handleActionChange(undefined);
            // Do nothing, exist
            return data;
        }
        // Otherwise if there are no action AND is a clip
        else if (Utility.noActionRunning(action) && WordUtil.isClip(target)) {
            // User has just created a new "edit_clip" OR "select_clip" action!
            action = {
                "context": "timeline",
                "mode": (this.props.isSpecialPressed
                        ? 'select_clip' : 'edit_clip'),
                "event": ev
            }
            // Run action change
            this.props.handleActionChange(action);
            // Exit with null data
            return data;
        }

        // If action is select_clip && there is not special pressed
        if (action.mode === "select_clip" && !this.props.isSpecialPressed) {
            // Manage wordclick
            this.props.handleActionChange(undefined);
            // Do nothing, exist
            return data;
        }

        // Compose the data to be processed from dialog
        data = ActionUtil.composeData(
            action, target, this.props.task, this.props.selected
        );

        // If data is defined: we are ready to continue
        if (data) {
            // Toggle modal to add keyword
            this.props.handleActionDialog(action, data);
        }
    }

    /**
     * Click action on timeline
     * @param  event ev     Click event
     * @return void
     */
    _handleTimelineDoubleClick = (ev) => {
        // Get the target of click
        let target =  ev.target;

        // If target has badge class
        if (target.classList.contains('badge-clip-profiles')) {
            // It means that should take the parent element, or rather timeline
            target = target.parentElement;
        }

        // Get the timeline
        let timeline = this.refs.timeline;
        // Get the position of click
        let position = ev.nativeEvent.layerX;
        // position : timeline.clientWidth = X : 100
        let percentage = (position * 100 / timeline.clientWidth) / 100;
        // Init element margin
        let elementMargin = target.style.marginLeft.replace(/%/i, '') || 0;
        // Add old margin to current
        percentage += parseInt(elementMargin) / 100;
        // Get duration task
        let duration = this._getTaskDuration(this.props.task);
        // Create progress object
        let progress = Utility.createProgressFromPercentage(
            percentage, duration
        );
        // Call parent to manage progress
        this.props.handleProgressChange('timeline', progress);
    }

    /**
     * Click action on increase zoom button
     * @param  {Event} ev   Click event
     * @return {Boolean}
     */
    _handleIncreaseZoomClick = (ev) => {
        // Log into verbose level
        Logger.write("Increase zoom button clicked", 0, ev);
        // TODO: write the function
        return false;
    }

    /**
     * Click action on decrease zoom button
     * @param  {Event} ev   Click event
     * @return {Boolean}
     */
    _handleDecreaseZoomClick = (ev) => {
        // Log into verbose level
        Logger.write("Decrease zoom button clicked", 0, ev);
        // TODO: write the function
        return false;
    }

    /*
    |-------------------------------------------------------------------------|
    |                                 ELEMENT UPDATE                          |
    |-------------------------------------------------------------------------|
    */

    /**
     * Init clip position in timeline
     * @param  HTML timeline    The timeline of UI
     * @return Boolean          True
     */
    initElementsPosition = (timeline) => {
        // Get all metadata
        let metadata = timeline.querySelectorAll('.timeline-metadata');
        // Get all clips
        let clips = timeline.querySelectorAll('.timeline-clip');

        // Write log
        // Logger.write(
        //     "Timeline@initElementsPosition -> start,",
        //     0,
        //     [metadata.length, clips.length]
        // );

        // Iterate rach metadata
        metadata.forEach((meta) => {
            // Move it in timeline
            this.moveMetadataInTimeline(timeline, meta);
        });

        // Iterate each clips
        clips.forEach((clip) => {
            // Move it in timeline
            this.moveClipInTimeline(timeline, clip);
        });

        // Return true
        return true;
    }

    /**
     * Update timeline progress in percentage
     * @param  integer progress     [Progress in percentage]
     * @return void
     */
    updateTimelineProgress = (progress) => {
        // If progress is not defined
        if (!progress) {
            // Return false
            return false;
        }

        // Calculate percentage
        let percentage = progress.played *100;
        // Get dom
        const node = ReactDOM.findDOMNode(this);

        // Get child nodes
        if (node instanceof HTMLElement) {
            // Find progressbar
            let child = node.querySelector('#progress-bar');

            // If child is found
            if (child) {
                // Set new progress
                child.style.marginLeft = percentage + "%";
            }
        }
    }

    /**
     * Move the clip based on the time specy in data-clip
     * @param  HTML timeline    The timeline form UI
     * @param  HTML clip         The clip form UI
     * @return Boolean          True
     */
    moveClipInTimeline = (timeline, clip) => {
        // Get timeline start unix time
        let start = timeline.dataset ? timeline.dataset.start : null;
        // Get timeline end unix time
        let end = timeline.dataset ? timeline.dataset.end : null;
        // Get start time in unix timestamp
        let clipStart = MetadataUtil.getStartTime(clip);
        // Get end time in unix timestamp
        let clipEnd = MetadataUtil.getEndTime(clip);

        // If clip has time lower then timeline start
        if (clipStart < start) {
            // Log an error
            Logger.write(
                'Timeline@moveClipInTimeline -> clip has time lower then timeline start.',
                2,
                [clipStart, start]
            );
            // Return false
            return false;
        }

        // If clip has time greater then timeline end
        if (clipEnd > end) {
            // Log an error
            Logger.write(
                'Timeline@moveClipInTimeline -> clip has time greater then timeline end.',
                2,
                [clipEnd, end]
            );
            // Return false
            return false;
        }

        // Calulate offset and remove half of metadata width
        let positionStart = this._calculateTimelinePosition(
            start, end, clipStart, timeline.offsetWidth, clip.offsetWidth
        );
        // Get the end point
        let positionEnd = this._calculateTimelinePosition(
            start, end, clipEnd, timeline.offsetWidth, clip.offsetWidth
        );
        // Get the clip width
        let width = positionEnd - positionStart;
        // Set attribute
        clip.setAttribute(
            'style',
            'margin-left:' + positionStart + '%; width:' + width + '%'
        );
        // Return true
        return true;
    }

    /**
     * Move the metadata based on the time specy in data-metadata
     * @param  HTML timeline    The timeline form UI
     * @param  HTML metadata         The metadata form UI
     * @return Boolean          True
     */
    moveMetadataInTimeline = (timeline, metadata) => {
        // Get timeline start unix time
        let start = timeline.dataset ? timeline.dataset.start : null;
        // Get timeline end unix time
        let end = timeline.dataset ? timeline.dataset.end : null;
        // Get the metadata data informations
        let metadataTime = MetadataUtil.getTime(metadata);

        // If metadata has time lower then timeline start
        if (metadataTime < start) {
            // Log an error
            Logger.write(
                'Timeline@moveMetadataInTimeline -> metadata has time lower then timeline start.',
                2,
                metadataTime
            );
            // Return false
            return false;
        }

        // If metadata has time greater then timeline end
        if (metadataTime > end) {
            // Log an error
            Logger.write(
                'Timeline@moveMetadataInTimeline -> metadata has time greater then timeline end.',
                2,
                metadataTime
            );
            // Return false
            return false;
        }

        // Calulate offset and remove half of metadata width
        let offset = this._calculateTimelinePosition(
            start, end, metadataTime, timeline.offsetWidth, metadata.offsetWidth
        );
        // Set attribute
        metadata.setAttribute('style', 'margin-left:' + offset + '%');
        // Return true
        return true;
    }

    /**
     * Give the percentage current position in timeline
     * @param  integer taskStart        The unix timestamp of task start
     * @param  integer taskEnd          The unix timestamp of task end
     * @param  integer elementTime      The unix timestamp of the position
     * @return integer                  The percentage
     */
    _calculateTimelinePosition = (taskStart, taskEnd, elementTime) => {
        // Calculate the duration in unix time
        let duration = taskEnd - taskStart;
        // Calculate the element position in unix time
        let metadataPosition = elementTime - taskStart;
        // Get the position percentage
        let percentage = (100 * metadataPosition) / duration;
        // Return the value rounded
        return Math.round(percentage);
    }

    /*
    |-------------------------------------------------------------------------|
    |                          UI ELEMENTS GENERATION                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Generate default items to be added to timeline
     * @param  object task  The task object
     * @return array        Array of react elements created
     */
    generateDefaultItems = (task) => {
        // Check if task is empty and return emtpy array
        if (task.isEmpty()) return [];
        // Load the number of time meadata to create
        let timeMetadataNumber = parseInt(
            Config.get('DEFAULT_TIME_TAG_NUMBER', 4)
        );
        // Init items array
        let generated = [];
        // Init item
        let item, time, seconds;
        // Calculate task duration
        let duration = this._getTaskDuration(task)/1000;

        // Iterare the number of metadata number
        for (var i = 0; i <= timeMetadataNumber; i++) {
            // Get the timeline start
            let start = task.start('moment');
            // Re-init item
            item = {};

            // If we are at the last element
            if (i === timeMetadataNumber) {
                // Set task end to avoid approximation out of timeline end
                time = task.end('moment');
            } else {
                // Calculate seconds to add
                seconds = Math.round((duration/timeMetadataNumber)*i);
                // Add seconds
                time = start.add(seconds, 'seconds');
            }

            // Create an id
            item['uuid'] = 'time-metadata-' + i;
            // Set time type
            item['type'] = 'time';
            // Set the time of the metadata
            item['start_datetime'] = time.format("YYYY-MM-DD HH:mm:ss.SSS");
            // Set the time of the metadata
            item['value'] = time.format("HH:mm:ss");
            // Create a metadata with item data
            let meta = Metadata.init(item);

            try {
                // Generate metada tag
                let metadata = <MetadataTag key={meta.id()} {...meta} timelineStart={this.props.task.start()} timelineEnd={this.props.task.end()}/>
                // Push metadata tag
                generated.push(metadata);
            } catch (e) {
                console.error("Error while composing tate datge");
            }
        }

        // Add the progress bar indicator
        item = <IconButton primary
                    id="progress-bar"
                    key="progress-bar"
                    iconProps={{ iconName: 'CaretSolidUp' }}
                    title="Progress" ariaLabel="Progress"
                    />
            // Push progress bar
        generated.push(item);
        // REturn the list of item
        return generated;
    }

    /**
     * Calculate task duration
     * @param  object task      The current task
     * @return integer          The duration in ms
     */
    _getTaskDuration = (task) => {
        // If task is not defined, return 0
        if (!task) return 0;

        // If date start or date end are undefined, return 0
        if (!task.start() && !task.end()) return 0;

        // Get the timeline start
        let start = task.start();
        // Get the timeline end
        let end = task.end();
        // Return duration
        return (end - start);
    }

    /*
    |-------------------------------------------------------------------------|
    |                                   OTHER METHODS                         |
    |-------------------------------------------------------------------------|
    */

    /*
    |-------------------------------------------------------------------------|
    |                                   RENDER                                |
    |-------------------------------------------------------------------------|
    */

    // shouldComponentUpdate = (nextProps, nextState) => {
    //     return Utility.shallowCompare(this, nextProps, nextState);
    // }

    /**
    * Render component
    * @return {} []
    */
    render() {
        // If has an error,
        if (this.state.hasError) {
            // Retunr empty timeline
            return (<div className='task-timeline-container ms-Grid'>
                <div className="timeline-zoom-container">
                    <IconButton primary iconProps={{ iconName: 'Blocked12' }} title="ZoomDecrease" ariaLabel="ZoomDecrease" onClick={this._handleDecreaseZoomClick}/>
                    <IconButton primary iconProps={{ iconName: 'AddTo' }} title="ZoomIncrease" ariaLabel="ZoomIncrease" onClick={this._handleIncreaseZoomClick}/>
                </div>
                <div className="timeline-box">
                    <div id="timeline"
                        ref="timeline"
                        className="timeline border-secondary"
                        data-start={this.props.task.start()}
                        data-end={this.props.task.end()}
                        onClick={this._handleTimelineClick}
                        onDoubleClick={this._handleTimelineDoubleClick}
                        >
                    </div>
                </div>
            </div>)
        }

        return (
            <div className='task-timeline-container ms-Grid'>
                <div className="timeline-zoom-container">
                    <IconButton primary iconProps={{ iconName: 'Blocked12' }} title="ZoomDecrease" ariaLabel="ZoomDecrease" onClick={this._handleDecreaseZoomClick}/>
                    <IconButton primary iconProps={{ iconName: 'AddTo' }} title="ZoomIncrease" ariaLabel="ZoomIncrease" onClick={this._handleIncreaseZoomClick}/>
                </div>
                <div className="timeline-box">
                    <div id="timeline"
                        ref="timeline"
                        className="timeline border-secondary"
                        data-start={this.props.task.start()}
                        data-end={this.props.task.end()}
                        onClick={this._handleTimelineClick}
                        onDoubleClick={this._handleTimelineDoubleClick}
                        >
                        {this.generateDefaultItems(this.props.task)}

                        {this.props.task && this.props.task.metadata()
                            ? this.props.task.metadata().map(meta => meta.haveToPrint()
                                ? <MetadataTag key={meta.id()} {...meta}
                                    timelineStart={this.props.task.start()}
                                    timelineEnd={this.props.task.end()}
                                    clips={this.props.task.clips()} />
                                : ""
                            )
                            : ''
                        }

                        {this.props.task && this.props.task.clips()
                            ? this.props.task.clips().map(clipObject => <Clip key={clipObject.id()} {...clipObject} selected={WordUtil.isTargetSelected([clipObject], this.props.selected)} timelineStart={this.props.task.start()} timelineEnd={this.props.task.end()}/>)
                            : ''
                        }

                    </div>
                </div>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default Timeline;

// const el = document.body
// ReactDOM.render(<Timeline name='John' />, el)
