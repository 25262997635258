import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changeView } from '../../redux/actions'

class Home extends Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        name: 'Home'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Create the action payload
        let data = {
            'name':'home', 
            'data':{'name':this.props.name}
        };
        // Dispatches action to change View
        this.props.changeView(data);
    }


    render() {
        return (
            <div>
                Welcome to Infojuice Willie!
            </div>
        );
    }
}

export default connect(
  null,
  { changeView }
)(Home);
