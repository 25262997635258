import moment from 'moment';

const TranscriptionHelper = {

    /**
     * WARNING: TO BE TESTED!!!! it doesn't work well
     * Find the nearest clip from unix time passed
     * @param  integer  unix    The unix time
     * @param  array    clips   The array of clips to be checked
     * @param  string   mode    The mode (search from start or from end?)
     * @return object           The clip object
     */
    findNearestClip: function(unix, clips, mode) {
        // If mode is 'end', take date end, otherwise date start
        mode = mode === 'end' ? 'end' : 'start';
        // Init the moment time from unix time
        let time = moment(unix);
        // init result
        let result = undefined;

        // Iterate each clips
        clips.forEach((clip, i) => {
            // If we are search the start clip
            if (mode === 'start') {

                // If current clip end is after the word timestamp
                if (moment(clip.date_end).isAfter(time)) {
                    // Check if the last result was null, or the end far respect the current clip end
                    result = (
                        !result
                        || moment(result.date_end).isAfter(clip.date_end)
                    )
                    // Take the nearest clip
                    ? clip
                    // Preserve old clip
                    : result;
                }

            }
            // Otherwise we are searching the end of the clip
            else {
                // If current clip end is after the word timestamp
                if (moment(clip.date_start).isAfter(time)) {
                    // Check if the last result was null, or the end far respect the current clip end
                    result = (
                        !result
                        || moment(clip.date_end).isAfter(result.date_end)
                    )
                    // Take the nearest clip
                    ? clip
                    // Preserve old clip
                    : result;
                }

            }
        });

        // Return the clip result
        return result;
    },

    /**
     * Parse the XML transcription
     * @param  XML transcription The xml transcription from API
     * @return array             The array of json object with words
     */
    parseXML: function(transcription) {
        // Init words array
        let words = [];
        // If transcription not defined
        if (!transcription) return words;
        // Init parser
        let parser = new DOMParser();
        // Parse XML and create a DOM object
        let xml = parser.parseFromString(transcription, 'text/xml');
        // If xml not defined
        if (!xml) return words;
        // Get all tokens inside HTMLCollection
        let tokens = xml.getElementsByTagName('Token');
        // If xml not defined
        if (!tokens) return words;
        // Init word object
        let word = {};

        // Iterate rach HTMLElement
        for (var i = 0; i < tokens.length; i++) {
            // Empty object
            word = {};
            // Create an unique id
            word['id'] = 'word-' + i;
            // Init metadata array
            word['metadata'] = [];
            // Init clips array
            word['clips'] = [];
            // Set time
            word['time'] = tokens[i].getAttribute('time');
            // Set length
            word['length'] = tokens[i].getAttribute('length');
            // Set data
            word['data'] = tokens[i].getAttribute('data');
            // Add word to words array
            words.push(word);
        }

        // Return all words
        return words;
    }
}

export default TranscriptionHelper;
