import React from 'react';
import ReactPlayer from 'react-player'

import {DefaultButton} from 'office-ui-fabric-react/lib/Button';

import Api from '../classes/Api.js';
import Logger from '../classes/Logger.js';
import Config from '../classes/Config.js';
import Utility from '../classes/Utility.js';

import '../styles/Player.css'

class Player extends React.Component {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'Player'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            playbackRate: 1,
            isPlaying: false,
            progressInterval: 200,
            mediaUrl: null,
            urlLoading: false,
        }
    }

    /**
     * React lifecyrcle event of component did update
     * @return void
     */
    componentDidUpdate = () => {
        // Set media url and load video
        this._setMediaUrl();
    }

    /**
     * Set the player video url
     * @return void
     */
    _setMediaUrl = () => {
        // If task is not downloaded or player already downloaded
        if (!this.props.taskLoaded || this.state.playerLoaded) return;
        // If is already in loading process, return
        if (this.state.urlLoading) return;
        // Set that is in loading process
        this.setState({urlLoading: true});
        // Load transcription
        let medias = this.props.task.getAttachmentsByType('task_media');
        // If no transcriptions was found, return null
        if (!medias || medias.length < 1) return null;

        // // TODO: NOTE: Take only the first media for now: call get attachment
        Api.getAttachment(medias[0].id).then(
            // Manage success
            (response) => {
                // Log in debug the call
                Logger.write('Player@_setMediaUrl -> success.', 0, response);
                // Get the data from response
                let data = response['data'];
                // Compose the url, if data was not found, take an empty string
                let url = !data ? ''
                    // otherwise concut media url + file path
                    : Config.get('BASE_URL_WILLIE_MEDIA') + "/"
                        + data['file_path'];


                // If url can not be played.
                // NOTE: don't use ReactPlayer.canPlay(), it will fail
                if (!data
                    || !data['file_path']
                    || data['file_path'].length === 0
                ) {
                    // Log in debug the call
                    Logger.write(
                        'Player@_setMediaUrl -> Can\'t play url!', 2, url
                    );
                    // // Set url as empty string.
                    url = '';
                }

                // Update state
                this.setState({
                    mediaUrl: url,
                    playerLoaded: true,
                    urlLoading: false
                });
            }
        )
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Player@_setMediaUrl -> error.', 3, err);
                // Update state
                this.setState({
                    mediaUrl: '',
                    playerLoaded: true,
                    urlLoading: false
                });
            }
        );
    }

    /**
     * Update player progress by seeking it.
     * it will call onPlayerProgress that makes all other component update
     * @param  object progress      a JS object with percentage, duration, video duration
     * @return void
     */
    updatePlayerProgress = (progress) => {
        // HACK: DON'T KNOW WHY, if is called directly  this.refs.player.seekTo
        // the player is null. Assign to var and use it
        let player = this.refs.player;
        // Seek to percentage
        player.seekTo(progress.played);
    }

    /**
     * Toggle status var to start play player
     * @return void
     */
    play = () => {
        // Save the play status in state
        this.setState({ isPlaying: true });
        // Change the status to false
        this.onPlayerStatusChange(true);
    }

    /**
     * Toggle status var to pause player
     * @return void
     */
    pause = () => {
        // Save the pause status in state
        this.setState({ isPlaying: false });
        // Change the status to false
        this.onPlayerStatusChange(false);
    }

    /**
    * Called when media starts playing
    * @param  Event ev     [The event of player]
    * @return void
    */
    onPlayerStart = (ev) => {
        // Write log
        Logger.write('onPlayerStart:', 0, this.refs.player);
        // Play
        this.play();
    }

    /**
    * Called when media starts or resumes playing after pausing or buffering
    * @param  Event ev     [The event of player]
    * @return void
    */
    onPlayerPlay = (ev) => {
        // Write log
        Logger.write('onPlayerPlay:', 0, this.refs.player);
        // Play
        this.play();
    }

    /**
    * Called when media is paused
    * @param  Event ev     [The event of player]
    * @return void
    */
    onPlayerPause = (ev) => {
        // Write log
        Logger.write("onPlayerPause", 0, this.refs.player);
        // Pause player
        this.pause();
    }

    /**
    * Called when media finishes playing
    * @param  Event ev     [The event of player]
    * @return void
    */
    onPlayerEnded = (ev) => {
        // Write log
        Logger.write("onPlayerEnded", 1, this.refs.player);
        // Pause player
        this.pause();
    }

    /**
     * Callback containing played and loaded progress as a fraction,
     * and playedSeconds and loadedSeconds in seconds
     * @param  Event ev     [The event of player]
     * @return void
     */
    onPlayerProgress = (ev) => {
        // Write debug
        // Logger.write("onPlayerProgress", 0, ev);
        // call method that manage player progress
        this.props.handleProgressChange('player', ev);
    }

    /**
     * Manage the status change (play/pause) into task component
     * @param  boolean isPlaying    True if is playing, false otherwise
     * @return void
     */
    onPlayerStatusChange = (isPlaying) => {
        // Manage the player status change in task component
        this.props.handlePlayerStatusChange(isPlaying);
    }

    /**
     * Handle keyboard key press
     * @param  Event event [description]
     * @return void       [description]
     */
    handleKeyPress = (event) => {
        // Write log
        Logger.write('key pressed.', 1, event);
    }

    /**
     * Action click on speed button of player
     * @param  {[type]} ev [description]
     * @return {[type]}    [description]
     */
    playerSpeedButtonClicked = (ev) => {
        // Get button clicked
        let button = ev.currentTarget;

        // Iterate each buttons
        for (var i = 0; i < button.parentNode.children.length; i++) {
            // Remove SELECTED class from other buttons
            button.parentNode.children[i].classList.remove('selected');
        }

        // Add custom class to selected button
        button.classList.add('selected');
        // Get dataset
        let data = button.dataset;
        // Get the speed from action data button
        let speed = data.speed ? parseInt(data.speed) : 1;
        // Set new player state
        this.setState({
            // Set current player speed
            playbackRate: speed
        })
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return Utility.shallowCompare(this, nextProps, nextState);
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <div className='task-player-container'>
                <div className='player-wrapper'>
                    <div className="player-button-container" >
                        <DefaultButton
                            className="player-button speed-button selected"
                            data-speed="1"
                            text="1x"
                            onClick={this.playerSpeedButtonClicked}
                            />
                        <DefaultButton
                            className="player-button speed-button"
                            data-speed="2"
                            text="2x"
                            onClick={this.playerSpeedButtonClicked}
                            />
                        <DefaultButton
                            className="player-button speed-button"
                            data-speed="5"
                            text="5x"
                            onClick={this.playerSpeedButtonClicked}
                            />
                    </div>
                    <ReactPlayer ref="player"
                        url={this.state.mediaUrl}
                        controls={true}
                        onStart={this.onPlayerStart}
                        onPlay={this.onPlayerPlay}
                        onPause={this.onPlayerPause}
                        onEnded={this.onPlayerEnded}
                        onProgress={this.onPlayerProgress}
                        onKeyPress={this.handleKeyPress}
                        playbackRate={this.state.playbackRate}
                        progressInterval={this.state.progressInterval}
                        playing={this.state.isPlaying}
                        />
                </div>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default Player;

// const el = document.body
// ReactDOM.render(<Player name='John' />, el)
