import { CHANGE_VIEW } from "../actionTypes";

// Create the initial state of the
const initialState = {
    // The data if the view changing
    viewData: {}
}

// Export the reducer "viewChange", taking the state and the action
export default function (state = initialState, action) {
    // Create a copy of current state
    let newState = {...state};

    // Switch on action type
    switch (action.type) {
        // If the action is CHANGE_VIEW
        case CHANGE_VIEW: {
            // Take the new data passed, and set it into the state
            newState.viewData = action.payload;
            // Return the updated state
            return newState;
        }
        // As default
        default:
            // Return the default state
            return state;
    }
}
