import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import { IconButton } from 'office-ui-fabric-react/lib/Button';

// Redux action and store
import { connect } from "react-redux";
import { callsUpdate } from '../../redux/actions';

class ErrorBoundary extends Component {

    /**
     * Constructor init
     * @param void
     */
    constructor(props) {
        // Make props available into the component
        super(props);
        // Init event ID as null
        this.state = {
            eventId: null,
        };
    }

    /**
     * Sentry method fire when a sentry error is fired
     * @return object   The object with has error to true
     */
    static getDerivedStateFromError() {
      return { hasError: true };
    }

    /**
     * Method fired when an error is catched
     * @param  object  error        The error object
     * @param  object errorInfo     Extra error infos
     * @return void
     */
    componentDidCatch(error, errorInfo) {
        // Run the exception
        Sentry.withScope((scope) => {
            // Set extra infos
            scope.setExtras(errorInfo);
            // Report to sentry
            const eventId = Sentry.captureException(error);
            // Update state
            this.setState({eventId});
        });
    }

    /**
     * Check for an error in redux store from an API call
     * @return object   The API call ran into error
     */
    _receivedErrorFromRedux = () => {
        // Get last call ended
        let call = this.props.ended[this.props.ended.length-1];

        // If have not end a call yet, exit
        if (!call) return false;

        // If has a response AND it has a status code under 400, its OK, retur
        if (call.response && call.response.status < 400) return false;

        // Otherwise it has a 4XX or 5XX error
        return call.sentry_id;
    }

    render() {
        // Check if has received a error from redux
        const id = this.state.eventId || this._receivedErrorFromRedux();

        // When there's not an error, render children untouched
        return (
            <div>
                {this.props.children}
                <IconButton
                    className={id ? "sentry-bug-report-icon" : "hidden"}
                    iconProps={{ iconName: 'Bug' }}
                    title="Report feedback"
                    ariaLabel="Report feedback"
                    onClick={() => Sentry.showReportDialog({eventId: id})}/>
            </div>

        );
    }
}

// Take the redux store
const mapStateToProps = state => {
    // From redux store, callsUpdate, take only calls
    const calls = state.callsUpdate.calls;
    // Store ended calls in props
    const ended = state.callsUpdate.ended;
    // Return the view data of view change redux and set it to component props
    return { calls, ended };
};

// Connect to redux
export default connect(mapStateToProps, { callsUpdate })(ErrorBoundary);
