import Utility from './Utility.js';
import Config from './Config.js';

/**
 * Permission methods goes here
 * @type {Object}
 */
const Permission = {
    /**
     * Load authenticated user
     * @type object
     */
    user: Utility.loadPersistData('user'),

    /**
     * The default key (repr) to check if a group is intended as "moderator"
     * @type string
     */
    groupModeratorKey: Config.get('DEFAULT_API_GROUP_MODERATOR_KEY'),

    /**
     * Check if user is a moderator
     * @return boolean  True if is a moderator
     */
    isModerator: function() {
        // If user is not authenticated, return false
        if (!this.authenticated()) return false;

        // If user is staff, is almost anyting
        if (this.group(this.groupModeratorKey)) return true;

        // Otherwise as default is not a moderator
        return false;
    },

    /**
     * Return all groups of an user
     * @return array    All the groups of the current user
     */
    groups: function() {
        // If user is not authenticated, return false
        if (!this.authenticated()) return [];

        // Otherwise as default is not a moderator
        return this.user.groups || [];
    },

    /**
     * The current group of the user
     * @param  string name  The name of the group to get
     * @return object       The object {url:string, repr:string}.
     */
    group: function(name) {
        // Cast name to string + lower case it if defined
        name = name ? (name + "").trim() : undefined;

        // If no name was specified, exit
        if (!name) return undefined;

        // Return the group found or undefined if not found.
        return this.groups().find((g) => g.repr === name);
    },

    /**
     * Return if user is authenticated or not
     * @return object   User JS object if authenticated, false otherwise
     */
    authenticated: function() {
        // If user is found, return it otherwise return false
        return this.user || false;
    }
}

export default Permission;
