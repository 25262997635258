import React, { Component } from 'react';
import Config from '../classes/Config.js';
import { connect } from 'react-redux'
import { changeView } from '../../redux/actions'

class Info extends Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        name: 'Info'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Create the action payload
        let data = {
            'name':'info',
            'data':{'name':this.props.name}
        };
        // Dispatches action to change View
        this.props.changeView(data);
    }

    render() {
        return (<div>{
            Config.get('VERSION')
            ? "Infojuice Willie Versione " + Config.get('VERSION')
                + " (" + Config.get('ENV') + ")"
            : "File di configurazione non trovato"
        }</div>);
    }
}

export default connect(
  null,
  { changeView }
)(Info);
