// NOTE: with React { memo } the tooltip is build in the top left corner of the screen.
import React from 'react';
import moment from 'moment';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import Logger from '../classes/Logger.js';

const Metadata = props => {
    // If is a time metadata
    let content = props.type() === "time"
        // Create the absolute upper time metadata
        ? <span>{props.value()}</span>
        // Otherwise nothing
        :"";
    // Convert start to unix time
    let time = props.start();
    // Get id or create a ne one if necessart
    let id = props.id()
        ? props.id()
        : "unknow-" + Math.floor(Math.random() * 10001);
    // Create the style with the offset of current metadata
    let style = moveMetadataInTimeline(props);
    // Create tooltip text
    let tooltipContent = _composeTooltipContent(props);
    //TODO: if you would like to use React memo, have to move by hand. Try
    // calloutProps={{ style: {margin: '50px', position: 'absolute'} }}
    // tooltipProps={{ style: {marginLeft: '16%', position: 'relative'} }}

    // Compose metadata
    return <div>
        <TooltipHost
            id={"tip-" + id}
            className="tooltip-metadata"
            content={tooltipContent}
            calloutProps={{gapSpace: 0,   target: document.getElementById(id)}}>
            <div
                id={id}
                className="timeline-metadata metadata-tag"
                aria-labelledby={"tip-" + id}
                data-id={id}
                data-type={props.type()}
                data-datetime={time}
                style={style}
                >{content}</div>
        </TooltipHost>
    </div>
};

/**
 * Compose the tooltip keyword
 * @param       Object word     Word object with all props
 * @return      JSX             The element rendered
 */
function _composeTooltipContent(props) {

    // If props type is time, return only the time
    if (props.type() === "time" ) return props.value();

    // If props type is thumbnail
    if (props.type() === "thumbnail" ) return "thumbnail ("
        + moment(props.start()).format('HH:mm:ss') + ")";

    // If props type is all exept keyword
    if (props.type() !== "keyword" ) return props.value() + " ("
        + moment(props.start()).format('HH:mm:ss') + ")";

    // Init lists
    let lists = [];

    // Create a keyword tag
    let keywordTag = <li key={"tooltip-row-keyword" } className="tooltip-row">
        <b>  Keyword: </b>{props.value() + " ("
            + moment(props.start()).format('HH:mm:ss') + ")"}
    </li>

    // Push new tag
    lists.push(keywordTag);

    // Iterate each clips
    let clip = props.clips.find(clip => {
        // Find metadata with the same id
        let meta = clip.metadata().find(m => m.id() === props.id());
        // If meta is found, return true
        if (meta) return true;
        // Otherwise return false
        return false;
    });

    // If no clip was found, return empty spam
    if (!clip) return undefined;

    // For each clips iterate each profiles
    clip.profiles().forEach(profile => {
        // Push a row
        lists.push(
            <li key={"tooltip-row-" + props.id } className="tooltip-row">
                <b>  Id: </b>{profile.id};
                <b>  Nome: </b>{profile.customer_name};
                <b>  Note: </b>{
                    !profile.notes || profile.notes.length === 0
                        ? "Nessuna nota" : profile.notes
                    };
            </li>
        );
    });

    // If no elements was composed, return undefined
    if (lists.length === 0) return undefined;

    // Return the div with an UL and
    return <span style={{ margin: 0, padding: 0 }}>{lists}</span>;
}


/**
 * Move the metadata based on the time specy in data-metadata
 * @param  object props    The props of component
 * @return object          The object style for react render
 */
function moveMetadataInTimeline(props) {
    // Get the metadata data informations
    let metadataTime = props.start();

    // If metadata has time lower then timeline start
    if (metadataTime < props.timelineStart) {
        // Log an error
        Logger.write(
            'Metadata@moveMetadataInTimeline -> metadata has time lower then timeline start.',
            2,
            [metadataTime, props.timelineStart]
        );
        // Return false
        return {marginLeft: '0%'};
    }

    // If metadata has time greater then timeline end
    if (metadataTime > props.timelineEnd) {
        // Log an error
        Logger.write(
            'Metadata@moveMetadataInTimeline -> metadata has time greater then timeline end.',
            2,
            [metadataTime, props.timelineEnd]
        );
        // Return false
        return {marginLeft: '0%'};
    }

    // Calulate offset and remove half of metadata width
    let offset = _calculateTimelinePosition(
        props.timelineStart, props.timelineEnd, metadataTime
    );
    // Set attribute
    return {marginLeft: offset + '%'}
}

/**
 * Give the percentage current position in timeline
 * @param  integer taskStart        The unix timestamp of task start
 * @param  integer taskEnd          The unix timestamp of task end
 * @param  integer elementTime      The unix timestamp of the position
 * @return integer                  The percentage
 */
function _calculateTimelinePosition(taskStart, taskEnd, elementTime) {
    // Calculate the duration in unix time
    let duration = taskEnd - taskStart;
    // Calculate the element position in unix time
    let metadataPosition = elementTime - taskStart;
    // Get the position percentage
    let percentage = (100 * metadataPosition) / duration;
    // Return the value rounded
    return percentage;
}



// // NOTE: IS NOT USING!!! If your data prop is an object you can't just use "==="
// function arePropsEqual(prevProps, nextProps) {
//     return JSON.stringify(prevProps.raw) === JSON.stringify(nextProps.raw);
// }

export default Metadata;//, arePropsEqual);
