import moment from 'moment';
/**
 * Utility methods goes here
 * @type {Object}
 */
const Utility = {
    // If you need static data, call like this.data.myprop
    data: [],

    /**
     * Generates a GUID string.
     * @returns string The generated GUID.
     * @example af8a8416-6e18-a307-bd9c-f2c947bbb3aa
     * @author Slavik Meltser (slavik@meltser.info).
     * @link http://slavik.meltser.info/?p=142
     */
    uuid: function() {
        function _p8(s) {
            var p = (Math.random().toString(16)+"000000000").substr(2,8);
            return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    },

    /**
     * Search objects in deep inside object with jQuery
     * @param  {jQuery} obj     Object where search
     * @param  {String} key     Key to find
     * @param  {?} val          Value to find
     * @return {Array}          Array of results
     */
    search: function(obj, key, val) {
        var objects = [];
        for (var i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if (typeof obj[i] === 'object') {
                objects = objects.concat(this.search(obj[i], key, val));
            } else if (i === key && obj[key] === val) {
                objects.push(obj);
            }
        }
        return objects;
    },

    /**
     * Compare 2 array of object and get out the differences
     * @param  array arr1   The first array
     * @param  array arr2   The second array
     * @return array        All the differences
     */
    arrayOfObjectDiff: function(arr1, arr2, key) {
        key = key || 'id';
        // Filter elements only in A
        let onlyA = arr1.filter(
            item => !arr2.some(other => item[key] === other[key])
        );
        // Filter elements only in B
        let onlyB = arr2.filter(
            item => !arr1.some(other => item[key] === other[key])
        );
        // Made a single array with all the differences
        return  onlyA.concat(onlyB);
    },

    /**
     * Create the progress object with all keys and values
     * @param  integer percentage   The percentage (fraction)
     * @param  integer duration     The duration (in milliseconds)
     * @return object               The object with all data
     */
    createProgressFromPercentage: function(percentage, duration) {
        // Init progress object
        let progress = {};
        // NOTE: set 1 hardcoded
        progress['loaded'] = 1;
        // Calculate how much seconds are loaded
        progress['loadedSeconds'] = duration / 1000;
        // Set percentage 0->1 (fraction)
        progress['played'] = percentage;
        // Set playerd seconds
        progress['playedSeconds'] = duration * percentage / 1000;
        // Return object
        return progress;
    },

    /**
     * Create the progress object with all keys and values
     * @param  integer seconds      The seconds (in seconds)
     * @param  integer duration     The duration (in milliseconds)
     * @return object               The object with all data
     */
    createProgressFromSeconds: function(seconds, duration) {
        // Init progress object
        let progress = {};
        // NOTE: set 1 hardcoded
        progress['loaded'] = 1;
        // Calculate how much seconds are loaded
        progress['loadedSeconds'] = duration / 1000;
        // Set percentage 0->1 (fraction)
        progress['played'] = seconds / duration * 1000;
        // Set playerd seconds
        progress['playedSeconds'] = seconds;
        // Return object
        return progress;
    },

    /**
     * Return if is an empty object
     * @param  object   obj     The object to check
     * @return boolean          True if is empty
     */
    isEmpty: function(obj) {
        // If object is null or has no keys, its empty!
        return !obj || Object.keys(obj).length === 0;
    },

    /**
     * Detect if selection is enabled AND is valid.
     * With "scopes" define in which container shold be made a selection.
     * @param  array scopes Array of strings id of container for selection scope
     * @return boolean      True if is enabled && valid
     */
    isSelectionEnabled: function(scopes) {
        // If no selection is made
        if (typeof window.getSelection === "undefined"
            && typeof document.selection === "undefined"
        ) return false;

        // Get the start element
        let start = this.getSelectedElement(true);
        // Get the end element
        let end = this.getSelectedElement(false);

        // If start or end are not defined, ore no scopes are available, exit
        if (!start || !end || !scopes) return false;

        // Init start found as false
        let startFound = false;
        // Init end found as false
        let endFound = false;

        // Iterate each scopes
        for (var i = 0; i < scopes.length; i++) {
            // Get the scope element from which should attach event
            let node = document.getElementById(scopes[i]);

            // If node was not found, continue next
            if (!node) continue

            // If parent don't contain target event node
            if (node && node.contains(start)) {
                // Set found true
                startFound = true;
            }

            // If parent don't contain target event node
            if (node && node.contains(end)) {
                // Set found true
                endFound = true;
            }
        }

        // If start OR end are null, return false
        if (!startFound || !endFound) return false;

        // Take the range
        let range = window.getSelection().getRangeAt(0);

        // If start container is the same of end container
        if (range.startContainer === range.endContainer
            // AND start offset is the same of end offset, it meas it's a clik!
            && range.startOffset === range.endOffset) return false;

        // Is a selection!
        return true;
    },

    /**
     * Get the node element of selection
     * @param  boolean start    True if should return start element
     * @return HTMLElement      The element HTML of selection / null otherwise
     */
    getSelectedElement: function(start, sel) {
        // If getSelection is defined in browser
        if (typeof window.getSelection != "undefined") {
            // Get selection
            let selection = sel ? sel : window.getSelection();

            // If selection has no range
            if (selection.rangeCount < 1) {
                // Return
                return null;
            }

            // If we will the start element
            if (start) {
                // Return start element from range
                return selection.getRangeAt(0).startContainer.parentNode;
            }
            // Otherwise
            else {
                // Return the end element from range
                return selection.getRangeAt(0).endContainer.parentNode;
            }

        }
        // Otherwise check if document.selection is available
        else if (typeof document.selection != "undefined") {
            // Create range from selection
            let range = sel ? sel : document.selection.createRange();
            // Specify if is start or end element
           range.collapse(start);
           // Return the element
           return range.parentElement();
        }

        // Return null
        return null;
    },

    /**
     * Create a range between the start and end and set selection by simulate
     * user common browser highlight seletion
     * @param  HTMLElement start The start element HTML
     * @param  HTMLElement end   The end element HTML
     * @return boolean           True if all goes OK
     */
    setSelection: function(start, end) {
        // If no start or end are defined
        if (!start || !end) return false;

        // Create the range
        let range = document.createRange();
        // Se the start
        range.setStart(start.firstChild, 0);
        // Set the end
        range.setEnd(end.lastChild, 0);
        // Create selection
        let selection = window.getSelection();
        // Remove all ranges
        selection.removeAllRanges();
        // Add current range
        selection.addRange(range);
        // Return ture
        return true;
    },

    /**
     * Remove browser selection
     * @return void
     */
    removeSelection: function() {
        // If we have any selection
        if (window.getSelection) {
            // Chrome
            if (window.getSelection().empty) {
                // Clear selection
                window.getSelection().empty();
            }
            // Firefox
            else if (window.getSelection().removeAllRanges) {
                // Clear selection
                window.getSelection().removeAllRanges();
            }
        // IE?
        } else if (document.selection) {
            // Clear selection
            document.selection.empty();
        }
    },

    /**
     * Return if current profile has metadata missing
     * @param  object  profile  The profile to be checked
     * @return boolean          True if all mandatory metadata is filled
     */
    isMetadataMissing: function(profile) {
        // If profile is not defined, return true
        if (!profile) return true;

        // Define the metadata type requirements
        let requirements = [
            "title", "description", "related_duration", "tagging"
        ];
        // Init is missing metadata to FALSE
        let missing = false;

        // Iterate rach required types
        for (var i = 0; i < requirements.length; i++) {
            // If current metadata type is not required, continue next
            if (profile[requirements[i]+"_required"] < 1) continue;

            // If profile has not metadata, it means that is missing!
            if (!profile.metadata) {
                // Set missing to true
                missing = true;
                // Exit for circle, not need to continue
                break;
            }

            // if is tagging to be checked
            if (requirements[i] === "tagging") {
                // Check if current metadata is missing, don't check choice
                missing = this._metaMissingCheck(profile.metadata, "topic1")
                    || this._metaMissingCheck(profile.metadata, "topic2")
                    || this._metaMissingCheck(profile.metadata, "topic3");
            }
            // Otherwise
            else {
                // Check if current metadata is missing
                missing = this._metaMissingCheck(profile.metadata, requirements[i]);
            }

            // if is missing
            if (missing) break;
        }

        // Return if is missing or not
        return missing;
    },

    /**
     * Return if there is missing metadata insede profile
     * @param  array    metadata      The profile object
     * @param  string   requirement   The requirement metadata key
     * @return boolean                True if metadata is missing
     */
    _metaMissingCheck: function(metadata, requirement) {
        // Filter all metadata taking only the type that is checking
        let meta = this._findMetaFromType(metadata, requirement);

        // If no results was found OR the results found is empty/null
        if (!meta || this.isEmpty(meta.value)) return true

        // If is related duration AND it contains a "_", is not valid!
        if (requirement === "related_duration" && meta.value.includes('_')) {
            // Return true, is missing!
            return true;
        }

        // Return false
        return false;
    },

    /**
     * Calculate task duration
     * @param  object task      The current task
     * @return integer          The duration in ms
     */
    getTaskDuration: function(task) {
        // If task is not defined, return 0
        if (!task) return 0;
        // If date start or date end are undefined, return 0
        if (!task.start() && !task.end()) return 0;
        // Return duration
        return task.end() - task.start();
    },

    /**
     * Check if is running an action
     * @param  object  action   The action in progress
     * @return boolean          True if no action is running
     */
    noActionRunning: function(action) {
        // If action is undefined, or without mode or mode default
        return !action || !action.mode || action.mode === 'default';
    },

    /**
     * Load a temp variable from local storage
     * @param  string name  The name of variable to load
     * @return multy        The variable
     */
    loadPersistData: function(name) {
        // If element is in local storage
        return localStorage.getItem(name)
            // Parse it to object
            ? JSON.parse(localStorage.getItem(name))
            // Otherwise turn null
            : null;
    },

    /**
     * Save a temp variable into local storage
     * @param  string name  The name of variable to load
     * @return multy        The variable
     */
    savePersistData: function(name, value) {
        // If is empty value
        if (Utility.isEmpty(value)) {
            // Delete the var
            return this.deletePersistData(name);
        }

        // Set the item inside local storage
        return localStorage.setItem(
            name,
            JSON.stringify(value)
        );
    },

    /**
     * Remove a temp variable from local storage
     * @param  string name  The name of variable to load
     * @return void
     */
    deletePersistData: function(name) {
        // Remove item from local storage
        return localStorage.removeItem(name);
    },

    /**
     * Compare the rest url of an resource and the id.
     * If url has same rource id  return true
     * EX: "https://mimesi.willie.media/api/v1/tasks/123" and 123
     * @param  string url   The RESTful url of the resource
     * @param  integer id   The integer id of an resource
     * @return boolean      True if are the same (id resource and id passed)
     */
    resourceUrlComparer: function(url, id) {
        // Get the resource id from url
        let resourceId = this.getResourceIdFromUrl(url);
        // If resource id is defined
        return resourceId
            // Return if resouce id is the same of id passed
            ? parseInt(resourceId) === parseInt(id)
            // Otherwise is false, not equals
            : false;
    },


    /**
     * Cut the last ID of an url (taking care of trailing slash) and return it
     * @param  string url   The RESTful url
     * @return string       The id (string) or undefined
     */
    getResourceIdFromUrl: function(url) {
        // If url is null, return null
        if (!url) return null;
        // Check if has slash
        let slash = url.slice(url.length -1) === "/" ? true : false;
        // Split url by "/" char
        let parts = url.split('/');
        // Take the url resource id (if has slash or not)
        return slash ? parts[parts.length-2] : parts[parts.length-1];
    },

    /*
    |-------------------------------------------------------------------------|
    |                           PRIVATE METHODS                               |
    |-------------------------------------------------------------------------|
    */

    /**
     * Get metadata from type.
     * This method is used in isMetadataMissing to avoid eslint don't make
     * func within a loop
     * @param  object metadata      The metadata raw data
     * @param  string type          The type of metadata to find
     * @return object               The metadata found
     */
    _findMetaFromType: function(metadata, type) {
        // Return the metadata
        return metadata.find(meta => meta.type === type);
    },

    /**
    * Performs equality by iterating through keys on an object and returning false
    * when any key has values which are not strictly equal between the arguments.
    * Returns true when the values of all keys are strictly equal.
    */
    shallowEqual: function(objA, objB) {
        if (objA === objB) {
            return true;
        }

        if (typeof objA !== 'object' || objA === null ||
        typeof objB !== 'object' || objB === null) {
            return false;
        }

        var keysA = Object.keys(objA);
        var keysB = Object.keys(objB);

        if (keysA.length !== keysB.length) {
            return false;
        }

        // Test for A's keys different from B.
        var bHasOwnProperty = hasOwnProperty.bind(objB);
        for (var i = 0; i < keysA.length; i++) {
            if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
                return false;
            }
        }

        return true;
    },

    shallowCompare: function(instance, nextProps, nextState) {
        return (
            !this.shallowEqual(instance.props, nextProps) ||
            !this.shallowEqual(instance.state, nextState)
        );
    },

    /**
     * Format the input value and return a moment object, unix timestamp,
     * string date time or human readable date value
     * @param  string value     The value to be formatted
     * @param  string format    The format to be apply
     * @return multy            The value formatted
     */
    momentFormat: function(value, format) {
        // If is undefined
        if (!value) return undefined;
        // Create a moment object
        let obj = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSS');
        // If format is 'moment', return moment object
        if (format === 'moment') return obj.clone();
        // If format is 'raw', return the default value without formatting
        if (format === 'raw') return this.raw.start_datetime;
        // If format is 'human', return the default human value string
        if (format === 'human') return obj.format('DD/MM/YYYY HH:mm:ss');
        // If format is 'human_short', return the default human short value
        if (format === 'human_short') return obj.format('DD/MM HH:mm:ss');
        // If format is 'time', return only time value string
        if (format === 'time') return obj.format('HH:mm:ss');
        // If format is 'date', return only date value string
        if (format === 'date') return obj.format('DD/MM/YYYY');
        // If format is 'api', return value string formatting for API.
        if (format === 'api') return obj.format('YYYY-MM-DDTHH:mm:ss.SSS');
        // If format is 'date_short', return only date short value string
        if (format === 'date_short') return obj.format('DD/MM');
        // Otherwise return moment unix datetime as default
        return obj.valueOf();
    },

    /**
     * Sleep for and wait
     * @param  integer ms   The number of ms to wait
     * @return Promise      The promise
     */
    sleep: function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    /**
     * Check if have to disable the action button for current target selected
     * @param  string actionName    The name of the current action tested
     * @return boolean              True if have to disable the action button
     */
    disableButtonForCurrentSelection: function(actionName, selected) {
        // If no selection is enabled, don't disable nothing
        if (!selected || selected.length < 1) return false;

        // Get selected type
        let selectedType = selected[0].type;
        // If is not clip, don't disable nothing
        if (selectedType !== "clip") return false;

        // If is not one of the permitted action for clip selection
        if (actionName !== "set_clip_end"
            && actionName !== "delete_clip"
            && actionName !== "set_clip_start") {
            // The current option is not permitted, make it disabled!
            return true;
        }

        // The rest is OK, don't disable it
        return false;
    }
}


export default Utility;
