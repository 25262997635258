import React from 'react';

import Logger from '../classes/Logger.js';

const Pause = props => {
    // Convert start to unix time
    let start = props.start();
    // Convert end time to unix time
    let end = props.end();

    // Get id or create a ne one if necessart
    let id = props.id()
        ? props.id()
        : "unknow-" + Math.floor(Math.random() * 10001);
    // Get clip style
    let style = moveClipInTimeline(props);
    // Compose clip
    return <div key={id}
        className={"timeline-pause"}
        data-id={id}
        data-type="clip"
        data-time_start={start}
        data-time_end={end}
        style={style}>
    </div>;
};

/**
 * Move the clip based on the time specy in data-clip
 * @param  object props    The props of component
 * @return object          The object style for react render
 */
function moveClipInTimeline(props){
    // Get start time in unix timestamp
    let pauseStart = props.start();
    // Get end time in unix timestamp
    let pauseEnd = props.end();

    // If clip has time lower then timeline start
    if (pauseStart < props.clipStart) {
        // Log an error
        Logger.write(
            'Timeline@moveClipInTimeline -> clip has time lower then timeline start.',
            2,
            [props.clipStart, pauseStart]
        );
        // Return false
        return false;
    }

    // If clip has time greater then timeline end
    if (pauseEnd > props.clipEnd) {
        // Log an error
        Logger.write(
            'Pause@moveClipInTimeline -> clip has time greater then timeline end.',
            2,
            [props.clipEnd, pauseEnd]
        );
        // Return false
        return false;
    }

    // Calulate offset and remove half of metadata width
    let positionStart = _calculateTimelinePosition(
        props.clipStart, props.clipEnd, pauseStart
    );
    // Get the end point
    let positionEnd = _calculateTimelinePosition(
        props.clipStart, props.clipEnd, pauseEnd
    );
    // Get the clip width
    let width = positionEnd - positionStart;
    // Return attribute
    return {
        marginLeft: positionStart + '%',
        width: width + '%'
    };
}

/**
 * Give the percentage current position in timeline
 * @param  integer taskStart        The unix timestamp of task start
 * @param  integer taskEnd          The unix timestamp of task end
 * @param  integer elementTime      The unix timestamp of the position
 * @return integer                  The percentage
 */
function _calculateTimelinePosition(taskStart, taskEnd, elementTime) {
    // Calculate the duration in unix time
    let duration = taskEnd - taskStart;
    // Calculate the element position in unix time
    let metadataPosition = elementTime - taskStart;
    // Get the position percentage
    let percentage = (100 * metadataPosition) / duration;
    // Return the value rounded
    return percentage;
}



export default Pause;
