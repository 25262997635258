import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric'
import { initializeIcons } from '@uifabric/icons';
import { loadTheme } from 'office-ui-fabric-react';

import Layout from './layout/Layout';

import Login from './Login';
import Config from './classes/Config';
import Utility from './classes/Utility';

import './styles/App.css';

// Load custom theme
loadTheme({
  palette: {
    themePrimary: Config.get("CUSTOM_THEME_PRIMARY", '#e67e22'),
    themeLighterAlt: Config.get("CUSTOM_THEME_LIGHTER_ALT", '#fef9f5'),
    themeLighter: Config.get("CUSTOM_THEME_LIGHTER", '#fbe9d9'),
    themeLight: Config.get("CUSTOM_THEME_LIGHT", '#f7d6b8'),
    themeTertiary: Config.get("CUSTOM_THEME_TERTIARY", '#f0af75'),
    themeSecondary: Config.get("CUSTOM_THEME_SECONDARY", '#e98c3b'),
    themeDarkAlt: Config.get("CUSTOM_THEME_DARK_ALT", '#cf711f'),
    themeDark: Config.get("CUSTOM_THEME_DARK", '#ae5f1a'),
    themeDarker: Config.get("CUSTOM_THEME_DARKER", '#814613'),
    neutralLighterAlt: Config.get("CUSTOM_NEUTRAL_LIGHTER_ALT", '#faf9f8'),
    neutralLighter: Config.get("CUSTOM_NEUTRAL_LIGHTER", '#f3f2f1'),
    neutralLight: Config.get("CUSTOM_NEUTRAL_LIGHT", '#edebe9'),
    neutralQuaternaryAlt: Config.get("CUSTOM_NEUTRAL_QUATERNARY_ALT", '#e1dfdd'),
    neutralQuaternary: Config.get("CUSTOM_NEUTRAL_QUATERNARY", '#d0d0d0'),
    neutralTertiaryAlt: Config.get("CUSTOM_NEUTRAL_TERTIARY_ALT", '#c8c6c4'),
    neutralTertiary: Config.get("CUSTOM_NEUTRAL_TERTIARY", '#c2c2c2'),
    neutralSecondary: Config.get("CUSTOM_NEUTRAL_SECONDARY", '#858585'),
    neutralPrimaryAlt: Config.get("CUSTOM_NEUTRAL_PRIMARY_ALT", '#4b4b4b'),
    neutralPrimary: Config.get("CUSTOM_NEUTRAL_PRIMARY", '#333333'),
    neutralDark: Config.get("CUSTOM_NEUTRAL_DARK", '#272727'),
    black: Config.get("CUSTOM_BLACK", '#1d1d1d'),
    white: Config.get("CUSTOM_WHITE", '#ffffff'),
  }
});

// Create a custom route that check if is authenticated and location is login
const LoginRoute = ({ component: Component, ...rest }) => {
    // Add your own authentication on the below line.
    const isLoggedIn = rest.isAuthenticated && localStorage.getItem('token');

    return (
        <Route {...rest}
            render={props =>
                isLoggedIn ? (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                ) : (
                    <Component {...props} />
                )
            }
            />
    )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
    // Add your own authentication on the below line.
    const isLoggedIn = rest.isAuthenticated;

    return (
        <Route {...rest}
            render={ props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
            />
    )
}



class App extends Component {

    /**
     * Load token from Redux store and return if logged or not
     * @return object       The view data taken from changeView redux reducer
     */
    _isLoggedIn = () => {
        // If exist viewData AND exist viewData.data, AND data si not null
        return (this.props.token && !Utility.isEmpty(this.props.token))
            // Return token
            ? this.props.token
            // Otherwise return null
            : null;
    }

    _initFavicon = () => {
        const favicon = document.getElementById("favicon");
        favicon.href = Config.get('CUSTOM_FAVICON_URL');
    }

    render() {
        // Get if is logged in or not
        let isLogged = this._isLoggedIn();
        // Init all icons form microsoft cdn
        initializeIcons();
        // Load correct favicon
        this._initFavicon();

        // Return the view initialized
        return (
            <Fabric className="App">
                <BrowserRouter>
                    <Switch>
                        <LoginRoute path="/login" component={Login} isAuthenticated={isLogged} />
                        <PrivateRoute path="/" component={Layout} isAuthenticated={isLogged} />
                    </Switch>
                </BrowserRouter>
            </Fabric>
        );
    }
}

// Take the redux store
const mapStateToProps = state => {
    // From redux store, take only login action
    const token = state.login.token;
    // Return the token of login action redux and set it to component props
    return { token };
};

export default connect(mapStateToProps)(App);
