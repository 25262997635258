import React from 'react';
// Import FabricUI compoents
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton} from 'office-ui-fabric-react/lib/Button';
// Import classes
import Api from '../../classes/Api.js';
import Logger from '../../classes/Logger.js';

class DeleteClipModal extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'DeleteClipModal'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            hideDialog: true,
            isDraggable: false,
            word: undefined
        }
    }

    /**
     * Toggle dialog
     * @param  Object word      The data object to be sent
     * @return void
     */
    toggle = (word) => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            word: word,
            loading: false
        });
    }

    /**
     * Close the current modal
     * @return void
     */
    close = () => {
        // Update state
        this.setState({
            hideDialog: !this.state.hideDialog,
            word: {},
            loading: false
        });
        // Handle action dismiss
        this.props.handleActionDismiss();
    }

    /**
     * Save the data passed to the modal
     * @return void
     */
    save = () => {
        // Log into debug
        Logger.write("DeleteClipModal@save -> start:", 0);
        // Save the error
        this.setState({loading: true});
        // Save the scope inside me var
        let me = this;
        // Compose API data to be sent to API
        let data = this._composeAPIData();
        // Start axios call to add a new keyword
        let axios = Api.deleteClip(data.id);
        // Manage response
        axios.then((response) => {
            // Log in debug
            Logger.write('DeleteClipModal@save -> axios success.', 0);
            // Handle action response
            me.props.handleActionResponse(data);
            // Close the current modal
            me.close();
        })
        // Manage error
        .catch((error) => {
            // Log in error
            Logger.write('DeleteClipModal@save -> got an error.', 3, error);
            // Close the current modal
            me.close();
        });
    }

    /**
     * Compose the data to send to API
     * @return integer      The id of the clip to be deleted
     */
    _composeAPIData = () => {
        // TODO: if there are 2 or any clips, select one. For now take the 1st
        return this.state.word.clips[0].raw;
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.close}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Elimina Clip',
                    subText: 'I dati verrano inviati al server.',
                }}
                modalProps={{
                    titleAriaId: 1,
                    subtitleAriaId: 2,
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                    dragOptions: this.state.isDraggable ? this._dragOptions : undefined
                }}
            >
            <DialogFooter>
                <PrimaryButton onClick={this.save} text={ this.state.loading ? "Caricamento..." : "Salva"} disabled={this.state.loading} />
                <DefaultButton onClick={this.close} text="Annulla" />
            </DialogFooter>
        </Dialog>
        );
    }
}

// Export default component to be accessible in other components
export default DeleteClipModal;
