import moment from 'moment';
import Utility from './Utility.js';
import Logger from './Logger.js';

const WordUtil = {
    /*
    |-------------------------------------------------------------------------|
    |                               GETTER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Get the text of a word
     * @param  HTMLEntity node   HTML node
     * @return string            The text of the word
     */
    getText: function(node) {
        // If node is defined, return the text trimmed, otherwise return null
        return node ? node.textContent.trim() : null;
    },

    /**
     * Get dataset from HTML element
     * @param  HTMLEntity node    HTML node
     * @return object             The dataset object
     */
    getData: function(node) {
        // If node is defined, return it, otherwise return null
        return node ? node.dataset : null;
    },

    /**
     * Get data attribute from HTML node dataset
     * @param  HTMLEntity node          HTML node
     * @param  string     attribute     The string attribute to return
     * @return multy                    The value of the data-attribute
     */
    getDataAttribute: function(node, attribute) {
        // Get dataset
        let data = this.getData(node);
        // If dataset is defined return the attribute, otherwise return null
        return data ? data[attribute] : null;
    },

    /**
     * Get word type
     * @param  HTMLEntity node          HTML node
     * @return string                   Get word id
     */
    getId: function(node) {
        // Return word id
        return this.getDataAttribute(node, 'id')
            ? parseInt(this.getDataAttribute(node, 'id'))
            : null;
    },

    /**
     * Get the clips from data attribute
     * @param  HTMLEntity node          HTML node
     * @return array                    Array of clips
     */
    getClips: function(node) {
        // Return word clips
        let attr = this.getDataAttribute(node, 'clips');
        // If is found return the parsing, otherwise return null
        return attr ? JSON.parse(attr) : [];
    },

    /**
     * Get the metadata from data attribute
     * @param  HTMLEntity node          HTML node
     * @return array                    Array of metadata
     */
    getMetadata: function(node) {
        // Return word metadata
        let attr = this.getDataAttribute(node, 'metadata');
        // If is found return the parsing, otherwise return null
        return attr ? JSON.parse(attr) : [];
    },

    /**
     * Get time of a word and format it
     * @param  HTMLEntity node      HTML node
     * @param  string     format    The format: unix(def) | human | moment
     * @param  string     attribute The key attribute
     * @return multy                The date formatted
     */
    getTime: function(node, format, attribute) {
        // If no attribute is defined, set datetime as default
        attribute = attribute || 'datetime';
        // Get the value in timestamp
        let value = this.getDataAttribute(node, attribute);

        // If value is not defined
        if (!value) {
            // Return null
            return null;
        }

        // Parse integer
        value = parseInt(value);
        // get moment object
        let momentObj = moment(value);

        // If have moment format
        if (format === 'moment') {
            // Return moment instance
            return momentObj;
        }
        // If have human format
        else if (format === 'human') {
            // Return data human formatted
            return momentObj.format('DD/MM/YYYY hh:mm:ss');
        }

        // Return unix timestamp as default
        return momentObj.valueOf();
    },

    /**
     * Get start time of a clip and format it
     * @param  HTMLEntity node      HTML node
     * @param  string     format    The format: unix(def) | human | moment
     * @return multy                The date formatted
     */
    getStartTime: function(node, format) {
        // Return the stat time formatted
        return this.getTime(node, format, 'time_start');
    },

    /**
     * Get end time of a clip and format it
     * @param  HTMLEntity node      HTML node
     * @param  string     format    The format: unix(def) | human | moment
     * @return multy                The date formatted
     */
    getEndTime: function(node, format) {
        // Return the end time formatted
        return this.getTime(node, format, 'time_end');
    },

    /**
     * Get override time of a clip and format it
     * @param  HTMLEntity node      HTML node
     * @param  string     format    The format: unix(def) | human | moment
     * @return multy                The date override formatted
     */
    getOverrideTime: function(node, format) {
        // Return the end time formatted
        return this.getTime(node, format, 'overrideDatetime');
    },

    /**
     * Calculate the second of player of a word
     * @param  integer wordTime     word time in milliseconds
     * @param  integer taskStart    start in millisecond
     * @return integer              The seconds of current word
     */
    calculateWordSeconds: function(wordTime, taskStart) {
        // Create unixtimestamp
        let time = moment(wordTime).valueOf();
        // Crteate moment
        let taskTime = moment(taskStart);
        // Get seconds
        return moment.duration(
            moment(time).diff(taskTime)
        ).seconds();
    },

    /**
     * If current word is one or more metadata, return them
     * @param  object word          The word object
     * @param  array metadata       The metadata array
     * @return array                The metadata douns
     */
    getMetadataForWord: function(word, metadata) {
        // Get time in moment
        let wordTime = moment(word.time);
        // Return the type
        return this.getMetadataForWordTime(wordTime, metadata);
    },

    /**
     * Return the metadata for the time object passed.
     * @param  moment wordTime      The word object
     * @param  array metadata       The metadata array
     * @return array                The metadata results
     */
    getMetadataForWordTime: function(wordTime, metadata) {
        // Init vars
        let metaStartTime, metaEndTime;
        // Init found array
        let matched = [];
        let mode = '[)';
        // Iterate each clips
        metadata.forEach((meta) => {
            // Get time in moment
            metaStartTime = meta.start('moment');
            metaEndTime = meta.end('moment');
            // Check if is a metadata that start AND and are the same
            mode = (meta.type() === 'thumbnail' || meta.type() === 'tag')
                // If so, extern are consider true
                ? '[]'
                // Otherwise the end are not considered
                : '[)';

            // If time is between it, include begin extreme but not end:
            // this because the end time of metadata is the start + length,
            // this value is the same of the very next word after metadata.
            // So, if you want to select correct you will need to exclude
            // the end extreme. This was used for multi words, but now I adopt
            // it for signle words too.
            if (wordTime.isBetween(metaStartTime, metaEndTime, null, mode)) {
                // Break
                matched.push(meta);
            }
        });

        // Return the matched array
        return matched;
    },

    /**
     * If current word is a clip return an array of clips ids where the word
     * is found
     * @param  object word       The word object
     * @param  array clips       The clips array
     * @return array             Array of clip ids
     */
    getClipsForWord: function(word, clips) {
        // Get time in moment
        let wordTime = moment(word.time);
        // Retrun the clips by word time
        return this.getClipsForWordTime(wordTime, clips);
    },

    /**
     * Return the clips for the time object passed.
     * @param  moment wordTime      The word object
     * @param  array  clips         The clips array
     * @return array                The clips results
     */
    getClipsForWordTime: function(wordTime, clips) {
        // Init vars
        let clipStartTime, clipEndTime;
        // Init found array
        let matched = [];

        // Iterate each clips
        clips.forEach((clip) => {
            // Get time in moment
            clipStartTime = clip.start('moment');
            clipEndTime = clip.end('moment');

            // If time is between it, include extreme
            if (wordTime.isBetween(clipStartTime, clipEndTime, null, '[]')) {
                // Break
                matched.push(clip);
            }
        });

        // Return the type
        return matched;
    },

    /**
     * Return the work currently played.
     * First, try taking all words highlighted (the current second rounded),
     * then take more accurate with ms.
     * Cloud be that no word is highlighted (silece or music produce time but
     * no words, no highlighting), to archive this, iterate X seconds before
     * and after played time to get the highlighted word.
     * @param  float seconds    The seconds of player
     * @param  boolean end      If true, take the time+length of the end word
     * @return HTMLEntity       The HTML node word
     */
    getWordCurrentlyPlayed: function(seconds, end) {
        // Log in verbose
        Logger.write('WordUtil@getWordCurrentlyPlayed -> start.', 0, seconds, end);
        // Get player seconds
        const playerSeconds = seconds;
        // Take care of seconds + decimals
        const accurate = parseFloat(seconds);

        // If seconds is null, return undefined, otherwise parse int
        seconds = seconds !== 0 && !seconds ? undefined : parseInt(seconds);
        // Get the box
        let box = document.querySelector('#transcription-box');
        // Get the played word
        let nodes = box.querySelectorAll('.transcription-word.highlighted');
        // Convert nodelist to array
        let words = nodes ? Array.from(nodes) : [];

        // Init vars
        let word, diff, lastDiff = undefined;

        // Iterate each word highlighted, if we have...
        words.forEach(w => {
            // If end is true
            diff = end
                // Take the played accurate
                ? Math.abs(accurate - (
                    // and remove the word accurate + word length
                    parseFloat(w.dataset.secondsAccurate)
                    + parseFloat(w.dataset.secondsLength)
                ))
                // Itherwose take the time difference between player and word
                : Math.abs(accurate - parseFloat(w.dataset.secondsAccurate));

            // If the difference is minor or is never initialized
            if (diff < lastDiff || lastDiff === undefined) {
                // Take care of the diff
                lastDiff = diff;
                // Take care of the word
                word = w;
            }
        })

        // If word was found, return it
        if (word) return this.overrideWordTime(word, playerSeconds);

        // Set the max number of correction
        let max = 20;
        // Init corrction to 0
        let correction = 0;

        // Iterate max 20 times to search the last word played
        while (!word && correction < max) {
            // Take the word of SECS +/- correction
            word = box.querySelector(
                "[data-seconds='" + (seconds - correction) + "']"
            ) || box.querySelector(
                "[data-seconds='" + (seconds + correction) + "']"
            );
            // Update correction
            correction = correction + 1;
        }

        // Return the word
        return this.overrideWordTime(word, playerSeconds);
    },

    /**
     * Take time in float 3 decimal and assign value to word.
     * Don't override, just add it with "override-" word.
     * @param  HTMLEntity word      The word entity
     * @param  float seconds        The played seconds from player
     * @return HTMLEntity           The entity with the new values
     */
    overrideWordTime: function(word, seconds) {
        // Put seconds accurate passed by player
        word.dataset['overrideSecondsAccurate'] = seconds.toFixed(3);
        // Put seconds passed by player
        word.dataset['overrideSeconds'] = parseInt(seconds);
        // Calculate difference between the original seconds and the player
        const diff = parseFloat(
            word.dataset['secondsAccurate']
        ) - parseFloat(
            word.dataset['overrideSecondsAccurate']
        );
        // Remove diff from time to get current right time
        word.dataset['overrideDatetime'] = word.dataset['datetime'] - (diff*1000);
        // Return word
        return word;
    },

    /*
    |-------------------------------------------------------------------------|
    |                               OTHER METHODS                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Compose the word object for API call
     * @param  HTMLEntity node      HTML node
     * @return object     word      The word object
     */
    composeWordObject: function(target) {
        // Init word
        let word = {};
        // Get unix time
        word['datetime'] = this.getTime(target, 'unix');
        // If has override datetime, return it
        word['overrideDatetime'] = this.getOverrideTime(target, 'unix');
        // Get value
        word['value'] = this.getText(target);
        // Get clips
        word['clips'] = this.getClips(target);
        // Get metadata
        word['metadata'] = this.getMetadata(target);
        // Retunr the word composed
        return word;
    },

    /**
     * Return true if is a metadata, false if is a clip or others.
     * @param  HTMLEntity node          HTML node
     * @return boolean                  True if is a metadata
     */
    isMetadata: function(node) {
        // Load metadata
        let metadata = this.getMetadata(node);
        // Return if metadata are defined
        return metadata && metadata.length > 0
            // Then return true
            ? true
            // Otherwise return false
            : false
    },

    /**
     * Return true if is a clip, false otherwise.
     * @param  HTMLEntity node          HTML node
     * @return boolean                  True if is a clip
     */
    isClip: function(node) {
        // Load clips
        let clips = this.getClips(node);
        // Return if clips are defined
        return clips && clips.length > 0
            // Then return true
            ? true
            // Otherwise return false
            : false;
    },

    /**
     * Check if almost 1 targe clips are marked as selected.
     * Iterate selected targets and check if clips are same id of targetClips
     * IDEA: when you will have to check more selected type, you can check the
     * selectedClips' type and return a enum of type selected. In word creation
     * you can check the selection type and set the correct style
     * @param  array targetClips        Array of object clips
     * @param  array selectedTargets    Array of HTMLElement targets
     * @return boolean                  Return true if target is selected
     */
    isTargetSelected: function(targetClips, selectedTargets) {
        // Init is selected var
        let isSelected = false;
        // If word clips or selection clips are null, return
        if (!targetClips || !selectedTargets) return isSelected;

        // Iterate each word clip
        for (var i = 0; i < targetClips.length; i++) {

            // Iterate each selected ids
            for (var j = 0; j < selectedTargets.length; j++) {
                // Get clips of current selecte element
                let clips = this.getClips(selectedTargets[j].target);

                // Iterate each clips object
                for (var x = 0; x < clips.length; x++) {

                    // If word is a selected one
                    if (targetClips[i].id() === clips[x].raw.id) {
                        // Set to true
                        isSelected = true;
                        // break
                        break;
                    }
                }
            }
        }

        // Return if is selected
        return isSelected;
    },

    /**
     * Retrieve the word HTML element from a click event on the timeline
     * @param  Event        ev      The event react
     * @param  TaskModel    task    The task model object
     * @return HTMLElement          The HTML word element
     */
    getWordTargetFromTimelineClick: function(ev, task) {
        // If ev or task are not defined, return
        if (!ev || !task) return undefined;
        // Get the target of click
        let target =  ev.target;

        // If target has badge class
        if (target.classList.contains('badge-clip-profiles')) {
            // It means that should take the parent element, or rather timeline
            target = target.parentElement;
        }

        // Get the timeline
        let timeline = document.querySelector('#timeline');
        // Get rect coords from event target
        let rect = target.getBoundingClientRect();
        // Get the left position of x axis
        let position = ev.clientX - rect.left;
        // position : timeline.clientWidth = X : 100
        let percentage = parseFloat((position * 100 / timeline.clientWidth));
        // Init element margin
        let elementMargin = target.style.marginLeft.replace(/%/i, '') || 0;
        // Add element margin to event target margin
        percentage = (percentage + parseFloat(elementMargin)) / 100;
        // Get duration task
        let duration = task.end() - task.start();
        // Create progress object
        let progress = Utility.createProgressFromPercentage(percentage, duration);
        // Get seconds time to be highlighted
        let time = parseInt(progress.playedSeconds);
        // Add the played seconds to task datetime millisec
        let datetime = task.start('moment').clone().add(
            progress.playedSeconds, 'seconds'
        // And get the millisecs where user pressed
        ).valueOf();

        // Init tried
        let tried = 0;
        // Init elements
        let elements = [];

        // Untile element are empty AND had tried at max 5 times
        while (elements.length < 1 && tried < 5) {
            // Query elements with time + X seconds
            elements = document.querySelectorAll(
                "[data-seconds='" + (time + tried) + "']"
            );

            // If elements are 0
            if (elements.length === 0) {
                // Query elements with time - X seconds
                elements = document.querySelectorAll(
                    "[data-seconds='" + (time - tried) + "']"
                );
            }

            // Increase tried
            tried++;
        }

        // If elements are null, exit
        if (!elements) return null;

        // Init vars
        let difference, currentDifference, word;

        // Iterate elements
        elements.forEach((el, i) => {
            // Make the absolute value of the difference between the exact
            // datetime pressed and the word datetime.
            currentDifference = Math.abs(
                parseInt(el.dataset.datetime) - datetime
            );

            // If the new difference is minor, we are closer!
            if (!difference || currentDifference < difference) {
                // Store the element as target
                word = el;
                // Update difference
                difference = currentDifference;
            }
        });

        // Return the target
        return word;
    }
}

export default WordUtil
