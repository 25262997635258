import React from 'react';
import moment from 'moment';
import ReactPlayer from 'react-player';

import { DefaultButton } from 'office-ui-fabric-react';

import Api from '../../../classes/Api.js';
import Logger from '../../../classes/Logger.js';
import Config from '../../../classes/Config.js';


class PlayerClipModalView extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'PlayerClipModalView',
        stepSecondsAmount: parseInt(
            Config.get('DEFAULT_CLIP_PLAYER_STEP_SECONDS_AMOUNT', 5)
        )
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            playbackRate: 1,
            isPlaying: false,
            progressInterval: 200,
            mediaUrl: null,
            urlLoading: false,
            loaded: false
        }

        // If selected clip si defined
        if (this.props.selectedClip) {
            // Set media url and load video
            this._setMediaUrl();
        }
    }

    /**
     * Set the player video url
     * @return void
     */
    _setMediaUrl = () => {
        // If task is not downloaded or player already downloaded
        if (!this.props.taskLoaded || this.state.playerLoaded) return;

        // If is already in loading process, return
        if (this.state.urlLoading) return;

        // Set that is in loading process
        // this.setState({urlLoading: true});
        // Load transcription
        let medias = this.props.task.getAttachmentsByType('task_media');

        // If no transcriptions was found, return null
        if (!medias || medias.length < 1) return null;

        // TODO: NOTE: Take only the first media for now: call get attachment
        Api.getAttachment(medias[0].id).then(
            // Manage success
            (response) => {
                // Log in debug the call
                Logger.write('PlayerClipModalView@_setMediaUrl -> success.', 0, response);
                // Get the data from response
                let data = response['data'];
                // Compose the url, if data was not found, take an empty string
                let url = !data ? ''
                    // otherwise concut media url + file path
                    : Config.get('BASE_URL_WILLIE_MEDIA') + "/"
                        + data['file_path'];
                // Update state
                this.setState({
                    mediaUrl: url,
                    playerLoaded: true,
                    urlLoading: false
                });
            }
        )
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Player@_setMediaUrl -> error.', 3, err);
                // Update state
                this.setState({
                    mediaUrl: '',
                    playerLoaded: true,
                    urlLoading: false
                });
            }
        );
    }

    /*
    |-------------------------------------------------------------------------|
    |                               PLAYER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Toggle status var to start play player
     * @return void
     */
    play = () => {
        // Save the play status in state
        this.setState({ isPlaying: true });
    }

    /**
     * Toggle status var to pause player
     * @return void
     */
    pause = () => {
        // Save the pause status in state
        this.setState({ isPlaying: false });
    }

    /**
    * Called when media starts playing
    * @param  Event ev     [The event of player]
    * @return void
    */
    onPlayerStart = (ev) => {
        // Write log
        Logger.write('onPlayerStart:', 0, this.refs.clipPlayer);
        // Play
        this.play();
    }

    /**
    * Called when media starts or resumes playing after pausing or buffering
    * @param  Event ev     [The event of player]
    * @return void
    */
    onPlayerPlay = (ev) => {
        // Write log
        Logger.write('onPlayerPlay:', 0, this.refs.clipPlayer);
        // Play
        this.play();
    }

    /**
    * Called when media is paused
    * @param  Event ev     [The event of player]
    * @return void
    */
    onPlayerPause = (ev) => {
        // Write log
        Logger.write("onPlayerPause", 0, this.refs.clipPlayer);
        // Pause player
        this.pause();
    }

    /**
    * Called when media finishes playing
    * @param  Event ev     [The event of player]
    * @return void
    */
    onPlayerEnded = (ev) => {
        // Write log
        Logger.write("onPlayerEnded", 1, this.refs.clipPlayer);
        // Pause player
        this.pause();
    }

    /**
     * Callback containing played and loaded progress as a fraction,
     * and playedSeconds and loadedSeconds in seconds
     * @param  Event ev     [The event of player]
     * @return void
     */
    onPlayerProgress = (ev) => {

        // If currently played seconds is greater or equals then clip end, pause
        if (ev.playedSeconds >= this.state.clipEndSecond) this.pause();

        // Set the start in percentage
        ev['clipStartPercentage'] = this.state.clipStartPercentage;
        // Set the end in percentage
        ev['clipEndPercentage'] = this.state.clipEndPercentage;
        // Run handle progress change
        this.props.handleProgressChange('player', ev);
    }

    /**
     * Event of player when is ready (after seeking or initialized)
     * @param  Event ev     Ready event
     * @return void
     */
    onPlayerReady = (ev) => {
        // If is not already loaded
        if (!this.state.loaded) {
            // Set loaded to true
            this.setState({ loaded: true });
            // Set player to start clip
            this._seekToClipStart();
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                          CONTROL BUTTON ACTIONS                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Click action on play button in control buttons
     * @param  Event ev     OnClick action event
     * @return void
     */
    onPlayButtonClicked = (ev) => {
        // If currently played seconds is greater or equals then clip end
        if (!ev || ev.playedSeconds >= this.state.clipEndSecond) return false;
        // Play player
        this.play();
    }

    /**
     * Click action on pause button in control buttons
     * @param  Event ev     OnClick action event
     * @return void
     */
    onPauseButtonClicked = (ev) => {
        // Play player
        this.pause();
    }

    /**
     * Click action on stop button in control buttons
     * @param  Event ev     OnClick action event
     * @return void
     */
    onStopButtonClicked = (ev) => {
        // Pause player
        this.pause();
        // Seek to start
        this._seekToClipStart();
    }

    /**
     * Click action on rewind button in control buttons
     * @param  Event ev     OnClick action event
     * @return void
     */
    onRewindButtonClicked = (ev) => {
        // HACK: DON'T KNOW WHY, if is called directly  this.refs.player.seekTo
        // the player is null. Assign to var and use it
        let player = this.refs.clipPlayer;
        // Calculate next seconds amount
        let sec = player.getCurrentTime() - this.props.stepSecondsAmount;

        // If seconds are minor then the clip start
        if (sec < this.state.clipStartSecond) {
            // Pause player
            this.pause();
            // Exit
            return false;
        }

        // Get the percentage where to seek
        let seekTo = sec/this.state.taskDurationSecond;
        // Seek to percentage
        player.seekTo(seekTo);
    }

    /**
     * Click action on fast forward button in control buttons
     * @param  Event ev     OnClick action event
     * @return void
     */
    onFastForwardButtonClicked = (ev) => {
        // HACK: DON'T KNOW WHY, if is called directly  this.refs.player.seekTo
        // the player is null. Assign to var and use it
        let player = this.refs.clipPlayer;
        // Calculate next seconds amount
        let sec = player.getCurrentTime() + this.props.stepSecondsAmount;

        // If seconds are minor then the clip start
        if (sec > this.state.clipEndSecond) {
            // Pause player
            this.pause();
            // Exit
            return false;
        }

        // Get the percentage where to seek
        let seekTo = sec/this.state.taskDurationSecond;
        // Seek to percentage
        player.seekTo(seekTo);
    }

    /**
     * Seek player to start clip
     * @return void
     */
    _seekToClipStart = () => {
        // HACK: DON'T KNOW WHY, if is called directly  this.refs.player.seekTo
        // the player is null. Assign to var and use it
        let player = this.refs.clipPlayer;
        // Get moment clip start
        let clipStart = this.props.selectedClip.start('moment');
        // Get moment clip end
        let clipEnd = this.props.selectedClip.end('moment');
        // Get moment task
        let taskStart = this.props.task.start('moment');
        // Calculate difference to know the seconds of clip start
        let clipSecStart = moment.duration(
            clipStart.diff(taskStart)
        ).asSeconds();
        // Calculate differences to know the seconds of clip end
        let clipSecEnd = moment.duration(
            clipEnd.diff(taskStart)
        ).asSeconds();
        // Get the duration of the clip
        let duration = moment.duration(
            this.props.task.end('moment').diff(taskStart)
        ).asSeconds();

        // Get the percentage where to seek
        let seekTo = clipSecStart/duration;

        // Store start seconds and end seconds
        this.setState({
            clipStartSecond: clipSecStart,
            clipStartPercentage: seekTo,
            clipEndPercentage: (clipSecEnd/duration),
            clipEndSecond: clipSecEnd,
            taskDurationSecond: duration
        })

        // Seek to
        player.seekTo(seekTo);

        // // COMBAK: seems like seek don't work because video is not ready loaded.
        // // Search if there is an event to know if player is loaded and use it.
        // setTimeout(function () {
        //     // Seek to percentage
        //     player.seekTo(seekTo);
        // }, 2000);
    }


    /**
    * Render component
    * @return {} []
    */
    render() {

        // Return component
        return (
            <div hidden={this.props.hidden}>
                <div className='clip-player-container'>
                    <div className='clip-player-wrapper'>
                        <ReactPlayer ref="clipPlayer" url={this.state.mediaUrl}
                            controls={false}
                            onStart={this.onPlayerStart}
                            onPlay={this.onPlayerPlay}
                            onPause={this.onPlayerPause}
                            onEnded={this.onPlayerEnded}
                            onProgress={this.onPlayerProgress}
                            onReady={this.onPlayerReady}
                            playbackRate={this.state.playbackRate}
                            progressInterval={this.state.progressInterval}
                            playing={this.state.isPlaying}
                            />
                    </div>
                </div>
                <div className="control-container">
                    <DefaultButton
                        toggle
                        checked={this.state.isPlaying}
                        text={this.state.isPlaying ? 'Pause' : 'Play'}
                        iconProps={this.state.isPlaying ? { iconName: 'Pause' } : { iconName: 'Play' }}
                        onClick={this.state.isPlaying ? this.onPauseButtonClicked : this.onPlayButtonClicked }
                        allowDisabledFocus
                        />
                        <DefaultButton
                            text={'-' + this.props.stepSecondsAmount + ' sec'}
                            iconProps={{ iconName: 'Rewind' }}
                            onClick={ this.onRewindButtonClicked }
                            allowDisabledFocus
                            />
                        <DefaultButton
                            text={'+' + this.props.stepSecondsAmount + ' sec'}
                            iconProps={{ iconName: 'FastForward' }}
                            onClick={ this.onFastForwardButtonClicked }
                            allowDisabledFocus
                            />
                        <DefaultButton
                            text='Stop'
                            iconProps={{ iconName: 'Stop' }}
                            onClick={ this.onStopButtonClicked }
                            allowDisabledFocus
                            />
                </div>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default PlayerClipModalView;
