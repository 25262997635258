import React from 'react';
import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react/lib/Shimmer';
import '../styles/TaskShimmer.css'

class TaskShimmer extends React.Component {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'TaskShimmer'
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true
        }
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        return (
            <div className='fixed-full-size-overlay' hidden={this.props.hidden}>
                <Shimmer
                    className="shimmer-task-overlay"
                    shimmerElements={[
                        { type: ShimmerElementType.line, height: 50, width: '100%' },
                    ]}
                    />
                <Shimmer
                    className="shimmer-task-overlay"
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: '2%' },
                        { type: ShimmerElementType.line, height: 300, width: '20%' },
                        { type: ShimmerElementType.gap, width: '5%' },
                        { type: ShimmerElementType.line, height: 250, width: '37%' },
                        { type: ShimmerElementType.gap, width: '2%' },
                        { type: ShimmerElementType.line, height: 250, width: '15%' },
                        { type: ShimmerElementType.gap, width: '2%' },
                        { type: ShimmerElementType.line, height: 250, width: '15%' },
                        { type: ShimmerElementType.gap, width: '2%' },
                    ]}
                    />
                <Shimmer
                    className="shimmer-task-overlay"
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: '27%' },
                        { type: ShimmerElementType.line, height: 16, width: '71%' },
                        { type: ShimmerElementType.gap, width: '2%' },
                    ]}
                    />
                <Shimmer
                    className="shimmer-task-overlay"
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: '27%' },
                        { type: ShimmerElementType.line, height: 16, width: '71%' },
                        { type: ShimmerElementType.gap, width: '2%' },
                    ]}
                    />
                <Shimmer
                    className="shimmer-task-overlay"
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: '27%' },
                        { type: ShimmerElementType.line, height: 16, width: '71%' },
                        { type: ShimmerElementType.gap, width: '2%' },
                    ]}
                    />
                    <Shimmer
                        className="shimmer-task-overlay"
                        shimmerElements={[
                            { type: ShimmerElementType.gap, width: '27%' },
                            { type: ShimmerElementType.line, height: 16, width: '51%' },
                            { type: ShimmerElementType.gap, width: '22%' },
                        ]}
                        />
                <Shimmer
                    className="shimmer-task-overlay"
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: '27%' },
                        { type: ShimmerElementType.line, height: 16, width: '71%' },
                        { type: ShimmerElementType.gap, width: '2%' },
                    ]}
                    />
                <Shimmer
                    className="shimmer-task-overlay"
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: '27%' },
                        { type: ShimmerElementType.line, height: 16, width: '71%' },
                        { type: ShimmerElementType.gap, width: '2%' },
                    ]}
                    />
                <Shimmer
                    className="shimmer-task-overlay"
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: '27%' },
                        { type: ShimmerElementType.line, height: 16, width: '31%' },
                        { type: ShimmerElementType.gap, width: '42%' },
                    ]}
                    />
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default TaskShimmer;

// const el = document.body
// ReactDOM.render(<TaskShimmer name='John' />, el)
