import React, { Component } from 'react';
// import { BrowserRouter as Route } from 'react-router-dom';
import { Label } from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';

import { connect } from 'react-redux'
import { login } from '../redux/actions'
import Api from './classes/Api.js';
import Logger from './classes/Logger.js';
import Config from './classes/Config.js';

import './styles/Login.css';

class Login extends Component {

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            inputUsername: "",
            inputPassword: "",
            errorUsername: null,
            errorPassword: null,
            logoUrl: Config.get('CUSTOM_LOGIN_LOGO_URL', "/images/login_logo_ij.png"),
            backgroudUrl: Config.get('CUSTOM_LOGIN_BACKGROUND_URL', "/images/login_bg_ij.jpg")
        }
    }

    /**
     * Call API and do login
     * @return {}   [Do login and redirect if success]
     */
    doLogin = () => {
        // Take email
        let errorUsername = this.state.inputUsername.trim().length < 1 ? "Inserisci un username valido" : null;
        let errorPassword = this.state.inputPassword.trim().length < 1 ? "Inserisci la password" : null;
        // Save the error
        this.setState({
            errorUsername: errorUsername,
            errorPassword: errorPassword
        });

        // If an error is defined
        if (errorUsername || errorPassword) {
            // Return false
            return false;
        }

        // Compose credentials
        let credentials = {
            'username': this.state.inputUsername,
            'password': this.state.inputPassword
        }

        // Save this scope
        let me = this;

        // Call get task from API
        Api.login(credentials).then(
            // Manage success
            (response) => {
                // Log in debug the call
                Logger.write('Login@doLogin -> success.', 0, response);
                // Compose token for base auth
                let token = btoa(
                    credentials['username'] + ":" + credentials['password']
                );
                // Dispatches action to login redux
                this.props.login({
                    'token': token,
                    'user': response.data
                });
            }
        )
        // Catch the error
        .catch(
            // Manage error
            (err) => {
                // Log in error level
                Logger.write('Login@doLogin -> error.', 3, err);
                // Get error object or an empty one
                err = err && err.response ? err.response : {};
                // Get status code
                let code = err.status;
                // If has error data, and has the error data msg from API
                let message = err.data && err.data.message
                    // Take it
                    ? err.data.message
                    // Otherwise, take the status text
                    : err.statusText;
                // Compose error message: if has a message
                message = message
                    // Print with status code
                    ? code + ": " + message
                    // Otherwise return generic error
                    : "An error occurred.";

                // Save the error
                me.setState({
                    errorPassword: message
                });
            }
        );
    }

    render() {
        // Return the view initialized
        return (
            <div className="login-backgroud" style={{
                    backgroundImage: "url(" + this.state.backgroudUrl + ")"
                }}>
                <Dialog
                    hidden={false}
                    onDismiss={this._closeDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: null,
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 750, textAlign: 'center' } }
                    }}
                    >
                    <img
                        src={this.state.logoUrl}
                        alt ="Logo"
                        className = "login-img-logo"
                        />
                    <form className="login-form">
                        <TextField label="Username" placeholder="foobar123"  autoComplete="username" errorMessage={this.state.errorUsername} required onChange={(ev, val) => {this.setState({inputUsername:val});}}/>
                        <br></br>
                        <TextField label="Password" type="password" placeholder="Secret Password" autoComplete="password" errorMessage={this.state.errorPassword} required onChange={(ev, val) => {this.setState({inputPassword:val});}}/>
                    </form>
                    <DialogFooter>
                        <Label className="override-style power-by-label" disabled>Powered by Infojuice Willie</Label>
                        <PrimaryButton onClick={this.doLogin} text="Login" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default connect(
  null,
  { login }
)(Login);
