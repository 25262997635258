import React from 'react';
import ReactDOM from 'react-dom';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { IconButton } from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';

import Utility from '../../../classes/Utility.js';
import Logger from '../../../classes/Logger.js';
import Config from '../../../classes/Config.js';
import Metadata from '../../../classes/models/Metadata.js';
import MetadataTag from '../../Metadata.js';
import Pause from '../../Pause.js';

class ProfileClipModalView extends React.PureComponent {

    /**
    * Set default props
    * @type {Object}
    */
    static defaultProps = {
        name: 'ProfileClipModalView',
    }

    /**
    * Component constructor
    * @param {Object} props [Component props]
    */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Load profiles
        let profiles = [...this.props.profiles];
        // Iterate each profile
        let valid = 0;
        // Iterate each profiles
        profiles.forEach(profile => {
            // return if meta data is not missing
            return !Utility.isMetadataMissing(profile)
                // Add 1 to valid
                ? (valid = valid + 1)
                // Otherwise nothing
                : null;
        });
        // Check if is valid, do that to know if enable save button
        let isValid = valid === profiles.length;

        // Set default state
        this.state = {
            visible: true,
            value: '',
            clip: this.props.clip,
            profiles: profiles,
            saveEnabled: isValid,
            loading: this.props.loading,
            pauseStartTime: undefined
        }
    }

    /**
     * Check if active profile is OK or has missing data
     * @param  integer  id      The id of profile to be checked
     * @return boolean          True if is OK
     */
    _isActiveProfileOK = (id) => {
        // Get the profile from id
        let profile = this.state.profiles.find(profile => profile.id === id);
        // Return the opposit of is missing data?
        return !Utility.isMetadataMissing(profile);
    }

    /**
     * Update task data with new profile data
     * @param  integer profileId    The ID of the profile you are editing
     * @param  string field         The name of the field you're editing
     * @param  string value         The value to attribute to the field
     * @return void
     */
    _saveProfileMetadata = (profileId, field, value) => {

        // If profile or field was not specified, return undefined
        if (!profileId || !field) return undefined;

        // Assign proiles to update var
        let update = this.state.profiles;
        // Get the clip index
        let profile = update.find(
            // If current profile has the same id of the searched one
            profile => profile.id === profileId
        );
        // If profile was not found
        if (!profile) return undefined;
        // If has not metadata, create the array of metadata
        if (!profile.metadata) profile.metadata = [];
        // Find first metadata of the same type
        let meta = profile.metadata.find(meta => meta.type === field);

        // If metadata was found, return that value, otherwise undefined
        if (!meta) {
            // Create a new meta object
            meta = {'type': field, 'value': value};
            // Push into array
            profile.metadata.push(meta);
        }
        // Otherwise
        else {
            // Set value into metadata
            meta['value'] = value;
        }

        // Update store
        this.setState({profiles: [...update]});
    }

    /**
     * Save the metadata of a clip that user is typing
     * @param  string field     Metadata type (field name)
     * @param  string value     Metadata value
     * @return void
     */
    _saveClipMetadata = (field, value) => {
        // If field was not specified, return undefined
        if (!field) return undefined;
        // Assign proiles to update var
        let update = this.props.clip;
        // If profile was not found
        if (!update) return undefined;
        // Find first metadata of the same type
        let meta = update.metadata().find(el => el.type() === field);

        // If metadata was found, return that value, otherwise undefined
        if (!meta) {
            // Create a new meta object
            meta = {'type': field, 'value': value};
            // Push into array
            update.addMetadata(meta);
        }
        // Otherwise
        else {
            // Set value into metadata
            meta.raw['value'] = value;
            // Update metadata
            update.editMetadata(meta.raw)
        }

        // Update store
        this.setState({clip: {...update}});
    }

    /**
     * Load profile metadata from state
     * @param  integer  profileId   The id of the profile
     * @param  string   field       The field name
     * @return string               Value
     */
    _loadProfileMetadata = (profileId, field) => {
        // If profile or field was not specified, return undefined
        if (!profileId || !field) return undefined;
        // Get the clip index
        let profile = this.state.profiles.find(
            // If current profile has the same id of the searched one
            profile => profile.id === profileId
        );
        // If profile was not found
        if (!profile) return undefined;
        // If has not metadata
        if (!profile.metadata) return undefined;
        // Find first metadata not null
        let meta = profile.metadata.find(meta => meta.type === field);
        // If metadata was found, return that value, otherwise undefined
        return meta ? meta.value : undefined;
    }

    /**
     * Check if have to enable save button
     * @return void
     */
    _enableSaveButton = (profiles) => {
        // Init var enable
        let valid = 0;
        // If profiles is specified use them, otherwise read the state
        profiles = profiles ? profiles : this.state.profiles;

        // Iterate each profile
        profiles.forEach(profile => {
            // return if meta data is not missing
            return !Utility.isMetadataMissing(profile)
                // Add 1 to valid
                ? (valid = valid + 1)
                // Otherwise nothing
                : null;
        });

        // Set state
        this.setState({
            // If all profiles are enabled
            saveEnabled : valid === profiles.length
        })
    }

    /*
    |-------------------------------------------------------------------------|
    |                      TWO WAY DATA BINDING METHODS                       |
    |-------------------------------------------------------------------------|
    */

    /**
     * Update timeline progress in percentage
     * @param  integer progress     [Progress in percentage]
     * @return void
     */
    updateTimelineProgress = (progress) => {
        // If progress is not defined return false
        if (!progress) return false;

        // Store progress
        this.setState({progress: progress});

        // Calculate the start moment of timeline rapported to clip (clip
        // start percentage) and not to TASK (played is a percentage of whole
        // task, not only of the clip)
        let realStart = progress.played - progress.clipStartPercentage;
        // Calculate the duration of the clip not of the whole task
        let realDuration = progress.clipEndPercentage - progress.clipStartPercentage;
        // Get the value percentage "0.XXXX"
        let percentage = realStart/realDuration;
        // Calculate percentage
        percentage = (percentage) * 100;
        // Get dom
        const node = ReactDOM.findDOMNode(this);

        // Get child nodes
        if (node instanceof HTMLElement) {
            // Find progressbar
            let child = node.querySelector('#clip-progress-bar');

            // If child is found, set new progress
            if (child) child.style.marginLeft = percentage + "%";
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                             ACTION EVENT HANDLE                         |
    |-------------------------------------------------------------------------|
    */

    /**
     * Handle click on "Previous" button
     * @return void
     */
    _handlePreviousButtonClicked = () => {
        // Save the error
        this.setState({loading: false});
        // Call parent method and pass selected clip
        this.props.handlePreviousViewChange(this.state.profiles);
    }

    /**
     * Handle click on "Next" button
     * @return void
     */
    _handleNextButtonClicked = () => {
        // Save the error
        this.setState({loading: true});
        // Call parent method and pass selected clip
        this.props.handleNextViewChange(this.state.selectedClip);
    }

    /**
     * Handle field choice group change
     * @param  Event    ev     The change event
     * @param  string   val    The field value
     * @return void
     */
    handleProfileChoiceFieldChange = (ev, val) => {
        // Get value
        let value = val.key;
        // Get profile id
        let profileId = val.data.profileId;
        // Get data field
        let field = val.data.field;
        // Save metadata in state
        this._saveProfileMetadata(profileId, field, value);
        // Check if have to enable save button
        this._enableSaveButton();
    }

    /**
     * Handle field text change (title or description)
     * @param  Event    ev     The change event
     * @param  string   val    The field value
     * @return void
     */
    handleProfileFieldChange = (ev, val) => {
        // Get target object
        let htmlObject = ev.target;
        // Get profile id
        let profileId = htmlObject.dataset.profileId
            ? parseInt(htmlObject.dataset.profileId)
            : -1;
        // Get profile field
        let field = htmlObject.dataset.profileField;
        // Save metadata in state
        this._saveProfileMetadata(profileId, field, val);
        // Check if have to enable save button
        this._enableSaveButton();
    }

    /**
     * Change action of textfield typing change
     * @param  Event ev     The React native event
     * @param  string val   The value typed
     * @return void
     */
    handleClipMetadataFieldChange = (ev, val) => {
        // Get target object
        let htmlObject = ev.target;
        // Get profile field
        let field = htmlObject.dataset.metadataField;
        // Save metadata in state
        this._saveClipMetadata(field, val);
    }

    /**
     * Handle button click on description paste.
     * @param  Event    ev     The change event
     * @param  string   val    The field value
     * @return void
     */
    handleDescriptionPasteButtonClick = (ev) => {
        // Get target object
        let htmlObject = ev.currentTarget;
        // Get profile id
        let profileId = htmlObject.dataset.profileId
            ? parseInt(htmlObject.dataset.profileId)
            : -1;
        // Get text
        let text = this.props.clip.textFromTranscription(
            this.props.transcription,
            this.props.task
        );
        // Save the text inside description
        this._saveProfileMetadata(profileId, 'description', text);
        // Check if have to enable save button
        this._enableSaveButton();
    }

    /**
     * Click action on stop button in control buttons
     * @param  Event ev     OnClick action event
     * @return void
     */
    _onCreatePauseButtonClicked = (ev) => {
        // Init value
        let value = undefined;

        // If pauseStartTime is null
        if (!this.state.pauseStartTime) {

            // If progress is defined in state, it means that is already been
            // played somethings
            if (this.state.progress) {
                // Get start task
                let start = this.props.task.start('moment');
                // Get start seconds
                let seconds = this.state.progress.playedSeconds;
                // Add start + seconds
                value = start.add(seconds, 'seconds');
            }
            // User click on button but never player
            else {
                // Set value of start clip
                value = this.props.clip.start('moment');
            }

            // Set start time in state
            this.setState({
                pauseStartTime: Utility.momentFormat(value, 'api')
            });
            // Return true
            return true;
        }

        // If progress never been initialized, return, can't set end!
        if (!this.state.progress) return false;

        // Get start task
        let end = this.props.task.start('moment');
        // Get start seconds
        let seconds = this.state.progress.playedSeconds;
        // Add start + seconds
        value = end.add(seconds, 'seconds');
        // Get the end time
        let pauseEndTime = Utility.momentFormat(value, 'api');

        // Create metadata
        let metadata = {
            type: 'clip_pause',
            start_datetime: this.state.pauseStartTime,
            end_datetime: pauseEndTime,
            value: "Pausa Clip"
        }

        // Add new metadata
        this.props.clip.addMetadata(metadata);
        // Set clear time in state
        this.setState({ pauseStartTime: undefined });
    }

    /*
    |-------------------------------------------------------------------------|
    |                               RENDER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    _composeDefaultItems = () => {
        // Load the number of time meadata to create
        let timeMetadataNumber = parseInt(
            Config.get('DEFAULT_TIME_TAG_NUMBER', 4)
        );
        // Init items array
        let generated = [];
        // Init item
        let item, time, seconds;
        // Calculate task duration
        let duration = (this.props.clip.end() - this.props.clip.start())/1000;

        // Iterare the number of metadata number
        for (var i = 0; i <= timeMetadataNumber; i++) {
            // Get the timeline start
            let start = this.props.clip.start('moment');
            // Re-init item
            item = {};

            // If we are at the last element
            if (i === timeMetadataNumber) {
                // Set task end to avoid approximation out of timeline end
                time = this.props.clip.end('moment');
            } else {
                // Calculate seconds to add
                seconds = Math.round((duration/timeMetadataNumber)*i);
                // Add seconds
                time = start.add(seconds, 'seconds');
            }

            // Create an id
            item['uuid'] = 'time-metadata-' + i;
            // Set time type
            item['type'] = 'time';
            // Set the time of the metadata
            // NOTE: MAYBE ADD .SSS to format to avoid missing millisecs
            item['start_datetime'] = time.format("YYYY-MM-DD HH:mm:ss.SSS");
            // Set the time of the metadata
            item['value'] = time.format("HH:mm:ss");
            // Create a metadata with item data
            let meta = Metadata.init(item);

            // Try creating
            try {
                // Generate metada tag
                let metadata = <MetadataTag key={meta.id()} {...meta} timelineStart={this.props.clip.start()} timelineEnd={this.props.clip.end()}/>
                // Push metadata tag
                generated.push(metadata);
            } catch (e) {
                Logger.write('ProfileClipModalView@_composeDefaultItems -> got an error.', 3, e);
            }
        }

        // Add the progress bar indicator
        item = <IconButton primary
                    id="clip-progress-bar"
                    key="clip-progress-bar"
                    iconProps={{ iconName: 'CaretSolidUp' }}
                    title="Progress" ariaLabel="Progress"
                    />
            // Push progress bar
        generated.push(item);
        // REturn the list of item
        return generated;
    }

    /**
     * Compose the tab items to be edited
     * @param  array profiles   The array with items
     * @return JSX              The tab
     */
    _composePivotItems = (profiles) => {
        // Init header
        let header = "";
        // Iterate each profile
        return profiles.map( (profile) => {
            // Compose header name if customer name is defined
            header = profile.customer_name
                // If name has more then 15 chars, cut 12 and attach elipsis
                ? (profile.customer_name.length > 15
                        ? profile.customer_name.slice(0, 12).trim() + "..."
                        : profile.customer_name)
                // Otherwise, use profile id
                : ("Profilo #"+profile.id);
            // Create tab item
            return <PivotItem key={profile.id} id={profile.id}
                headerText={header}
                onRenderItemLink={this._customRenderer}>
                <Label className="modal-label title">ID:&nbsp;</Label><Label className="modal-label">{profile.id}</Label><br />
                <Label className="modal-label title">Nome:&nbsp;</Label><Label className="modal-label">{profile.customer_name ? profile.customer_name : ("Profilo #"+profile.id)}</Label><br />
                {this._composePivotItemBodyElements(profile)}
            </PivotItem>;
        });
    }

    /**
     * [_composePivotItemBodyElements description]
     * @param  {[type]} profile [description]
     * @return {[type]}         [description]
     */
    _composePivotItemBodyElements = (profile) => {
        // Init body
        let body = [];

        // If title is required
        if (profile.title_required > 0) {
            // Add title text box to body
            body.push(<TextField key={'title_' + profile.id}
                    ref={'title_' + profile.id}
                    data-profile-id={profile.id}
                    data-profile-field="title" label="Titolo"
                    placeholder="Titolo Profilo" autoComplete="title"
                    errorMessage={this.state.errorTitle}
                    required={profile.title_required > 0}
                    onChange={this.handleProfileFieldChange}
                    value={this._loadProfileMetadata(profile.id, 'title')}/>)
        }

        // If description is required
        if (profile.description_required > 0) {
            // Add description text box to body
            body.push(<TextField key={'description_' + profile.id}
                ref={'description_' + profile.id}
                data-profile-id={profile.id}
                data-profile-field="description"
                label="Descrizione"
                className="profile-edit-description"
                placeholder="Descrizione Profilo"
                autoComplete="descrizione"
                multiline rows={3}
                errorMessage={this.state.errorDescription}
                required={profile.description_required > 0}
                onChange={this.handleProfileFieldChange}
                value={this._loadProfileMetadata(profile.id, 'description')}/>)
            // Add Icon paste clip text
            body.push(<IconButton key={'button_idcon_' + profile.id} iconProps={{ iconName: 'Paste' }} data-profile-id={profile.id} title="Paste" ariaLabel="Paste" className="profile-edit-description-paste-button" onClick={this.handleDescriptionPasteButtonClick}></IconButton>)
        }

        // If tagging is required
        if (profile.tagging_required > 0) {
            // Add tagging topic 1 TextField to body
            body.push(<TextField key={'topic1_' + profile.id}
                ref={'topic1_' + profile.id}
                data-profile-id={profile.id}
                data-profile-field="topic1"
                label="Topic 1"
                placeholder="Inserisci valori separati da ;"
                autoComplete="topic1"
                errorMessage={this.state.errorTagging}
                required={profile.tagging_required > 0}
                onChange={this.handleProfileFieldChange}
                value={this._loadProfileMetadata(profile.id, 'topic1')}/>)
            // Add tagging topic 2 TextField to body
            body.push(<TextField key={'topic2_' + profile.id}
                ref={'topic2_' + profile.id}
                data-profile-id={profile.id}
                data-profile-field="topic2"
                label="Topic 2"
                placeholder="Inserisci valori separati da ;"
                autoComplete="topic2"
                errorMessage={this.state.errorTagging}
                required={profile.tagging_required > 0}
                onChange={this.handleProfileFieldChange}
                value={this._loadProfileMetadata(profile.id, 'topic2')}/>)
            // Add tagging topic 3 TextField to body
            body.push(<TextField key={'topic3_' + profile.id}
                ref={'topic3_' + profile.id}
                data-profile-id={profile.id}
                data-profile-field="topic3"
                label="Topic 3"
                placeholder="Inserisci valori separati da ;"
                autoComplete="topic3"
                errorMessage={this.state.errorTagging}
                required={profile.tagging_required > 0}
                onChange={this.handleProfileFieldChange}
                value={this._loadProfileMetadata(profile.id, 'topic3')}/>)
            // Add tagging sentiment choice group to body
            body.push(<ChoiceGroup
                key={'sentiment_' + profile.id}
                ref={'sentiment_' + profile.id}
                required={profile.tagging_required > 0}
                onChange={this.handleProfileChoiceFieldChange}
                selectedKey={this._loadProfileMetadata(profile.id, 'sentiment') || "neu"}
                errorMessage={this.state.errorTagging}
                label="Sentiment"
                options={[
                    {
                        key: 'pos',
                        iconProps: { iconName: 'Emoji2' },
                        data: {
                            profileId: profile.id,
                            field: "sentiment"
                        },
                        text: 'Positivo'
                    },
                    {
                        key: 'neu',
                        iconProps: { iconName: 'EmojiNeutral' },
                        data: {
                            profileId: profile.id,
                            field: "sentiment"
                        },
                        text: 'Neutro'
                    },
                    {
                        key: 'neg',
                        iconProps: { iconName: 'Sad' },
                        data: {
                            profileId: profile.id,
                            field: "sentiment"
                        },
                        text: 'Negativo',
                    }
                ]}
                />)
        }

        // If tagging is required
        if (profile.related_duration_required > 0) {
            // Add related duration to body
            body.push(<MaskedTextField key={'related_duration_' + profile.id}
                mask="99:59:59"
                maskFormat={{'5':/[0-5]/, '9':/[0-9]/}}
                ref={'related_duration_' + profile.id}
               data-profile-id={profile.id}
               data-profile-field="related_duration"
               label="Durata"
               placeholder="Durata Profilo"
               autoComplete="related_duration"
               errorMessage={this.state.errorRelatedDuratoin}
               required={profile.related_duration_required > 0}
               onChange={this.handleProfileFieldChange}
               value={this._loadProfileMetadata(profile.id, 'related_duration')}/>)
       }

       // Return body
       return body;
    }

    /**
     * Compose the default tab of clip metadata
     * @return JSX The tab of clip metadata
     */
    _composeDefaultPivotItem = () => {
        // Return pivot item
        return <PivotItem key="default_clip_pivot_item"
            id="default_clip_pivot_item"
            headerText="Metadati Clip"
            onRenderItemLink={this._customDefualtItemRenderer}>
            <Label className="modal-label">Metadati clip</Label><br />
            <TextField key={'program'}
                ref={'program'}
                data-metadata-field="program"
                label="Programma"
                placeholder="Programma"
                autoComplete="program"
                onChange={this.handleClipMetadataFieldChange}
                value={this.props.clip.getMetadataFromType('program') ? this.props.clip.getMetadataFromType('program').value() : undefined}/>
            <TextField key={'anchorman'}
                ref={'anchorman'}
                data-metadata-field="anchorman"
                label="Nome Conduttore"
                placeholder="Nome Conduttore"
                autoComplete="anchorman"
                onChange={this.handleClipMetadataFieldChange}
                value={this.props.clip.getMetadataFromType('anchorman') ? this.props.clip.getMetadataFromType('anchorman').value() : undefined}/>
            <TextField key={'journalists'}
                ref={'journalists'}
                data-metadata-field="journalists"
                label="Servizio di"
                placeholder="Nomi giornalisti separati da ;"
                autoComplete="journalists"
                onChange={this.handleClipMetadataFieldChange}
                value={this.props.clip.getMetadataFromType('journalists') ? this.props.clip.getMetadataFromType('journalists').value() : undefined}/>
            <TextField key='interviewee'
                ref='interviewee'
               data-metadata-field="interviewee"
               label="Intervento di"
               placeholder="Nomi intervistati separati da ;"
               autoComplete="interviewee"
               onChange={this.handleClipMetadataFieldChange}
               value={this.props.clip.getMetadataFromType('interviewee') ? this.props.clip.getMetadataFromType('interviewee').value() : undefined}/>
        </PivotItem>;
    }

    /**
     * Compose the default tab of clip metadata
     * @return JSX The tab of clip metadata
     */
    _composePauseClipPivotItem = () => {
        // Load all pauses
        const pauses = this.props.clip.getMetadatasFromType('clip_pause');
        // Save me var scope
        let me = this;
        // Return pivot item
        return <PivotItem key="pause_clip_pivot_item"
            id="pause_clip_pivot_item"
            headerText="Pause Clip"
            onRenderItemLink={this._customPauseClipItemRenderer}>
            <Label className="modal-label">Modifica le pause della clip</Label><br />
           <div className="clip-timeline-instance-box">
                <div className="clip-timeline-instance"
                    data-start={this.props.clip.start()}
                    data-end={this.props.clip.end()}
                    >
                    {this._composeDefaultItems()}
                    {pauses.map((pause, i) => me._buildPuaseTimelineElement(pause, i))}
                </div>
           </div>
           <div>
               <DefaultButton
                   toggle
                   checked={this.state.pauseStartTime}
                   text={this.state.pauseStartTime ? 'Crea Pausa (Fine)' : 'Crea Pausa (Inizio)'}
                   iconProps={this.state.pauseStartTime ? { iconName: 'PaddingLeft' } : { iconName: 'PaddingRight' }}
                   onClick={this._onCreatePauseButtonClicked }
                   allowDisabledFocus
                   />
                    <div className="clip-pause-list">
                       {pauses.map((pause, i) => me._buildPuaseListElement(pause, i))}
                   </div>
                </div>
        </PivotItem>;
    }

    /**
     * Build pause timeline elements
     * @param  Metadata pause     Metadata instance of type "clip_pause"
     * @param  integer  i         The index while creating
     * @return JSX                An Pause JSX component
     */
    _buildPuaseTimelineElement = (pause, i) => {
        return <Pause
            key={"pause-" + (pause.id() || i)}
            {...pause}
            clipStart={this.props.clip.start()}
            clipEnd={this.props.clip.end()}/>;
    }

    /**
     * Build pause list element used to remove already added pauses compoents
     * in clip timeline
     * @param  Metadata pause     The metadata instance of type "clip_pause"
     * @param  integer  i         The index while creating
     * @return JSX                An Pause JSX component
     */
    _buildPuaseListElement = (pause, i) => {
        // Create an id for react key
        let id = pause.id() || "clip-pause-" + i;
        // IDEA: If is a new pause, we don't have any ids really unique.
        // To get ride of that compose an base64 made by values joined together
        let base64 = btoa(pause.start('api') + "-" + pause.end('api'));
        // Return the list element
        return <div key={id}>
            <IconButton iconProps={{ iconName: 'Delete' }}
                title="Delete" ariaLabel="Delete"
                className="clip-pause-delete-button"
                onClick={this._handleDeletePuseClipButtonClicked}
                data-base64={base64}>
                #{(i+1)} Pausa {pause.start('time')} - {pause.end('time')}
            </IconButton>
        </div>;
    }

    /**
     * Handle click on delete pause button from list of pause elements
     * @param  Event ev     The click event
     * @return void
     */
    _handleDeletePuseClipButtonClicked = (ev) => {
        // Get dataset value
        let base64 = ev.currentTarget.dataset.base64;
        // Remove paused metadata
        this.props.clip.removePauseMetadata(base64);
        // Force update please!
        this.forceUpdate();
        // Return true
        return true;
    }

    /**
     * Return a correct icon for the passed tab
     * @param  JSX link                 The tab
     * @param  void defaultRenderer     Fabric UI render method
     * @return JSX                      The icon
     */
    _customRenderer = (link, defaultRenderer) => {
        return (
          <span className="tab-index-name-max-length">
            {this._isActiveProfileOK(link.id)
                ? <Icon iconName="Accept" className="color-success"/>
                : <Icon iconName="Warning12" className="color-warning"/>
            } &nbsp;
            {defaultRenderer(link)}
          </span>
        );
    }

    /**
     * Return a correct icon for the default clip metadata tab
     * @param  JSX link                 The tab
     * @param  void defaultRenderer     Fabric UI render method
     * @return JSX                      The icon
     */
    _customDefualtItemRenderer = (link, defaultRenderer) => {
        return (
          <span className="tab-index-name-max-length">
            <Icon iconName="TripleColumnEdit" className="color-success"/>&nbsp;
            {defaultRenderer(link)}
          </span>
        );
    }

    /**
     * Return a correct icon for the default pause clip creation tab
     * @param  JSX link                 The tab
     * @param  void defaultRenderer     Fabric UI render method
     * @return JSX                      The icon
     */
    _customPauseClipItemRenderer = (link, defaultRenderer) => {
        return (
          <span className="tab-index-name-max-length">
            <Icon iconName="Rename" className="color-success"/>&nbsp;
            {defaultRenderer(link)}
          </span>
        );
    }

    /**
    * Render component
    * @return {} []
    */
    render() {
        const { profiles } = this.state;
        return (
            <div hidden={this.props.hidden}>
                <Pivot>
                    {this._composeDefaultPivotItem()}
                    {this._composePauseClipPivotItem()}
                    {this._composePivotItems(profiles)}
                </Pivot>
                <DialogFooter>
                    <DefaultButton text="Indietro" onClick={this._handlePreviousButtonClicked}/>
                    <PrimaryButton text={ this.state.loading ? "Caricamento..." : "Salva"} disabled={!this.state.saveEnabled || this.state.loading} onClick={this._handleNextButtonClicked}/>
                </DialogFooter>
            </div>
        );
    }
}

// Export default component to be accessible in other components
export default ProfileClipModalView;

// const el = document.body
// ReactDOM.render(<ProfileClipModalView name='John' />, el)
