import Logger from './Logger';

const Config = {

    /**
     * Load a configuration from env
     * @param  string name      Name of configuration to be loaded
     * @param  multy def        The default value to be returned
     * @return multy            The value loaded, use default if null
     */
    get: function(name, def) {

        // If name is not defined
        if (!name || !name.trim()) {
            // Write warning log
            Logger.write('Config@load -> No name config passed!', 2);
            // REturn null
            return null;
        }

        // Define config name
        name = 'REACT_APP_' + name.toUpperCase();
        // Return if config is defined
        return process.env[name]
            // Return it
            ? process.env[name]
            // Otherwise return default
            : def;
    }
}

export default Config
