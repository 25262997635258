import sha1 from 'sha1';

import Metadata from './Metadata.js';
import Clip from './Clip.js';

import Logger from '../Logger.js';
import Utility from '../Utility.js';
import WordUtil from '../WordUtil.js';


const Task = {

    /**
     * Task data downloaded from server
     * @type object
     */
    raw: {},

    /**
     * Init object and return self instance
     * @param  object data  Data downloaded form server
     * @return object       Self object
     */
    init: function(data) {
        // Log in debug the call
        Logger.write('Task@init -> call start.', 0, data);
        // Store data into raw value
        this.raw = data;
        // Return self instance
        return {...this};
    },

    /*
    |-------------------------------------------------------------------------|
    |                         GETTER/SETER METHODS                            |
    |-------------------------------------------------------------------------|
    */

    /**
     * Return task id
     * @return integer    Task id
     */
    id: function() {
        // Return task id
        return this.raw.id ? parseInt(this.raw.id) : undefined;
    },

    /**
     * Return all attachments
     * @return array    All task's attachments
     */
    attachments: function() {
        // Return attachemnts
        return this.raw.attachments;
    },

    /**
     * Return attachments by type
     * @return array    All task's attachments of that type
     */
    getAttachmentsByType: function(type) {
        // Load all attachments
        let attachments = this.attachments();
        // Search for all taks json inside attachments
        return Utility.search(attachments, 'type_repr', type);
    },

    /**
     * Return one clip
     * @return object    One task clip from id
     */
    clip: function(id) {
        // Get all the clips and find the clip with the same id
        return this.clips().find(clip => clip.id() === id);
    },

    /**
     * Return all clips
     * @return array    All task's clips
     */
    clips: function() {
        // If clips are empty
        return !this.raw.clips
            // Return empty array
            ? []
            // Otherwise, map each element
            : this.raw.clips.map(clip => {
                // Return clip object
                return Clip.init(clip);
            });
    },

    /**
     * Get all profiles
     * @param  boolean unique   True if has to return the unique array
     * @return array            Array of profiles from clips
     */
    profiles: function(unique) {
        // Get all clips
        let profiles = [];

        // Iterate each clips
        for (var i = 0; i < this.clips().length; i++) {
            // Concat profiles
            profiles = profiles.concat(this.clips()[i].profiles());
        }

        // If unique is specified
        return unique
            // Return an unique array of object
            ? [...new Map(profiles.map(item => [item['id'], item])).values()]
            // Otherwise return all
            : profiles;
    },

    /**
     * Return clips from a word passed
     * @return array    All task's clips
     */
    getClipsFromWord: function(word) {
        // Load all object clip from word HTML
        let objects = WordUtil.getClips(word);
        // Filter all clips
        return this.clips().filter(
            // Take one, and return if is inside objects array (check the ids)
            clip => objects.some(obj => obj.raw.id === clip.id())
        );
    },

    /**
     * Add a clip to raw data of task
     * @param  object newClip   The raw data of the clip to be added
     * @return boolean          True if added
     */
    addClip: function(newClip) {
        // If newClip is undefined
        if (!newClip) return false;
        // If stored clips are empty, init them
        if (!this.raw.clips) this.raw.clips = [];
        // If raw already contains newClip clip
        if (this.raw.clips.some(clip => clip.id === newClip.id)) return true;
        // Update clips with new one
        this.raw.clips = [...this.raw.clips, newClip];
        // Return true
        return true;
    },

    /**
     * Remove a clip to raw data of task
     * @param  integer id   The id of the clip to be removed
     * @return boolean      True if added
     */
    removeClip: function(id) {
        // If new is undefined
        if (!id) return false;
        // If stored clips are empty, init them
        if (!this.raw.clips) this.raw.clips = [];
        // Update with all clips excempt the deleted clip
        this.raw.clips = this.raw.clips.filter((clip) => {
            // Return if clip.id is not the same of data.id
            return clip.id !== id;
        });
        // Return true
        return true;
    },

    /**
     * Edit a clip passing new raw information
     * @param  object newClip   The raw data of the clip to be added
     * @return boolean          True if added
     */
    editClip: function(newClip) {
        // Remove clip first
        this.removeClip(newClip.id);
        // Add clip after
        this.addClip(newClip);
        // Return true
        return true;
    },

    /**
     * Return all metadata
     * @return array    All task's metadata
     */
    metadata: function() {
        // If metadata is empty
        return !this.raw.metadata
            // Return undefined
            ? undefined
            // Otherwise, map each element
            : this.raw.metadata.map(metadata => {
                // Return metadata object
                return Metadata.init(metadata);
            });
    },

    /**
     * Set all task metadata
     * @return array    All task's metadata
     */
    setMetadata: function(metadata) {
        // If metadata is null, return
        if (!metadata) return [];
        // If metadata is empty
        return this.raw.metadata = [...metadata];
    },

    /**
     * Return the start date time of the task
     * @param  string format    The format to be returned (unix, moment, string, human)
     * @return multy            The value formatted, string value as default
     */
    start: function(format) {
        // Return value formatted
        return Utility.momentFormat(this.raw.start_datetime, format);
    },

    /**
     * Return the end date time of the task
     * @param  string format    The format to be returned (unix, moment, string, human)
     * @return multy            The value formatted, string value as default
     */
    end: function(format) {
        // Return value formatted
        return Utility.momentFormat(this.raw.end_datetime, format);
    },

    /**
     * Return task name
     * @return {[type]} [description]
     */
    sourceRepr: function() {
        // Return source name
        return this.raw.source_repr;
    },

    /**
     * Return task source url
     * @return integer    Task source
     */
    sourceUrl: function() {
        // Return task source
        return this.raw.source;
    },

    /**
     * Return task source id
     * @return integer    Task source
     */
    sourceId: function() {
        // Divide url in parts
        let parts = this.raw.source.split("/");
        // Get the id
        let id = parts.pop();
        // If id is NOT empty return it, otherwise it means that ended with "/"
        id = !Utility.isEmpty(id) ? id : parts.pop();
        // Return the id parsed
        return id ? parseInt(id) : undefined;
    },

    /**
     * Return the owner_user of the task
     * @return string  Url of owner_user
     */
    user: function() {
        // Return the owner_user repr of the task
        return this.raw.owner_user;
    },


    /**
     * Return the status of the task
     * @return string  Url of status
     */
    status: function() {
        // Return the status repr of the task
        return this.raw.status;
    },

    /**
     * Return the status repr of the task
     * @return string  "Confirmed" or others
     */
    statusRepr: function() {
        // Return the status repr of the task
        return this.raw.status_repr;
    },

    /**
     * Return if is confirmed
     * @return boolean  True if is confirmed
     */
    isConfirmed: function() {
        // Return if task is_confirmed or not
        return this.statusRepr() === "confirmed" ? true : false;
    },

    /*
    |-------------------------------------------------------------------------|
    |                               OTHER METHODS                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Check if is empty or not
     * @return boolean        True if is empty
     */
    isEmpty: function() {
        // Call utiltiy method is empty
        return Utility.isEmpty(this.raw);
    },

    /**
     * Iterate each clips and check if all clips are OK
     * @return boolean  True if all clips are OK or if no clips are available
     */
    isCompleted: function() {
        // Load all clips
        let clips = this.clips();

        // Iterate each clips
        for (var i = 0; i < clips.length; i++) {
            // If almost 1 clip is not OK, return false
            if (!clips[i].isOK()) return false;
        }

        // All OK, return true
        return true;
    },

    /**
     * Check if almost 1 clip is OK (green color := profiles completed).
     * @return boolean  True if almost 1 clip is OK
     */
    hasAlmostOneClipOK: function() {
        // Found for almost 1 clip OK
        const clipOK = this.clips().find(c => c.isOK());
        // If almost 1 clip is OK, return true
        return clipOK ? true : false;
    },

    /**
     * Return sha1 of raw data
     * @return string  The sha1 string of eaw data
     */
    hash: function() {
        // Make the sha1 and return
        return sha1(this.raw);
    }
}
export default Task
