import React from 'react';
// Redux action and store
import { connect } from "react-redux";
import { callsUpdate } from '../../redux/actions';
// FabricUI components
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
// Classes
import Logger from '../classes/Logger.js';
import Utility from '../classes/Utility.js';
// Style
import '../styles/NetworkIndicator.css';

// Small component that process and show network activity to user
class NetworkIndicator extends React.Component {

    /**
     * Set default props
     * @type {Object}
     */
    static defaultProps = {
        name: 'NetworkIndicator',
        statuses: {0:'done', 1:'loading', 2:'error'},
    }

    /**
     * Component constructor
     * @param {Object} props [Component props]
     */
    constructor (props) {
        // Make property available in this module
        super(props);
        // Set default state
        this.state = {
            visible: true,
            pastCalls: []
        }
    }

    /*
    |-------------------------------------------------------------------------|
    |                        GETTER AND SETTER METHODS                        |
    |-------------------------------------------------------------------------|
    */

    /**
     * Return the array with {uuid:callsUpdate} of running calls
     * @return array   The array with all running requests
     */
    getRunningCalls = () => {
        // Return running calls
        return this.props.calls;
    }

    /**
     * Read the last call ended
     * @return object   The call object from redux action
     */
    getLastEndedCall = () => {
        return this.props.ended[this.props.ended.length-1];
    }

    /*
    |-------------------------------------------------------------------------|
    |                               OTHER METHODS                             |
    |-------------------------------------------------------------------------|
    */

    /**
     * Make network indicator blinking!
     * If -1 is passed, don't stop it!
     * @param  integer milliseconds     The blink duration (-1 = unlimited)
     * @return void
     */
    blink = (milliseconds) => {
        // Get network indicator container
        let container = this.refs.network_icon_container;

        // if container is not defiend
        if (!container) {
            // Exit
            return false;
        }

        // If element has not blink me calls (avoid double classes)
        if (!container.classList.contains('blink_me')) {
            // Add class "blick me!"
            container.classList.add('blink_me');
        }

        // If milliseconds are -1 means FOREVER!
        if (milliseconds < 0) {
            // Return
            return true;
        }

        // Set a timeout
        setTimeout(function () {
            // Remove the class and stop blinking
            container.classList.remove('blink_me');
        // Wait milliseconds passed
        }, milliseconds);
    }

    /**
     * Format tip text to be displayed
     * @return string   The text message of tip to be displayed
     */
    formatTip = (status) => {
        // Init text var with status 0 text
        let text = "Tutte le richieste sono state completate.";

        // If stateus is 1 => loading
        if (this.getRunningCalls().length > 0) {
            // Count the calls running..
            text = this.getRunningCalls().length > 1
                // If there are many, white plural sentence
                ? "Ci sono " + this.getRunningCalls().length + " richieste in attesa..."
                // Otherwise write single sentece.
                : "C'è una richiesta in attesa...";
        }
        // If status code is greater then 1
        else if (status > 1) {
            // Set error text
            text = "Si è verificato un errore con l'ultima chiamata.";
        }

        // Return text
        return text;
    }

    /**
     * Manage click on the NetworkIndicator
     * @param  Event ev     The click event
     * @return void
     */
    handleClick = (ev) => {
        Logger.write('NetworkIndicator@handleClick -> click start.');
    }

    /**
     * Return correct code and blink icon
     * @return integer  The code of current running requests
     */
    checkStatus = () => {

        // If there are not running calls
        if (this.getRunningCalls().length > 0) {
            // Otherwise, is still loading, blink 4ever
            this.blink(-1);
            // Return code 1, loading
            return 1;
        }

        // Get ended calls
        let end = this.getLastEndedCall();
        // Blink only 1000 ms
        this.blink(1000);
        // Return code
        return end
            ? (end.response && end.response.status < 400) ? 0 : 2
            : 0;
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return Utility.shallowCompare(this, nextProps, nextState);
    }

    /**
     * Render component
     * @return {} []
     */
    render() {
        // Retrieve status call
        const status = this.checkStatus();

        // Return rendered component
        return (
            <div className='network-indicator pull-right'>
            <div
                ref="network_icon_container"
                className="network-indicator-icon container status"
                onClick={this.handleClick}
                >
                <TooltipHost
                    id="tip-network-indicator-icon"
                    className="tooltip-network-indicator"
                    content={this.formatTip(status)}
                    calloutProps={{
                        gapSpace: 0,
                        target: '#network-indicator-icon'
                    }}>
                    <Icon id='network-indicator-icon' iconName="LocationDot" className={"network-indicator-icon state-" + status} />
                </TooltipHost>&nbsp;
            </div>
        </div>
    );
    }
}

// Take the redux store
const mapStateToProps = state => {
    // From redux store, take only callsUpdate calls
    const calls = state.callsUpdate.calls;
    // Store ended calls in props
    const ended = state.callsUpdate.ended;
    // Return the view data of view change redux and set it to component props
    return { calls, ended };
};

// Connect to redux
export default connect(mapStateToProps, { callsUpdate })(NetworkIndicator);
